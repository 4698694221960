import { connect, MapStateToProps } from 'react-redux';
import { Props } from './Page';
import { AppState } from '../../types/store';
import { getCurrentPageType, getThisUser } from '../../selectors/page';

export type StateProps = Pick<Props, 'pageType' | 'thisUser'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  pageType: getCurrentPageType(state),
  thisUser: getThisUser(state),
});

export default connect(mapStateToProps);
