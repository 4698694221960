import { Url } from '@wix/da-url';

export function getPageUrlWithCursor(cursor?: string, requestUrl = '') {
  const { path, query } = Url.parseUrl(
    typeof window === 'undefined' ? requestUrl : window.location.href
  );
  const params = new URLSearchParams(query);
  params.delete('page');
  if (cursor) {
    params.set('cursor', cursor);
  }
  return `${path}?${params.toString()}`;
}
