import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url, URLS } from '@wix/da-url';
import { ErrorNames } from '../../../constants';

import s from './EmptyResult.scss';

export interface Props {
  errorName: ErrorNames;
}

const EmptyResultDueToSettings: React.FC<Props> = ({ errorName }) => {
  const { t } = useTranslation();
  return (
    <div className={s['empty-state-root']}>
      <div className={s['empty-state-label']}>
        {t([
          `searchPage.emptyState.title.${errorName}`,
          'facetPage.emptyState.title',
        ])}
      </div>
      <div className={s['empty-state-error']}>
        {t(`searchPage.emptyState.error.${errorName}`, {
          postProcess: ['reactPostprocessor', 'featureBrancher'],
          settingsLink: (
            <a
              className="text-link-level-1-brand"
              href={Url.to(URLS.SETTINGS_BROWSING)}
            >
              {t('emptyResult.dueToSettings.linkText')}
            </a>
          ),
        })}
      </div>
    </div>
  );
};
EmptyResultDueToSettings.displayName = 'EmptyResultDueToSettings';

export default EmptyResultDueToSettings;
