import { connect, MapStateToProps } from 'react-redux';
import { Props } from './DeviantsBar';
import { AppState } from '../../types/store';
import {
  getActiveUserId,
  getWatchFilterSubnavigation,
  getWatchAllData,
  getWatchedDeviants,
} from '../../selectors/page';

export type StateProps = Pick<
  Props,
  'users' | 'activeUserId' | 'allHasNew' | 'allUrl' | 'filter'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    users: getWatchedDeviants(state),
    allHasNew: getWatchAllData(state)?.hasNewContent,
    allUrl: getWatchAllData(state)?.url ?? '',
    activeUserId: getActiveUserId(state),
    filter: getWatchFilterSubnavigation(state),
  });

export default connect(mapStateToProps);
