import React from 'react';
import { ErrorNames } from '../../../constants';
import SearchEmptyResult from './SearchEmptyResult';
import DefaultEmptyResult from './DefaultEmptyResult';
import PostsEmptyResult from './Posts';
import NoDeviantsWatchedEmptyResult from './NoDeviantsWatched';
import GroupDeviationsEmptyResult from './GroupDeviations';
import EmptyResultDueToSettings from './EmptyResultDueToSettings';

export interface Props {
  searchTerm?: string;
  pageType?: string;
  feedType?: string;
  errorName: ErrorNames;
}

const EmptyResult: React.FC<Props> = ({
  searchTerm,
  errorName,
  pageType,
  feedType,
}) => {
  if (pageType === 'search' || pageType === 'shop') {
    return <SearchEmptyResult searchTerm={searchTerm} errorName={errorName} />;
  }

  if (pageType === 'groups') {
    if (feedType === 'deviations') {
      return <GroupDeviationsEmptyResult />;
    }
    // group post feed handles its own empty state
    return null;
  }

  if (feedType === 'posts') {
    return <PostsEmptyResult errorName={errorName} />;
  }

  const dueToSettings = errorName === ErrorNames.AllMature;
  if (dueToSettings) {
    return <EmptyResultDueToSettings errorName={errorName} />;
  }

  const showNoDeviantsWatched = errorName === ErrorNames.NoDeviantsYouWatch;
  if (showNoDeviantsWatched) {
    return <NoDeviantsWatchedEmptyResult />;
  }

  return <DefaultEmptyResult errorName={errorName} />;
};
EmptyResult.displayName = 'EmptyResult';

export default EmptyResult;
