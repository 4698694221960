import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Url, URLS } from '@wix/da-url';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import { BiData, ItemClickBiEvent } from '@wix/da-bi/pkg/events';
import { FacetListRow } from '@wix/da-shared-react/pkg/FacetList';
import { FacetButton } from '@wix/da-ds/pkg/FacetList/Facet';
import DailyChallengeIcon from '@wix/da-ds/pkg/Icons/16x16/DailyChallenge';
import { SubNavigation } from '../../../../types/store';
import useSubnav from '../../../../helpers/useSubnav';

import s from './TagSliderSubnav.scss';

const SCROLL_INTO_VIEW_DELAY = 80;
const CLICK_BI_DATA: ItemClickBiEvent = {
  evid: 100,
  sectionname: 'topics_homepage',
};

export interface Props {
  subnav?: SubNavigation;
  /** should include first button that will 'clear' the subnav and send the user back to the root */
  withClearButton?: boolean;
  /** should we show a DailyChallenges icon next to any subnav options marked with `todaysTag`? */
  withTodaysTagIcon?: boolean;
  className?: string;
}

export const TagSliderSubnavPresentational: React.FC<Props> = ({
  subnav,
  withClearButton,
  withTodaysTagIcon,
  className,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const selectedRef = useRef<HTMLElement | null>(null);

  // if a tag is selected, try to put it in the middle of the screen
  useEffect(() => {
    if (selectedRef.current) {
      setTimeout(() => {
        selectedRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }, SCROLL_INTO_VIEW_DELAY);
    }
  }, [isMounted]);

  if (!subnav) {
    return null;
  }

  const { currentValue, options = [] } = subnav;
  const rootUrl = Url.replaceWithCurrentOrigin(URLS.ROOT);
  const clearButton = (
    <FacetButton
      size="large"
      key={`tag-subnav-all`}
      selected={!currentValue}
      href={rootUrl}
      biData={BiData<ItemClickBiEvent>({
        ...CLICK_BI_DATA,
        link_url: rootUrl,
      })}
    >
      {t('browse.tagSliderSubnav.all')}
    </FacetButton>
  );

  const todaysTagIcon = withTodaysTagIcon && (
    <DailyChallengeIcon className={s['todays-tag-icon']} />
  );

  return (
    <FacetListRow
      className={className}
      facets={[
        ...(withClearButton ? [clearButton] : []),
        ...options.map(option => {
          const { subnav: subnavLabel, url, typeId, itemId } = option;
          const updatedUrl = Url.replaceWithCurrentOrigin(url ?? '');
          const isSelected = subnavLabel === currentValue;
          const isTodaysDailyChallenge = (option as any).todaysTag;
          return (
            <FacetButton
              // this applies to the FacetContent -inside- the button
              className={s['facet-content']}
              key={`tag-subnav-${subnavLabel}`}
              size="large"
              selected={isSelected}
              href={updatedUrl}
              innerRef={node => {
                if (node && isSelected) {
                  selectedRef.current = node;
                }
              }}
              biData={BiData<ItemClickBiEvent>({
                ...CLICK_BI_DATA,
                link_url: updatedUrl,
                typeid: typeId,
                itemid: itemId,
              })}
            >
              {isTodaysDailyChallenge && todaysTagIcon}
              {subnavLabel}
            </FacetButton>
          );
        }),
      ]}
    />
  );
};

export const TagSliderSubnav: React.FC<Props> = props => {
  const subnav = useSubnav('tag');
  return <TagSliderSubnavPresentational subnav={subnav} {...props} />;
};
TagSliderSubnav.displayName = 'TagSliderSubnav';
export default TagSliderSubnav;
