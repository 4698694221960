import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './FeedNotice';
import { AppState } from '../../../types/store';
import { getThisGroup } from '../../../selectors/page';
import { getIsCurrentStreamEmpty } from '../../../selectors/stream';

export type StateProps = Pick<Props, 'thisGroup' | 'isFeedEmpty'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  thisGroup: getThisGroup(state),
  isFeedEmpty: getIsCurrentStreamEmpty(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
