import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiTag } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { Aside } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import LoadableIngressForm from '@wix/da-dreamup/pkg/components/LoadableIngressForm';
import s from './DreamupEntryForm.scss';

interface Props {
  tag?: PapiTag;
  className?: string;
}

const DreamupEntryForm: React.FC<Props> = ({ tag, className }) => {
  const { t } = useTranslation();
  return (
    <BiLoggerContextProvider
      value={{
        itemid: tag?.itemId,
        typeid: tag?.typeId,
        sectionname: 'topic-dreamup-banner',
      }}
    >
      <Aside className={c(s['root'], className)}>
        <Heading className={s['heading']}>
          {t('tagPage.dreamupEntry.heading')}
        </Heading>
        <div className={s['form-wrapper']}>
          <LoadableIngressForm hideArtStyle />
        </div>
      </Aside>
    </BiLoggerContextProvider>
  );
};

DreamupEntryForm.displayName = 'DreamupEntryForm';

export default DreamupEntryForm;
