import React, { useCallback, useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { getStreamItemId } from '@wix/da-shared-react/pkg/Duperbrowse/redux/helpers';
import { StandardGrid, GridElement } from '@wix/da-shared-react/pkg/Grid';
import StackThumb, {
  SIZE_ADJUSTMENTS,
} from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Stack';
import type { BrowseItem } from '../../../../types/api';
import { getItemId, getItemType, isDeviation } from '../../../helpers/streams';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import {
  GRID_DEFAULT_STRIP_INDEX,
  GRID_STRIP_HEIGHT_DESKTOP,
  GRID_STRIP_HEIGHT_MOBILE,
} from '../constants';
import { PapiDeviation } from '@wix/da-papi-types';

import s from './BrowseStacks.scss';
import { BiData, WatchViewAllByArtistBiEvent } from '@wix/da-bi/pkg/events';

export interface Props {
  items: BrowseItem[];
  className?: string;
  isInfiniteScroll?: boolean;
  contentStrip?: React.ReactNode;
  contentStripIndex?: number;
  contentStripHeight?: number;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
  getItemUrl?: (item: BrowseItem) => string | undefined;
  onOffsetReached?: (offset: number) => void;
  renderThumbExtras?: (item: BrowseItem, index: number) => React.ReactNode;
}

const BrowseStacks: React.FC<Props> = ({
  items,
  isInfiniteScroll,
  className,
  contentStrip,
  contentStripHeight,
  contentStripIndex,
  onRemove,
  getItemUrl,
  onOffsetReached,
  renderThumbExtras,
}) => {
  const isMobile = useContext(MobileContext);
  const getGridItemId = useCallback(
    (index: number) => {
      const item = items[index];
      const type = getItemType(item);
      return `item-${type}-${getItemId(item)}`;
    },
    [items]
  );

  const getDuperbrowseItemId = useCallback(
    (index: number) => {
      const item = items[index];

      if (isDeviation(item)) {
        return getStreamItemId(item);
      }

      return `item-${getItemType(item)}-${getItemId(item)}`;
    },
    [items]
  );

  const renderGridItem = useCallback(
    ({ index, width, height, style }: GridElement) => {
      const item = items[index];

      return (
        item &&
        isDeviation(item) && (
          <BiLoggerContextProvider
            key={getGridItemId(index)}
            value={{ _rankIndex: index }}
          >
            <div className={s['stack-thumb']}>
              <StackThumb
                deviation={item}
                width={width}
                height={height}
                style={style}
                url={getItemUrl && getItemUrl(item)}
                onRemove={onRemove && (() => onRemove(item, index))}
                biData={BiData<WatchViewAllByArtistBiEvent>({
                  evid: 413,
                  itemid: item.author?.userId ?? 0,
                  typeid: ResourceType.USER,
                })}
              />
              {renderThumbExtras && renderThumbExtras(item, index)}
            </div>
          </BiLoggerContextProvider>
        )
      );
    },
    [getGridItemId, getItemUrl, items, onRemove, renderThumbExtras]
  );

  return (
    <StandardGrid
      elementCount={items.length}
      cookieType={MeasuredCookieType.BROWSE}
      getElementId={getGridItemId}
      getDuperbrowseItemId={getDuperbrowseItemId}
      enableScrollOptim={isInfiniteScroll}
      onOffsetReached={isInfiniteScroll ? onOffsetReached : undefined}
      adjustHeight={SIZE_ADJUSTMENTS}
      className={className}
      contentStrip={contentStrip}
      contentStripIndex={contentStripIndex ?? GRID_DEFAULT_STRIP_INDEX}
      contentStripHeight={
        contentStripHeight ??
        (isMobile ? GRID_STRIP_HEIGHT_MOBILE : GRID_STRIP_HEIGHT_DESKTOP)
      }
    >
      {renderGridItem}
    </StandardGrid>
  );
};
BrowseStacks.displayName = 'BrowseStacks';

export default BrowseStacks;
