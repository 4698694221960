// DS: CMPST04
// [composite of CR51 + C33D + INT01D] https://zpl.io/bWj098K
import React from 'react';
import { PapiDeviation, PapiGallection } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import {
  BiContextProvidedValue,
  BiData,
  ItemClickBiEvent,
} from '@wix/da-bi/pkg/events';
import DeviationsMosaic from '@wix/da-shared-react/pkg/DeviationsMosaic';
import { GallectionResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import CollectionCardFooter from './CollectionCardFooter';
import CollectionCardOverlay from './CollectionCardOverlay';
import s from './CollectionCard.scss';

export interface Props {
  gallection: PapiGallection;
  width: number;
  height: number;
  style?: React.CSSProperties; // For torpedo
  inclusiveFooter?: boolean; // default true, remove footer height from requested height
  dataHook?: string;
}

const FOOTER_HEIGHT = 45;

export const CollectionCardCMPST04: React.FC<Props> = ({
  style,
  width,
  height,
  gallection,
  gallection: { owner, type, thumb, additionalThumbs, name, folderId },
  inclusiveFooter = true,
  dataHook,
}) => {
  let deviations: PapiDeviation[] = [];
  if (thumb) {
    deviations.push(thumb);
  }
  if (additionalThumbs) {
    deviations = [...deviations, ...additionalThumbs];
  }

  // Calculate heights
  const mosaicHeight = inclusiveFooter ? height - FOOTER_HEIGHT : height;
  const fullHeight = inclusiveFooter ? height : 'auto';

  const link = Url.userGalleryLink({
    username: owner.username,
    gallectionType: 'collection',
    folderId,
    folderName: name,
  });

  return (
    <ErrorBoundary debugComponent="CollectionCardCMPST04">
      <div
        className={s['root']}
        style={{ ...style, width, height: fullHeight }}
        data-hook={dataHook}
      >
        <BiLink
          className={s['bi-link-overlay']}
          href={link}
          biData={BiData<ItemClickBiEvent>({
            evid: 100,
            itemid: folderId,
            typeid: GallectionResourceType.COLLECTION,
            page_typeid: BiContextProvidedValue,
            page_itemid: BiContextProvidedValue,
            sectionid: BiContextProvidedValue,
            sectionname: BiContextProvidedValue,
            section_typeid: BiContextProvidedValue,
            sectiontype: BiContextProvidedValue,
            widgetname: BiContextProvidedValue,
          })}
        ></BiLink>
        <div className={s['mosaic']}>
          <DeviationsMosaic
            deviations={deviations}
            width={width}
            height={mosaicHeight}
          />
          <CollectionCardOverlay
            className={s['overlay']}
            gallection={gallection}
          />
        </div>
        <CollectionCardFooter gallection={gallection} />
      </div>
    </ErrorBoundary>
  );
};

CollectionCardCMPST04.displayName = 'CollectionCardCMPST04';

export default CollectionCardCMPST04;
