import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import DreamUpStarsIcon from '@wix/da-ds/pkg/Icons/24x24/DreamUpStars';

import useDreamUpButtonProps from './useDreamUpButtonProps';

export interface Props {
  className?: string;
}

const DreamUpButton: React.FC<PropsWithChildren<Props>> = ({ className }) => {
  const { t } = useTranslation();
  const dreamupButtonProps = useDreamUpButtonProps();

  return (
    <TextButton
      className={className}
      variant="main"
      size="large"
      icon={DreamUpStarsIcon}
      {...dreamupButtonProps}
    >
      {t('browse.dreamupCTA.mobile')}
    </TextButton>
  );
};
DreamUpButton.displayName = 'DreamUpButton';

export default DreamUpButton;
