import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { showDeviantsListModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './DeviantsSearchButton';
import { AppState } from '../../types/store';
import { getCurrentFeedType } from '../../selectors/page';

export type StateProps = Pick<Props, 'feedType'>;
export type DispatchProps = Pick<Props, 'showDeviantsListModal'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  feedType: getCurrentFeedType(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showDeviantsListModal,
};

export default connect(mapStateToProps, mapDispatchToProps);
