import { connect, MapStateToProps } from 'react-redux';
import { Props } from './SearchPageHeader';
import { AppState } from '../../types/store';
import { getTotal } from '../../selectors/stream';
import { getMainContentUrl } from '../../selectors/page';

export type StateProps = Pick<Props, 'estTotal' | 'mainContentUrl'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  estTotal: getTotal(state),
  mainContentUrl: getMainContentUrl(state),
});

export default connect(mapStateToProps);
