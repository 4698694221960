import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ErrorNames } from '../../../constants';
import s from './EmptyResult.scss';

export interface Props {
  searchTerm?: string;
  errorName: ErrorNames;
}

const SearchEmptyResult: React.FC<Props> = ({ searchTerm, errorName }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(s['search'], s['empty-state-root'])}>
      <div className={s['empty-state-label']}>
        {t(
          [
            `searchPage.emptyState.title.${errorName}`,
            'searchPage.emptyState.title',
          ],
          {
            searchTerm,
          }
        )}
      </div>
      {errorName && (
        <div className={s['empty-state-error']}>
          {t(`searchPage.emptyState.error.${errorName}`)}
        </div>
      )}
      <div className={s['empty-state-suggestion']}>
        {t([
          `searchPage.emptyState.suggestion.${errorName}`,
          'searchPage.emptyState.suggestion',
        ])}
      </div>
    </div>
  );
};
SearchEmptyResult.displayName = 'SearchEmptyResult';

export default SearchEmptyResult;
