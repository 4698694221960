import React, { useEffect, useRef } from 'react';

type Direction = 'up' | 'down';

type Options = {
  scrollingUpClassName?: string;
  scrollingDownClassName?: string;
  enabled?: boolean;
  showThreshold?: number;
  hideThreshold?: number;
};

function getScrollPosition() {
  if (typeof window === 'undefined') {
    return 0;
  }

  return window.pageYOffset || document.documentElement.scrollTop;
}

export function useScrollDirection(
  ref: React.RefObject<HTMLElement>,
  {
    enabled = true,
    showThreshold = 100,
    hideThreshold = 300,
    scrollingUpClassName,
    scrollingDownClassName,
  }: Options = {}
) {
  const lastTurningPoint = useRef(getScrollPosition());
  const lastScrollPosition = useRef(getScrollPosition());
  const lastScrollDirection = useRef<Direction | null>(null);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onScroll = () => {
      // The order of things here is important!
      // first, let's see where we are
      const scrollPosition = getScrollPosition();
      // ...and our relative position compared to the previous event
      const direction =
        lastScrollPosition.current < scrollPosition ? 'down' : 'up';

      // if we just changed direction, this is our most recent turning point
      if (direction !== lastScrollDirection.current) {
        lastTurningPoint.current = scrollPosition;
      }

      // see how far we are from the last turning point
      // this will be 0 if we just turned, which is good, so we don't flicker
      const scrollDelta = Math.abs(lastTurningPoint.current - scrollPosition);

      // preserve data for the next event
      lastScrollDirection.current = direction;
      lastScrollPosition.current = scrollPosition;

      // now see if we need to change state
      const refNode = ref.current;

      // no node, nothing we can do
      if (!refNode) {
        return;
      }

      // do not attach either direction when close enough to the top
      if (refNode.getBoundingClientRect().height > scrollPosition) {
        scrollingUpClassName && refNode.classList.remove(scrollingUpClassName);
        scrollingDownClassName &&
          refNode.classList.remove(scrollingDownClassName);
        return;
      }
      if (direction === 'up' && scrollDelta > showThreshold) {
        scrollingUpClassName && refNode.classList.add(scrollingUpClassName);
        scrollingDownClassName &&
          refNode.classList.remove(scrollingDownClassName);
      } else if (direction === 'down' && scrollDelta > hideThreshold) {
        scrollingUpClassName && refNode.classList.remove(scrollingUpClassName);
        scrollingDownClassName && refNode.classList.add(scrollingDownClassName);
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [
    enabled,
    hideThreshold,
    showThreshold,
    ref,
    scrollingUpClassName,
    scrollingDownClassName,
  ]);
}
