import React, { useContext } from 'react';
import classnames from 'classnames';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useCurrentPromo } from '@wix/da-shared-react/pkg/utils/hooks/useCurrentPromo';
import SaleBanner from '@wix/da-shared-react/pkg/banners/SaleBanner';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BrowseLayout } from '../../../../../constants';
import PageContainer from '../PageContainer';
import { Props as PageContainerProps } from '../PageContainer/PageContainer';
import DefaultPageContent from '../DefaultPageContent';
import MpBanner from './MpBanner';

import s from './BasePageLayout.scss';

type PassablePageContainerProps = Pick<
  PageContainerProps,
  | 'aboveHeader'
  | 'title'
  | 'tagLine'
  | 'headerNavigation'
  | 'headerRightSideContent'
  | 'headerLeftSideContent'
  | 'headerIsStickyOnScrollUp'
  | 'withNavigation'
  | 'withNetworkBar'
  | 'wrapperClassName'
  | 'headerClassName'
  | 'innerHeaderClassName'
  | 'titleClassName'
  | 'customHeader'
  | 'withTabHeader'
  | 'belowHeader'
>;

export interface Props extends PassablePageContainerProps {
  layout: BrowseLayout;
  feedType?: string;
  contentClassName?: string;
  allowSaleBanner?: boolean;
  children?: React.ReactNode;
}

const BasePageLayout: React.FC<Props> = ({
  layout,
  feedType,
  contentClassName,
  allowSaleBanner = true,
  children,
  ...pageContainerProps
}) => {
  const isMobile = useContext(MobileContext);

  const mp_banner_d = useFlag('mp_banner_d');
  const isShowingMpBanner = !isMobile && mp_banner_d && allowSaleBanner;

  const currentPromo = useCurrentPromo('self');
  const isShowingCorePromo = allowSaleBanner && currentPromo;
  const isShowingBanner = isShowingCorePromo;

  const isCoast2Coast = layout === BrowseLayout.MobileGrid;
  return (
    <PageContainer {...pageContainerProps}>
      <div
        className={classnames(
          s['root'],
          s[`feed-${feedType}`],
          isShowingBanner && s['with-banner'],
          isCoast2Coast && s['coast2coast'],
          contentClassName
        )}
      >
        {isShowingCorePromo && (
          <SaleBanner
            className={s['sale-banner']}
            endDate={currentPromo!.endDate}
            discountValuePercent={currentPromo!.discountValuePercent}
            referralType={ReferralType.PROMOTION_BANNER_HOME}
          />
        )}
        {isShowingMpBanner && <MpBanner className={s['sale-banner']} />}
        {children ? children : <DefaultPageContent />}
      </div>
    </PageContainer>
  );
};
BasePageLayout.displayName = 'BasePageLayout';

export default BasePageLayout;
