import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiWatchSuggestion } from '@wix/da-papi-types';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import WatchSuggestionList from '@wix/da-shared-react/pkg/WatchSuggestions';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import s from './NoDeviantsWatchedEmptyResult.scss';

export interface Props {
  watchSuggestions?: PapiWatchSuggestion[];
}

const NoDeviantsWatchedEmptyResult: React.FC<Props> = ({
  watchSuggestions = [],
}) => {
  const { t } = useTranslation();
  const [watchCount, setWatchCount] = useState(0);
  const onWatch = useCallback(() => setWatchCount(count => count + 1), []);

  return (
    <div className={s['root']}>
      <Heading className={s['title']}>
        {t('deviantsYouWatchPage.emptyState.v2.header')}
      </Heading>
      <p className={s['subtitle']}>
        {t('deviantsYouWatchPage.emptyState.v2.subheader')}
      </p>

      <WatchSuggestionList
        watchSuggestions={watchSuggestions}
        duperbrowseContextTitle={t('duperbrowse.backButton.watch.deviations')}
        onWatch={onWatch}
      />

      {watchCount >= 3 && (
        <div className={s['build']}>
          <SolidButton
            size="medium"
            variant="approval-green"
            onClick={() => window.location.reload()}
            biData={BiData<DefaultBiEvent>({
              evid: 2,
              click_info: 'dyw_emptystate_build_button',
              sectionname: BiContextProvidedValue,
            })}
          >
            {t('deviantsYouWatchPage.emptyState.v2.buildButton.label')}
          </SolidButton>
        </div>
      )}
    </div>
  );
};
NoDeviantsWatchedEmptyResult.displayName = 'NoDeviantsWatchedEmptyResult';

export default NoDeviantsWatchedEmptyResult;
