import { connect, MapStateToProps } from 'react-redux';
import { Props } from './ViewSwitcher';
import { AppState } from '../../../../types/store';
import { getStackFilterSubnavigation } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'subnav'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    subnav: getStackFilterSubnavigation(state),
  });

export default connect(mapStateToProps);
