import React from 'react';
import { useTranslation } from 'react-i18next';
import SophisticatedTooltipContent from '@wix/da-ds/pkg/tooltips/lego/SophisticatedTooltipContent';
import s from './DesktopWatchFTUE.scss';

export interface Props {
  className?: string;
  onDropFlag?: () => void;
}

const DesktopWatchFTUE: React.FC<Props> = ({ className, onDropFlag }) => {
  const { t } = useTranslation();

  return (
    <SophisticatedTooltipContent
      width={253}
      size="large"
      effectivePlacement="right"
      effectiveSecondaryPlacement="middle"
      heading={t('deviantsYouWatchPage.removeFtue.title')}
      description={t('deviantsYouWatchPage.removeFtue.text')}
      prefix={
        <img
          alt=""
          src="https://st.deviantart.net/eclipse/global/images/dyw3-remove-ftue.gif"
          className={s['illustration']}
        />
      }
      hasCloseButton
      onClose={onDropFlag}
      className={className}
    />
  );
};
DesktopWatchFTUE.displayName = 'DesktopWatchFTUE';

export default DesktopWatchFTUE;
