import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';

import s from './SellersLandingPageLink.scss';

/** CTA with text that sends user to the 'sellers landing page' */
const SellersLandingPageLink: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Section className={s['root']}>
      <Heading className={s['title']}>
        {t('shopPage.sellersLandingPageLink.title')}
      </Heading>

      <p className={s['subtitle']}>
        {t('shopPage.sellersLandingPageLink.subtitle')}
      </p>

      <SolidButton
        className={s['link']}
        href={t('shopPage.sellersLandingPageLink.button.url')}
        target="_blank"
        rel="noopener noreferrer"
        priority="primary"
        size="medium"
        variant="approval-green"
        biData={BiData<DefaultBiEvent>({
          evid: 2,
          click_info: 'show_me_how',
        })}
      >
        {t('shopPage.sellersLandingPageLink.button.text')}
      </SolidButton>
    </Section>
  );
};

SellersLandingPageLink.displayName = 'SellersLandingPageLink';
export default SellersLandingPageLink;
