import { connect, MapStateToProps } from 'react-redux';
import { Props } from './ShopThumbLayout';
import { AppState } from '../../../../../types/store';
import { getCurrentStreamItems } from '../../../../../selectors/stream';
import {
  getCurrentOrder,
  getIsInfiniteScroll,
} from '../../../../../selectors/page';

export type StateProps = Pick<
  Props,
  'items' | 'effectiveOrder' | 'isInfiniteScroll'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  items: getCurrentStreamItems(state),
  effectiveOrder: getCurrentOrder(state) ?? '',
  isInfiniteScroll: getIsInfiniteScroll(state),
});

export default connect(mapStateToProps);
