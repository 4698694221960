import React from 'react';
import classnames from 'classnames';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import DeviantsBar from '../../../../DeviantsBar';
import SingleDeviantEmptyResult from '../../../../EmptyResult/SingleDeviant';
import BasePageLayout from '../../../_partials/BasePageLayout';
import DefaultPageContent from '../../../_partials/DefaultPageContent';
import WatchButton from '../../../../WatchButton';
import WatchedUserMenu from '../../../../WatchedUserMenu';
import DeviantsSearchButton from '../../../../DeviantsSearchButton';
import BirthdayBanner from '../../../../BirthdayBanner';
import RemoveContentMargin from '../../../_partials/RemoveContentMargin';
import { BiEvent } from '@wix/da-bi/pkg/events';

import s from './MobileArtistWatchPage.scss';

export interface Props {
  feedType?: string;
  thisUser: PapiUser;
  isEmpty?: boolean;
  showRemoveAll?: boolean;
  removeAll: (biData: BiEvent) => void;
  removeDeviation: (deviation: PapiDeviation, offset: number) => void;
}

const MobileArtistWatchPage: React.FC<Props> = ({
  feedType,
  thisUser,
  isEmpty,
  showRemoveAll,
  removeAll,
  removeDeviation,
}) => {
  return (
    <BasePageLayout
      customHeader={
        <>
          <DeviantsBar />
          <div className={s['profile-bar']}>
            <UserLink user={thisUser} className={s['username']} />
            <WatchButton user={thisUser} className={s['watch-button']} />
            {showRemoveAll && (
              <WatchedUserMenu
                user={thisUser}
                removeAll={removeAll}
                className={s['user-menu']}
              />
            )}
          </div>
        </>
      }
      headerRightSideContent={
        <DeviantsSearchButton selectedUserId={thisUser.userId} />
      }
      headerIsStickyOnScrollUp
      contentClassName={classnames(isEmpty && s['content-empty'])}
      allowSaleBanner={!thisUser.isBirthweek}
    >
      {thisUser.isBirthweek && (
        <RemoveContentMargin className={s['birthday-banner']}>
          <BirthdayBanner user={thisUser} />
        </RemoveContentMargin>
      )}
      {isEmpty ? (
        <SingleDeviantEmptyResult feedType={feedType} />
      ) : (
        <OverlayContextProvider
          value={{
            showPermanentEngagementIcon: true,
            showAuthorWatchButton: false,
          }}
        >
          <DefaultPageContent
            onRemove={removeDeviation}
            withWatchOrSubscribeButtons={false}
          />
        </OverlayContextProvider>
      )}
    </BasePageLayout>
  );
};
MobileArtistWatchPage.displayName = 'MobileArtistWatchPage';

export default MobileArtistWatchPage;
