import { connect, MapStateToProps } from 'react-redux';
import { Props } from './OrderDropdown';
import { AppState } from '../../../../types/store';
import { getOrderSubnavigation } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'subnav'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  subnav: getOrderSubnavigation(state),
});

export default connect(mapStateToProps);
