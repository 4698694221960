import React from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';

import s from './FeedNotice.scss';

export interface Props {
  thisGroup?: PapiUser;
  isFeedEmpty?: boolean;
  className?: string;
}

const ACTIVE_FEED_CUTOFF = 90 * 24 * 60 * 60 * 1000;

const FeedNotice: React.FC<Props> = ({ thisGroup, className, isFeedEmpty }) => {
  const { t } = useTranslation();

  // only groups get these cards for now
  const groupPostData = thisGroup?.extendedGroup?.apps?.posts;
  if (!thisGroup || !groupPostData) {
    return null;
  }

  const { canSubmit, latestPostTs = 0 } = groupPostData;

  // empty state
  if (isFeedEmpty) {
    return (
      <div className={classnames(s['root'], s['empty'], className)}>
        <div className={s['title']}>
          {t('groupFeed.posts.emptyState.header')}
        </div>
        <div className={s['subtitle']}>
          {canSubmit ? (
            t('groupFeed.posts.emptyState.subheader.canSubmit')
          ) : (
            /* prettier-ignore */
            <Trans i18nKey="groupFeed.posts.emptyState.subheader.cannotSubmit">
              <UserLink user={thisGroup!} className={s['link']}>
                Visit the group
              </UserLink>{' '}
              or{' '}
              <PlainButtonOrLink
                className={s['link']}
                href={Url.userNoteLink(thisGroup!.username)}
                biData={BiData<DefaultBiEvent>({
                  evid: 2,
                  click_info: 'empty_feed_contact_admin',
                  sectionname: BiContextProvidedValue,
                  typeid: BiContextProvidedValue,
                  itemid: BiContextProvidedValue,
                })}
              >
                contact the admins
              </PlainButtonOrLink>
              <br />
              to learn how to start posting.
            </Trans>
          )}
        </div>
      </div>
    );
  }

  // php returns seconds, javascript uses ms, hence the weird 1000 in there
  if (Date.now() - latestPostTs * 1000 < ACTIVE_FEED_CUTOFF) {
    // for active feeds we show nothing
    return null;
  }

  // stale
  return (
    <div className={classnames(s['root'], className)}>
      <div className={s['title']}>{t('groupFeed.posts.stale.header')}</div>
      <div className={s['subtitle']}>
        {canSubmit ? (
          /* prettier-ignore */
          <Trans i18nKey="groupFeed.posts.stale.subheader.canSubmit">
            It’s been a bit quiet around here. Why not write a <br /> post to
            kick-start the conversation?
          </Trans>
        ) : (
          /* prettier-ignore */
          <Trans i18nKey="groupFeed.posts.stale.subheader.cannotSubmit">
            It’s been a bit quiet around here.{' '}
            <UserLink className={s['link']} user={thisGroup!}>
              Visit the group
            </UserLink>
            <br /> or{' '}
            <PlainButtonOrLink
              className={s['link']}
              href={Url.userNoteLink(thisGroup!.username)}
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'stale_feed_contact_admin',
                sectionname: BiContextProvidedValue,
                typeid: BiContextProvidedValue,
                itemid: BiContextProvidedValue,
              })}
            >
              contact the admins
            </PlainButtonOrLink>{' '}
            to learn how to start posting.
          </Trans>
        )}
      </div>
    </div>
  );
};
FeedNotice.displayName = 'FeedNotice';

export default FeedNotice;
