import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getIsDuperbrowseActive } from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import { selectors, actions } from '@wix/da-shared-react/pkg/Stream';
import { Props } from './PagingWrapper';
import { AppState } from '../../../types/store';
import {
  getNextPageUrl,
  getPrevPageUrl,
  getStreamError,
} from '../../../selectors/stream';
import {
  getCurrentPageType,
  getIsInfiniteScroll,
} from '../../../selectors/page';

export type StateProps = Pick<
  Props,
  | 'isDuperbrowseActive'
  | 'isFetching'
  | 'hasMore'
  | 'hasLess'
  | 'isInfiniteScroll'
  | 'error'
  | 'nextPageUrl'
  | 'prevPageUrl'
  | 'pageType'
>;
export type DispatchProps = Pick<Props, 'fetchMore'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { streamId }
) => ({
  hasMore: selectors.hasMore(state, streamId),
  hasLess: selectors.getHasLess(state, streamId),
  isFetching: selectors.isFetching(state, streamId),
  isDuperbrowseActive: getIsDuperbrowseActive(state as any),
  isInfiniteScroll: getIsInfiniteScroll(state),
  error: getStreamError(state),
  nextPageUrl: getNextPageUrl(state),
  prevPageUrl: getPrevPageUrl(state),
  pageType: getCurrentPageType(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  fetchMore: actions.fetchNext,
};

export default connect(mapStateToProps, mapDispatchToProps);
