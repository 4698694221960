import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { PapiUser } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { BiData, BuyCoreButtonBiEvent } from '@wix/da-bi/pkg/events';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import BirthdayCake from '@wix/da-ds/pkg/Icons/16x16/BirthdayCake';
import DialogBanner from '@wix/da-ds/pkg/DialogBanner';

import baseStyles from './BirthdayBanner.scss';

interface Props {
  user: PapiUser;
  className?: string;
}

const GiftCoreBirthdayBanner: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const saleKey = isMobile
    ? 'watch.birthday.banner.textWithLink.mobile'
    : 'watch.birthday.banner.textWithLink';

  const { username } = user;
  return (
    <DialogBanner
      className={classnames(
        baseStyles['root'],
        baseStyles['gift-core'],
        className
      )}
      message={
        <span className={baseStyles['message']}>
          <BirthdayCake
            disableDefaultColors
            className={baseStyles['cake-icon']}
            role="presentation"
          />

          <span>
            {t(saleKey, {
              postProcess: ['reactPostprocessor', 'featureBrancher'],
              username: (
                <a
                  className="text-link-level-2-primary"
                  href={Url.userLink(username)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {username}
                </a>
              ),
            })}
          </span>
        </span>
      }
      customCtaButton={
        <BrandedButton
          size="small"
          variant="core-orange"
          className={baseStyles['button']}
          href={Url.giveCoreMembership(user.username)}
          biData={BiData<BuyCoreButtonBiEvent>({
            evid: 375,
            referral_info: ReferralType.DYW_BIRTHDAY,
          })}
        >
          {t(
            isMobile
              ? 'watch.birthday.banner.button.upsell.mobile'
              : 'watch.birthday.banner.button.upsell'
          )}
        </BrandedButton>
      }
    />
  );
};
GiftCoreBirthdayBanner.displayName = 'GiftCoreBirthdayBanner';

export default GiftCoreBirthdayBanner;
