import React, { useMemo } from 'react';
import TagList from '@wix/da-ds/pkg/TagList';
import { TagStrip } from '../../../../types/api';
import StripWrapper from '../StripWrapper';
import s from './TagsStrip.scss';

export interface Props {
  strip: TagStrip;
  isSearchPage?: boolean;
  className?: string;
}

const TagsStrip: React.FC<Props> = ({ strip, isSearchPage, className }) => {
  const tags = useMemo(
    () =>
      strip.tags.map(stripTag => ({
        tagname: stripTag.tag,
        href: stripTag.url,
      })),
    [strip.tags]
  );

  return (
    <StripWrapper
      strip={strip}
      className={className}
      isSearchPage={isSearchPage}
    >
      <div className={s['tag-bar-inner']}>
        <TagList tags={tags} />
      </div>
    </StripWrapper>
  );
};
TagsStrip.displayName = 'TagsStrip';

export default TagsStrip;
