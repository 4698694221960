import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import FilterIcon from '@wix/da-ds/pkg/Icons/Filter';
import RightArrowIcon from '@wix/da-ds/pkg/Icons/24x24/ChevronRight';
import LeftArrowIcon from '@wix/da-ds/pkg/Icons/24x24/ArrowLeft';
import { ModalRoot } from '@wix/da-ds/pkg/modals/base';

import s from './ShopFiltersDisclosure.scss';

const ARIA_ID = 'shop-filters';

export interface Props {
  isOpen?: boolean;
  openButtonClassName?: string;
  openButtonLabel?: string;
  panelClassName?: string;
  onOpenClick?: () => void;
  onCloseClick?: () => void;
  children?: React.ReactNode;
}

const ShopFiltersDisclosure: React.FC<Props> = ({
  isOpen,
  openButtonClassName,
  openButtonLabel,
  panelClassName,
  onOpenClick,
  onCloseClick,
  children,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  // this disclosure renders the open/close toggle as:
  // - a separate open button and no panel when closed
  // - a close button inside the content panel (and no open button) when open
  if (!isOpen) {
    return (
      <PlainButtonOrLink
        className={classnames(openButtonClassName, s['open-button'])}
        aria-expanded={false}
        onClick={onOpenClick}
      >
        <FilterIcon />
        {isMobile ? (
          <span className={s['open-button-label']}>{openButtonLabel}</span>
        ) : (
          <>
            <VisuallyHidden>
              {t('shopPage.shopFiltersDisclosure.openButton.a11y.label')}
            </VisuallyHidden>
            <RightArrowIcon />
          </>
        )}
      </PlainButtonOrLink>
    );
  }

  return isMobile ? (
    <ModalRoot className={s['modal']} isOpen onRequestClose={onCloseClick}>
      <div className={s['modal-header']}>
        <FilterIcon />
        {t('shopPage.shopFiltersDisclosure.modal.title.mobile')}
      </div>

      {children}
    </ModalRoot>
  ) : (
    <div
      className={classnames(panelClassName, s['panel'])}
      id={ARIA_ID}
      aria-expanded={true}
    >
      <PlainButtonOrLink
        className={s['close-button']}
        aria-expanded={true}
        aria-controls={ARIA_ID}
        onClick={onCloseClick}
      >
        <VisuallyHidden>
          {t('shopPage.shopFiltersDisclosure.closeButton.a11y.label')}
        </VisuallyHidden>
        <FilterIcon />
        <LeftArrowIcon className={s['toggle-arrow']} />
      </PlainButtonOrLink>

      {children}
    </div>
  );
};

ShopFiltersDisclosure.displayName = 'ShopFiltersDisclosure';
export default ShopFiltersDisclosure;
