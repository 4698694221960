import { connect, MapStateToProps } from 'react-redux';
import { Props } from './BrowseDuperContext';
import { AppState } from '../../../types/store';
import {
  getCurrentFeedType,
  getCurrentPageType,
} from '../../../selectors/page';

export type StateProps = Pick<Props, 'pageType' | 'feedType'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  pageType: getCurrentPageType(state),
  feedType: getCurrentFeedType(state),
});

export default connect(mapStateToProps);
