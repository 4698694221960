/** Remove the trailing slash from the end of a url path. */
export function stripSlashes(url: string) {
  // we only really split on the first ? since they're also valid in query params
  const [path, ...query] = url.split('?');
  const p = path !== '/' && path.endsWith('/') ? path.slice(0, -1) : path;
  const q = query.length ? `?${query.join('?')}` : '';
  return `${p}${q}`;
}

export function getDDLatestPublishedRawDate() {
  return new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
  );
}

export function getDDLatestPublishedDate(unformatted = false) {
  const today = getDDLatestPublishedRawDate();

  if (unformatted) {
    return today;
  }

  return today
    .toLocaleString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
}

export function formatDDDate(date: Date) {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}
