import { AppState, ModalState } from '../types/store';

function getModal(state: AppState): ModalState {
  return state.modal || {};
}

export function getModalToShowOnLoad(state: AppState): ModalState['modalToShowOnLoad'] {
  return getModal(state)?.modalToShowOnLoad || '';
}

export function getModalParams(state: AppState): ModalState['modalParams'] {
  return getModal(state)?.modalParams || {};
}
