import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorNames } from '../../../constants';
import s from './EmptyResult.scss';

export interface Props {
  errorName: ErrorNames;
}

const DefaultEmptyResult: React.FC<Props> = ({ errorName }) => {
  const { t } = useTranslation();
  return (
    <div className={s['empty-state-root']}>
      <div className={s['empty-state-label']}>
        {t([
          `searchPage.emptyState.title.${errorName}`,
          'facetPage.emptyState.title',
        ])}
      </div>
      {errorName && (
        <div className={s['empty-state-error']}>
          {t(`searchPage.emptyState.error.${errorName}`)}
        </div>
      )}
      <div className={s['empty-state-suggestion']}>
        {t([
          `searchPage.emptyState.suggestion.${errorName}`,
          'facetPage.emptyState.suggestion',
        ])}
      </div>
    </div>
  );
};
DefaultEmptyResult.displayName = 'DefaultEmptyResult';

export default DefaultEmptyResult;
