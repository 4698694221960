import React, { useEffect, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import Slider, {
  DefaultPreviousButton,
  DefaultNextButton,
} from '@wix/da-ds/pkg/Slider';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { DailyChallengeArray } from '../../types';
import DailyChallengeTag from './DailyChallengeTag';

import s from './DailyChallengeSlider.scss';

export interface Props {
  tag?: string;
  dailyChallenges?: DailyChallengeArray;
  className?: string;
  contentClassName?: string;
}

const DailyChallengeSlider: React.FC<Props> = ({
  tag,
  dailyChallenges,
  className,
  contentClassName,
}) => {
  const selectedTagRef = useRef<HTMLElement | null>();

  // might technically be called 'on a render after mounting' or just 'delay'
  // if we didn't add this to the useEffect after it, safari doesn't seem to
  // have enough time to set up enough of the dom to do scrollIntoView very well
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted && selectedTagRef.current) {
      selectedTagRef.current.scrollIntoView({
        behavior: 'instant' as any /* instant isn't in ts yet */,
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [isMounted]);

  const selectedIndex = useMemo(
    () => dailyChallenges?.findIndex(({ tag: t }) => t.tag === tag) || 0,
    [tag, dailyChallenges]
  );

  if (!dailyChallenges) {
    return null;
  }

  return (
    <Slider
      className={classnames(className, s['root'])}
      contentClassName={classnames(contentClassName, s['content'])}
      scrollToIndex={selectedIndex}
      renderPrevButton={({ onClick, className: prevButtonClassName }) => (
        <DefaultPreviousButton
          onClick={onClick}
          className={classnames(prevButtonClassName, s['prev-button'])}
          arrowSize={IconSize.SMALL}
        />
      )}
      renderNextButton={({ onClick, className: nextButtonClassName }) => (
        <DefaultNextButton
          onClick={onClick}
          className={classnames(nextButtonClassName, s['next-button'])}
          arrowSize={IconSize.SMALL}
        />
      )}
    >
      {dailyChallenges.map(challenge => {
        const isSelected = challenge.tag.tag === tag;
        return (
          <DailyChallengeTag
            key={challenge.day}
            challenge={challenge}
            isSelected={isSelected}
            ref={node => {
              if (node && isSelected) {
                selectedTagRef.current = node;
              }
            }}
          />
        );
      })}
    </Slider>
  );
};
DailyChallengeSlider.displayName = 'DailyChallengeSlider';

export default DailyChallengeSlider;
