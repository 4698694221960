import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import OrderDropdown from '../../_partials/OrderDropdown';
import BasePageLayout from '../../_partials/BasePageLayout';
import DefaultPageContent from '../../_partials/DefaultPageContent';

import s from './BrowsePage.scss';

export interface Props {
  pageType?: string;
  hasOrderSubnav?: boolean;
}

const BrowsePage: React.FC<Props> = ({ pageType, hasOrderSubnav }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;

  const rightSideOrderDropdown = hasOrderSubnav && isDesktop;
  const headerRightSideContent = rightSideOrderDropdown && (
    <OrderDropdown className={s['order-dropdown']} />
  );

  return (
    <OverlayContextProvider value={{ showDailyDeviationLabel: true }}>
      <BasePageLayout
        title={t([
          `subnavigation.title.${pageType}`,
          'subnavigation.title.home',
        ])}
        headerRightSideContent={headerRightSideContent}
        allowSaleBanner={isDesktop}
        headerClassName={s['header']}
        withNavigation
      >
        {isMobile && hasOrderSubnav && !rightSideOrderDropdown && (
          <div className={s['order-dropdown']}>
            <OrderDropdown />
          </div>
        )}

        <DefaultPageContent />
      </BasePageLayout>
    </OverlayContextProvider>
  );
};
BrowsePage.displayName = 'BrowsePage';

export default BrowsePage;
