import { connect, MapStateToProps } from 'react-redux';
import { Props } from './GroupPage';
import { AppState } from '../../../../types/store';
import { getThisGroup } from '../../../../selectors/page';
import { getIsBurgerMenuOpen } from '@wix/da-shared-react/pkg/SiteHeader/redux/selectors';

export type StateProps = Pick<Props, 'group' | 'isNetworkbarOpen'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  group: getThisGroup(state),
  isNetworkbarOpen: getIsBurgerMenuOpen(state),
});

export default connect(mapStateToProps);
