import {
  PapiDeviation,
  PapiUser,
  PapiGroup,
  PapiGallection,
} from '@wix/da-papi-types';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationListSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';

export function getNormalizedStreamItems({
  deviations,
  users,
  groups,
  collections,
}: {
  deviations?: PapiDeviation[];
  users?: PapiUser[];
  groups?: PapiGroup[];
  collections?: PapiGallection[];
}): {
  items: any[];
  itemType: string;
  entities?: any;
} {
  if (deviations) {
    const { entities, result } = normalize(deviations, deviationListSchema);
    return { items: result, itemType: 'deviation', entities };
  }

  // rest of the stuff is not normalized atm but if needed, can be added here

  if (users) {
    return { items: users, itemType: 'user' };
  }

  if (groups) {
    return { items: groups, itemType: 'group' };
  }

  if (collections) {
    return { items: collections, itemType: 'collection' };
  }

  return { items: [], itemType: 'unknown' };
}
