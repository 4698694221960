import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import partition from 'lodash/partition';
import chunk from 'lodash/chunk';
import { BiData, ContentNavbarClickBiEvent } from '@wix/da-bi/pkg/events';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { TabItem } from '@wix/da-ds/pkg/Tabs';
import UnderlinedTabs from '@wix/da-ds/pkg/Tabs/dsPresets/UnderlinedTabs';
import UnderlinedTab from '@wix/da-ds/pkg/Tabs/dsPresets/UnderlinedTabs/UnderlinedTab';
import StandardMenu from '@wix/da-ds/pkg/Dropdown/MenuPreset/StandardMenu';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import BetaBadge from '@wix/da-ds/pkg/BetaBadge';
import DollarHeart from '@wix/da-ds/pkg/Icons/16x16/DollarHeart';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import type { TabBarProps } from '../../types';
import { useScrollToActiveItem } from '../../hooks/useScrollToActiveItem';
import { useIsOverflowedHorizontal } from '../../hooks/useIsOverflowedHorizontal';

import s from './TabBar.scss';

export const DESKTOP_MAX_VISIBLE_ITEMS = 6;

export type Props = Pick<
  TabBarProps,
  'items' | 'activeMenuItem' | 'className' | 'children'
> & { forceExclusivesNewLabel?: boolean };
export type MenuItem = NonNullable<Props['items']>[0];

function getMenuItems(
  items: MenuItem[],
  activeMenuItem: Props['activeMenuItem'],
  isMobile: boolean
): [MenuItem[], MenuItem[]] {
  if (isMobile) {
    return [items, []];
  }

  const noOverflow = items.length <= DESKTOP_MAX_VISIBLE_ITEMS;
  if (noOverflow) {
    return [items, []];
  }

  // -1 because we're replacing the last item with 'more'
  const [visible, overflow] = chunk(items, DESKTOP_MAX_VISIBLE_ITEMS - 1);

  // Check if an item in the overflow menu is the active tab,
  const [[activeSubnav], nonActiveOverflowed] = partition(
    overflow,
    item => item.subnav === activeMenuItem
  );
  const isActiveInOverflow = activeMenuItem && activeSubnav;
  if (!isActiveInOverflow) {
    return [visible, overflow];
  }

  // if active is in overflow, un-overflow it and swap out the last
  // non-overflowed item
  const fromVisibleToOverflow = visible.pop() as MenuItem;
  return [
    [...visible, activeSubnav],
    [fromVisibleToOverflow, ...nonActiveOverflowed],
  ];
}

const TabBar: React.FC<Props> = ({
  items = [],
  activeMenuItem,
  className,
  children,
  forceExclusivesNewLabel,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { navListRef, activeItemRef } = useScrollToActiveItem(isMobile, 16);
  const { isOverflowed } = useIsOverflowedHorizontal(navListRef);

  const [actualItems, overflowItems] = useMemo((): [
    TabItem[],
    NullableDropdownItem[]
  ] => {
    const [visible, overflow] = getMenuItems(items, activeMenuItem, isMobile);
    return [
      visible.map(menuItem => ({
        index: menuItem.subnav,
        label: t(`browseHeader.page.${menuItem.subnav}`),
        labelExtras:
          forceExclusivesNewLabel &&
          menuItem.subnav === 'marketplace_exclusives' ? (
            <BetaBadge
              badgeType="custom"
              size="medium"
              variant="green"
              className={s['beta-badge']}
            >
              {t('browseHeader.page.marketplace_exclusives.newLabel')}
            </BetaBadge>
          ) : (
            menuItem.isBeta && (
              <BetaBadge
                badgeType="beta"
                size="medium"
                variant="green"
                className={s['beta-badge']}
              />
            )
          ),
        prefix:
          menuItem.subnav === 'marketplace_sell_your_art' ? (
            <DollarHeart size={IconSize.SMALLER} />
          ) : undefined,
        href: menuItem.url,
        hasNotification: menuItem.hasNewContent,
        biData: BiData<ContentNavbarClickBiEvent>({
          evid: 120,
          link_name: menuItem.subnav,
          indicator: menuItem.hasNewContent ? 1 : 0,
        }),
      })),
      overflow.map(menuItem => ({
        link: menuItem.url,
        label: t(`browseHeader.page.${menuItem.subnav}`),
        biData: BiData<ContentNavbarClickBiEvent>({
          evid: 120,
          link_name: menuItem.subnav,
        }),
      })),
    ];
  }, [activeMenuItem, isMobile, items, t, forceExclusivesNewLabel]);

  const setActiveItemRef = (node: HTMLLIElement) => {
    activeItemRef.current = node;
  };

  const tabSize = isMobile ? 'small' : 'big';

  return (
    <div
      className={classnames(
        s['root'],
        isOverflowed && s['scrollable'],
        className
      )}
      ref={navListRef}
    >
      {children}
      {!children && actualItems.length > 0 && (
        <div className={s['nav-list']}>
          <UnderlinedTabs
            className={s['tabs']}
            size={tabSize}
            items={actualItems}
            activeIndex={activeMenuItem}
            activeItemRef={setActiveItemRef}
            extraTabs={
              <>
                {overflowItems.length > 0 && (
                  <UnderlinedTab
                    className={s['more-menu']}
                    label={t('browseHeader.more')}
                    size={tabSize}
                    dropdown={
                      <StandardMenu
                        lightText
                        id="subnav-more"
                        className={s['more-list']}
                        items={overflowItems}
                      />
                    }
                  />
                )}
              </>
            }
          />
        </div>
      )}
    </div>
  );
};
TabBar.displayName = 'TabBar';

export default TabBar;
