import { useState, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import { useLayoutEffectWithoutSSRComplaining } from '@wix/da-hooks/pkg/useLayoutEffectWithoutSSRComplaining';
import { useResizeObserver } from '@wix/da-hooks/pkg/useResizeObserver';

// This is a modified version of the shared useIsOverflowed to make it work horizontally in this context

// an optional cache to keep measurements while component is unmounted
// this allows to immediately start in the right state while mounting,
// without needing to wait for the measurement
// make sure the key use is unique enough for the instance you are caching
// it for (eg. deviationid by itself is not enough, componentname-deviationid
// might work)
const cache: {
  [k: string]: boolean | null;
} = {};

export function useIsOverflowedHorizontal(
  ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
  throttleTimeout = 300,
  cacheKey?: string
) {
  const isReady = useRef(false);
  const [isOverflowed, setIsOverflowed] = useState(
    cache[cacheKey ?? ''] ?? false
  );
  const measure = useMemo(
    () =>
      throttle(
        () => {
          const contentNode = ref.current;

          if (!contentNode) {
            return;
          }

          const isOverflowedNow =
            contentNode.scrollWidth > contentNode.clientWidth;

          setIsOverflowed(isOverflowedNow);
          if (cacheKey) {
            cache[cacheKey] = isOverflowedNow;
          }
        },
        throttleTimeout,
        { trailing: true }
      ),
    [throttleTimeout, ref, cacheKey]
  );
  useResizeObserver(ref, measure);

  useLayoutEffectWithoutSSRComplaining(() => {
    measure();
    isReady.current = true;
  }, [measure]);

  return { isOverflowed, isReady: isReady.current };
}
