// MO7.1, MO7.1M
import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { BiData, SearchClickBiEvent } from '@wix/da-bi/pkg/events';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import SearchBar from './SearchBar';

import s from './ShopPageHeader.scss';

/** which tab are we automatically directed to when searching from the /shop page */
const DEFAULT_TAB_ON_SEARCH = 'exclusives';

export interface Props {
  searchTerm?: string;
}

/** shop page explanation text and search input, sits above subnav tabs */
const ShopPageHeader: React.FC<Props> = ({ searchTerm = '' }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const logBiEvent = useBiLogger();

  const submitCallback = useCallback(
    newSearchTerm => {
      logBiEvent(
        BiData<SearchClickBiEvent>({
          evid: 710,
          search_terms: newSearchTerm,
          sectionname: 'shop',
        })
      );

      window.location.assign(
        newSearchTerm
          ? getPathnameWithSearch(newSearchTerm)
          : getPathnameWithoutSearch()
      );
    },
    [logBiEvent]
  );

  return (
    <Section className={classnames(s['root'], searchTerm && s['with-search'])}>
      {!searchTerm && (
        <Heading className={s['title']}>{t('shopPage.header.title')}</Heading>
      )}

      <SearchBar
        className={s['search-bar']}
        id="shop-search-bar"
        name="q"
        placeholder={
          isMobile
            ? t('shopPage.header.searchFieldPlaceholder.mobile')
            : t('shopPage.header.searchFieldPlaceholder')
        }
        initialValue={searchTerm}
        buttonText={t('shopPage.header.searchButton')}
        onSubmit={submitCallback}
      />
    </Section>
  );
};

ShopPageHeader.displayName = 'ShopPageHeader';
export default ShopPageHeader;

// these are to handle the special /shop/search1=term vs /shop
// others: /shop/commissions/search1=term vs. /shop/commissions
function getPathnameWithoutSearch(): string {
  if (typeof window === 'undefined') {
    return '/shop';
  }
  const { pathname: windowPathname, search } = window.location;
  const newPathname =
    windowPathname === '/shop/search'
      ? '/shop'
      : windowPathname.replace('/search', '');

  const params = new URLSearchParams(search);
  params.delete('q');
  const newLocationSearch = params.toString();
  return newLocationSearch
    ? `${newPathname}?${params.toString()}`
    : newPathname;
}

function getPathnameWithSearch(searchTerm: string): string {
  if (typeof window === 'undefined') {
    return '/shop/search';
  }
  const { pathname: windowPathname, search } = window.location;

  let newPathname: string;
  if (windowPathname.endsWith('/search')) {
    newPathname = windowPathname;
  } else if (windowPathname === '/shop') {
    newPathname = `${windowPathname}/${DEFAULT_TAB_ON_SEARCH}/search`;
  } else {
    newPathname = `${windowPathname}/search`;
  }

  const params = new URLSearchParams(search);
  params.delete('q');
  params.append('q', searchTerm);
  return `${newPathname}?${params.toString()}`;
}
