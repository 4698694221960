import React, { useCallback, useContext, useMemo } from 'react';
import type { TorpedoProps } from '@wix/da-shared-react/pkg/Torpedo/Torpedo';
import MeasuredTorpedo from '@wix/da-shared-react/pkg/Torpedo/MeasuredTorpedo';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import type { TorpedoLayoutedItem } from '@wix/da-shared-react/pkg/Torpedo/types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import type { BrowseItem } from '../../../../types/api';
import ItemCard from '../../ItemCard';
import EmptyResult from '../../EmptyResult';
import { browseItemsToTorpedoItems, BrowseTorpedoItem } from '../helpers';
import { getItemId } from '../../../helpers/streams';
import { TORPEDO_DEFAULT_STRIP_OFFSET } from '../constants';
import { PapiDeviation } from '@wix/da-papi-types';

// DeviationTorpedo configuration params
const container = { width: 1024 };
const defaultSize = {
  desktop: { width: 1024, height: 0 },
  mobile: { width: 360, height: 0 },
};

export interface Props {
  items: BrowseItem[];
  contentStrip?: React.ReactNode;
  contentStripIndex?: number;
  isInfiniteScroll?: boolean;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
  renderThumbExtras?: (item: BrowseItem, index: number) => React.ReactNode;
}

const BrowseTorpedo: React.FC<Props> = ({
  items,
  contentStrip,
  contentStripIndex = TORPEDO_DEFAULT_STRIP_OFFSET,
  isInfiniteScroll,
  onRemove,
  renderThumbExtras,
}) => {
  const isMobile = useContext(MobileContext);
  const torpedoItems = useMemo(
    () => browseItemsToTorpedoItems(items, isMobile),
    [isMobile, items]
  );

  const renderTorpedoItem = useCallback(
    (
      props: TorpedoProps,
      {
        dto: { type, item },
        idx,
        width,
        height,
      }: TorpedoLayoutedItem<BrowseTorpedoItem>,
      itemStyle: React.CSSProperties
    ) => (
      <>
        <ItemCard
          itemIndex={idx}
          key={`item-${type}-${getItemId(item)}`}
          type={type}
          item={item}
          width={width}
          height={height}
          style={itemStyle}
          onRemove={onRemove}
        />
        {renderThumbExtras && renderThumbExtras(item, idx)}
      </>
    ),
    [onRemove, renderThumbExtras]
  );

  if (!torpedoItems || torpedoItems.length === 0) {
    return <EmptyResult />;
  }
  return (
    <MeasuredTorpedo
      items={torpedoItems}
      renderItem={renderTorpedoItem}
      container={container}
      cookieType={MeasuredCookieType.BROWSE}
      cookieDim={'width'}
      defaultSize={defaultSize}
      enableScrollVisibilityOptimisation={isInfiniteScroll}
      contentStrip={contentStrip}
      contentStripOffset={contentStripIndex}
    />
  );
};

export default BrowseTorpedo;
