/** A strip slider that gives its items a non-fixed size */
import React, { useDebugValue } from 'react';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { MeasuredSlider } from '@wix/da-shared-react/pkg/Slider';
import { getStripType, getItemId, getStripItems } from '../../helpers/streams';
import StripItem from './StripItem';
import { useGetMeasuredElementSize } from './hooks/useElementSize';

export interface Props {
  /** stream data and initial array of content and url pointing to larger collection */
  strip: PapiNetworkbarStrip;
  disableShowLess?: boolean;
  className?: string;
  contentClassName?: string;
}

const MeasuredStripSlider: React.FC<Props> = ({
  strip,
  className,
  contentClassName,
  disableShowLess,
}) => {
  const getMeasuredElementSize = useGetMeasuredElementSize(strip);
  const type = getStripType(strip);
  const items = getStripItems(strip);
  useDebugValue(type);
  if (!items) {
    return null;
  }

  return (
    <MeasuredSlider
      className={className}
      contentClassName={contentClassName}
      renderSliderItems={defaultSize => {
        const elementSize = getMeasuredElementSize(defaultSize);
        return items.map((item, index) => (
          <StripItem
            itemIndex={index}
            key={`strip-${type}-${getItemId(item)}`}
            type={type!}
            item={item}
            elementSize={elementSize}
            disableShowLess={disableShowLess}
          />
        ));
      }}
    />
  );
};
MeasuredStripSlider.displayName = 'MeasuredStripSlider';

export default MeasuredStripSlider;
