import { connect, MapStateToProps } from 'react-redux';
import { Props } from './NoDeviantsWatchedEmptyResult';
import { AppState } from '../../../types/store';
import { getWatchSuggestions } from '../../../selectors/page';

export type StateProps = Pick<Props, 'watchSuggestions'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  watchSuggestions: getWatchSuggestions(state),
});

export default connect(mapStateToProps);
