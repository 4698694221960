import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@wix/da-shared-react/pkg/ToggleButtonGroup';
import GalleryTorpedoIcon from '@wix/da-ds/pkg/Icons/24x24/GalleryTorpedo';
import StackedIcon from '@wix/da-ds/pkg/Icons/24x24/Stacked';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import { SubNavigation } from '../../../../types/store';
import { BiData, WatchGroupByArtistClickBiEvent } from '@wix/da-bi/pkg/events';

export interface Props {
  subnav?: SubNavigation;
}

const iconByView = {
  grid: GalleryTorpedoIcon,
  stack: StackedIcon,
};

const ViewSwitcher: React.FC<Props> = ({ subnav }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isStacked = subnav?.currentValue === 'stack';

  const buttons = useMemo(
    () =>
      subnav?.options?.map(option => ({
        tooltipText: t(`view.${option.subnav}.button.tooltip`),
        ariaLabel: t(`view.${option.subnav}.button.a11y.label`),
        icon: iconByView[option.subnav],
        value: option.subnav,
        href: option.url,
        biData: BiData<WatchGroupByArtistClickBiEvent>({
          evid: 412,
          is_grouped: isStacked ? (1 as const) : (0 as const),
        }),
      })),
    [isStacked, subnav?.options, t]
  );

  if (!buttons) {
    return null;
  }

  if (isMobile) {
    const switcherButton = buttons.find(
      button => button.value !== subnav?.currentValue
    );
    if (switcherButton) {
      return (
        <IconButton
          size="medium"
          icon={switcherButton.icon}
          href={switcherButton.href}
          aria-label={switcherButton.ariaLabel}
          biData={switcherButton.biData}
        />
      );
    }

    return null;
  }

  return (
    <ToggleButtonGroup
      value={subnav?.currentValue ?? 'grid'}
      buttons={buttons}
    />
  );
};
ViewSwitcher.displayName = 'ViewSwitcher';

export default ViewSwitcher;
