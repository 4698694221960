import React, { useContext } from 'react';
import classnames from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SiteFooter from '@wix/da-shared-react/pkg/SiteFooter';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import Header from '../../../Header';
import NetworkBarWrapper from '../NetworkBarWrapper';
import ScrollDependentStickyContainer from '../../../ScrollDependentStickyContainter';
import { BiEvent } from '@wix/da-bi/pkg/events';

import s from './PageContainer.scss';

export interface Props {
  withNetworkBar?: boolean;
  wrapperClassName?: string;
  biData?: BiEvent;
  /** optional component above the tab header */
  aboveHeader?: React.ReactNode;
  headerIsStickyOnScrollUp?: boolean;
  customHeader?: React.ReactNode;
  withTabHeader?: boolean;
  title?: React.ReactNode;
  titleClassName?: string;
  tagLine?: React.ReactNode;
  headerClassName?: string;
  innerHeaderClassName?: string;
  headerLeftSideContent?: React.ReactNode;
  headerRightSideContent?: React.ReactNode;
  withNavigation?: boolean;
  headerNavigation?: React.ReactNode;
  /** optional component below the tab header */
  belowHeader?: React.ReactNode;
  isSearchPage?: boolean;
  isInfiniteScroll?: boolean;
  hasMore?: boolean;
  withFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PageContainer: React.FC<Props> = ({
  withNetworkBar = true,
  wrapperClassName,
  biData = {},
  aboveHeader,
  headerIsStickyOnScrollUp,
  customHeader,
  withTabHeader = true,
  title,
  titleClassName,
  tagLine,
  headerClassName,
  innerHeaderClassName,
  headerLeftSideContent,
  headerRightSideContent,
  withNavigation,
  headerNavigation,
  belowHeader,
  isSearchPage,
  withFooter = false,
  className,
  children,
}) => {
  const isMobile = useContext(MobileContext);

  return (
    <NetworkBarWrapper
      isNetworkBarEnabled={withNetworkBar}
      className={classnames(
        s['root'],
        wrapperClassName,
        !withFooter && !withNetworkBar && s['no-footer-page']
      )}
    >
      <BiLoggerContextProvider value={{ ...biData }}>
        {aboveHeader}
        {headerIsStickyOnScrollUp && (
          <ScrollDependentStickyContainer className={s['scroll-sticky']}>
            {customHeader}
            {withTabHeader && (
              <Header
                title={title}
                tagline={tagLine}
                rightSideContent={headerRightSideContent}
                leftSideContent={headerLeftSideContent}
                className={classnames(headerClassName, s['header'])}
                innerClassName={innerHeaderClassName}
                withNavigation={withNavigation}
                titleClassName={titleClassName}
                isSticky={false}
              >
                {headerNavigation}
              </Header>
            )}
          </ScrollDependentStickyContainer>
        )}
        {!headerIsStickyOnScrollUp && (
          <>
            {customHeader}
            {withTabHeader && (
              <Header
                title={title}
                tagline={tagLine}
                rightSideContent={headerRightSideContent}
                leftSideContent={headerLeftSideContent}
                className={classnames(headerClassName, s['header'])}
                innerClassName={innerHeaderClassName}
                withNavigation={withNavigation}
                titleClassName={titleClassName}
                isSticky={
                  headerIsStickyOnScrollUp ?? (!isMobile || withNavigation)
                }
              >
                {headerNavigation}
              </Header>
            )}
          </>
        )}
        {belowHeader}
        <div
          className={classnames(
            s['page'],
            isSearchPage && s['search'],
            withTabHeader && s['with-header']
          )}
        >
          <div className={className}>{children}</div>
        </div>
      </BiLoggerContextProvider>
      {withFooter && <SiteFooter />}
    </NetworkBarWrapper>
  );
};
PageContainer.displayName = 'PageContainer';

export default PageContainer;
