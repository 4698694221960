import {
  PapiDeviation,
  PapiUser,
  PapiGallection,
  PapiGroup,
} from '@wix/da-papi-types';
import MediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { TorpedoItem } from '@wix/da-shared-react/pkg/Torpedo/types';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { BrowseItem } from '../../../types/api';
import { ItemTypes } from '../../../constants';
import { getItemType } from '../../helpers/streams';

export interface BrowseTorpedoItem extends TorpedoItem {
  item: BrowseItem;
  type: ItemTypes;
}

const browseItemToTorpedoItem = (
  item: BrowseItem,
  isMobile?: boolean
): BrowseTorpedoItem | undefined => {
  const type = getItemType(item);
  switch (type) {
    case ItemTypes.Deviation:
      const deviation = item as PapiDeviation;
      const id = `deviation-${deviation.deviationId}`;
      if (isMobile) {
        return {
          id,
          width: 3430,
          height: 2290,
          type,
          item,
        };
      }
      const { width = 1000, height = 1000 } =
        MediaService.getThumbDimensions(deviation);
      return {
        id,
        width,
        height,
        type,
        item,
        duperbrowseItemId: getDeviationEntityId(deviation),
      };
    case ItemTypes.User:
      return {
        id: `artist-${(item as PapiUser).userId}`,
        width: 4380,
        height: 2880,
        type,
        item,
      };
    case ItemTypes.Gallection:
      return {
        id: `gallection-${(item as PapiGallection).folderId}`,
        width: isMobile ? 3430 : 4380,
        height: isMobile ? 2290 : 4580,
        type,
        item,
      };
    case ItemTypes.Group:
      return {
        id: `group-${(item as PapiGroup).user.userId}`,
        width: isMobile ? 1000 : 8870,
        height: isMobile ? 1000 : 3430,
        type,
        item,
      };
    default:
      return;
  }
};

export const browseItemsToTorpedoItems = (
  items: BrowseItem[],
  isMobile?: boolean
): BrowseTorpedoItem[] => {
  const browseTorpedoItems: BrowseTorpedoItem[] = [];
  items.forEach(item => {
    const torpedoItem = browseItemToTorpedoItem(item, isMobile);
    if (torpedoItem) {
      browseTorpedoItems.push(torpedoItem);
    }
  });
  return browseTorpedoItems;
};
