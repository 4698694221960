import { connect, MapStateToProps } from 'react-redux';
import { Props } from './SearchPage';
import { AppState } from '../../../../types/store';
import {
  getOrderSubnavigation,
  getRelatedTags,
  getSearchQuery,
  getSearchType,
  getMainContentUrl,
  getThisTag,
} from '../../../../selectors/page';
import { getInitialOffset, getTotal } from '../../../../selectors/stream';

export type StateProps = Pick<
  Props,
  | 'hasOrderSubnav'
  | 'initialOffset'
  | 'searchTerm'
  | 'searchType'
  | 'mainContentUrl'
  | 'totalResults'
  | 'relatedTags'
  | 'thisTag'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  hasOrderSubnav: !!getOrderSubnavigation(state),
  initialOffset: getInitialOffset(state),
  searchTerm: getSearchQuery(state) || '',
  searchType: getSearchType(state),
  mainContentUrl: getMainContentUrl(state),
  totalResults: getTotal(state),
  relatedTags: getRelatedTags(state),
  thisTag: getThisTag(state),
});

export default connect(mapStateToProps);
