import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink/PlainButtonOrLink';
import { formatDate, DATE_FORMATS } from '@wix/da-ds/pkg/Time/functions';
import DailyChallengeIcon from '@wix/da-ds/pkg/Icons/16x16/DailyChallenge';
import { BiData, TagClickBiEvent } from '@wix/da-bi/pkg/events';
import { DailyChallenge } from '../../types';

import s from './DailyChallengeTag.scss';

export interface Props {
  challenge: DailyChallenge;
  isSelected?: boolean;
  className?: string;
}

const DailyChallengeTag = React.forwardRef<HTMLElement | null, Props>(
  ({ challenge, isSelected, className }, ref) => {
    const { day, today, tag } = challenge;
    const { tag: tagString, url } = tag;

    const isShowingTodaysIcon = today;

    const { t, i18n } = useTranslation();
    const formattedDay = formatDate(
      // hardcode the timezone so we don't get off by 1's for the date
      // btwn client & server due to localization
      `${day}T00:00:00`,
      DATE_FORMATS.Date.DayMonth,
      i18n.language
    );

    return (
      <PlainButtonOrLink
        className={classnames(
          className,
          s['root'],
          'ds-card',
          isSelected && 'ds-card-selected-thick',
          today && s['today']
        )}
        innerRef={ref as (node: HTMLElement | null) => void}
        href={url}
        biData={BiData<TagClickBiEvent>({
          evid: 391,
          tag_text: tagString,
          link_url: url,
          tagid: tag.itemId,
        })}
      >
        <div className={s['date']}>
          {isShowingTodaysIcon && (
            <DailyChallengeIcon className={s['today-icon']} />
          )}
          {today ? t('tagPage.dailyChallengeTag.today') : formattedDay}
        </div>
        <div className={s['tag']}>{`#${tagString}`}</div>
      </PlainButtonOrLink>
    );
  }
);
DailyChallengeTag.displayName = 'DailyChallengeTag';

export default DailyChallengeTag;
