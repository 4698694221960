import { connect, MapStateToProps } from 'react-redux';
import { Props } from './FeedSidebarContent';
import { AppState } from '../../types/store';
import { getStrips } from '../../selectors/page';

export type StateProps = Pick<Props, 'contentStrip'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  contentStrip: getStrips(state)?.[0],
});

export default connect(mapStateToProps);
