import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PapiUser } from '@wix/da-papi-types';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import JoinGroupButton from '@wix/da-shared-react/pkg/JoinGroupButton';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import BasePageLayout from '../../_partials/BasePageLayout';
import GroupMenu from '../../../GroupMenu';
import { Url } from '@wix/da-url';

import s from './GroupPage.scss';

export interface Props {
  group?: PapiUser;
  isNetworkbarOpen?: boolean;
}

const GroupPage: React.FC<Props> = ({ group, isNetworkbarOpen }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  if (!group) {
    return null;
  }
  return (
    <BasePageLayout
      title={
        <UserLink user={group} className={s['groupname']}>
          {t('subnavigation.title.group', { groupname: group.username })}
        </UserLink>
      }
      tagLine={
        <div className={s['extras']}>
          <span>
            {group.profile?.tagline && (
              <span className={s['tagline']}>{group.profile?.tagline}</span>
            )}
            {group.stats && (
              <span className={s['watchers']}>
                {t('subnavigation.subtitle.member', {
                  count: group.extendedGroup?.totalCount ?? 1,
                })}
              </span>
            )}
          </span>
          {isMobile && (
            <div className={s['join-button-container']}>
              <JoinGroupButton
                group={group}
                className={classnames(s['button'], s['join-button'])}
              />
              <GhostButton
                variant="main"
                size="small"
                href={Url.userLink(group.username)}
              >
                {t('subnavigation.button.visitGroup')}
              </GhostButton>
              <GroupMenu group={group} className={s['button']} />
            </div>
          )}
        </div>
      }
      headerRightSideContent={
        isMobile ? undefined : (
          <BiLoggerContextProvider value={{ sectionname: 'button' }}>
            <UserLink user={group} className={s['rightside-button']}>
              <GhostButton
                size="medium"
                variant="main"
                isFakeButton
                className={s['visit-button']}
              >
                {t('subnavigation.button.visitGroup')}
              </GhostButton>
            </UserLink>
            <div
              className={classnames(s['group-button'], s['rightside-button'])}
            >
              <JoinGroupButton
                className={s['join-button']}
                group={group}
                type="solid"
                showAdminButton
              />
            </div>
            <GroupMenu group={group} className={s['rightside-button']} />
          </BiLoggerContextProvider>
        )
      }
    />
  );
};
GroupPage.displayName = 'GroupPage';

export default GroupPage;
