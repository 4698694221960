// DS3.10
// Large Input Field w/ Button
import React, { useState } from 'react';
import classnames from 'classnames';
import SearchInput from '@wix/da-ds/pkg/formControls/dsPresets/SearchInput';
import { type Props as SearchInputProps } from '@wix/da-ds/pkg/formControls/dsPresets/SearchInput/SearchInput';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import CloseIcon from '@wix/da-ds/pkg/Icons/24x24/Close';

import s from './SearchBar.scss';

const forcedLightThemeClasses =
  'theme-light forced-theme-light surface surface-primary';

type SearchInputPropsUnused = 'onSubmit' | 'onClearClick' | 'label' | 'value';
type SearchInputPropsUsed = Omit<SearchInputProps, SearchInputPropsUnused>;
export interface Props extends SearchInputPropsUsed {
  buttonText: string;
  initialValue?: string;
  className?: string;
  onSubmit?: (inputValue: string) => void;
}

/** shop page explanation text and search input, sits above subnav tabs */
const SearchBar = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      buttonText,
      initialValue = '',
      className,
      onSubmit: onSubmitProp = noop,
      ...inputProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(initialValue);

    const onSubmit = ev => {
      ev.preventDefault();
      const newSearchTerm = inputValue.trim();
      // TODO?: likely need some check here to prevent empty searches
      // other than onClearClick
      onSubmitProp(newSearchTerm);
    };

    const onClearClick = () => {
      // doesn't clear the active search (by resubmitting with no term) - just clears input
      setInputValue('');
    };

    return (
      <form
        className={classnames(s['root'], forcedLightThemeClasses, className)}
        onSubmit={onSubmit}
      >
        <SearchInput
          ref={ref}
          className={s['input']}
          wrapperClassName={s['wrapper']}
          onChange={ev => setInputValue(ev.target.value)}
          isLabelHidden
          noBottomSpacing
          {...(inputProps as SearchInputProps)}
          value={inputValue}
          onClearClick={onClearClick}
          // TODO: send this in redundantly so we can add type="button"
          // type="submit" is default and that interferes with the submit below
          postfix={
            inputValue ? (
              <PlainButtonOrLink
                className={s['close-button']}
                onClick={onClearClick}
                type="button"
              >
                <CloseIcon />
              </PlainButtonOrLink>
            ) : null
          }
        />

        <PlainButtonOrLink className={s['button']} type="submit">
          {buttonText}
        </PlainButtonOrLink>
      </form>
    );
  }
);

SearchBar.displayName = 'SearchBar';
export default SearchBar;
