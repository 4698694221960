import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import Strips from '../../../Strips';
import ContentLoader from '../../../ContentLoader';
import BasePageLayout from '../../_partials/BasePageLayout';
import OrderDropdown from '../../_partials/OrderDropdown';
import ShopEmptyResult from './ShopEmptyResult';
import ShopThumbLayout from './ShopThumbLayout';
import ShopPageHeader from './ShopPageHeader';
import ShopFiltersDisclosure from './ShopFiltersDisclosure';
import ShopFiltersForm from './ShopFiltersForm';
import useShopFilterState from './ShopFiltersForm/useShopFilterState';
import SellersLandingPageLink from './SellersLandingPageLink';
import ShopSellYourArtPage from './ShopSellYourArtPage';

import s from './ShopPage.scss';

const SEARCHTYPE_CONVERTED_TO_SECTIONNAME = {
  downloads: 'Premium Downloads',
  commissions: 'Commissions',
  adoptables: 'Adoptables',
  exclusives: 'Exclusives',
};

export interface Props {
  strips?: PapiNetworkbarStrip[];
  searchPageType?: string;
  searchTerm?: string;
  order?: string;
  totalResults?: number;
  initialOffset?: number;
  isInfiniteScroll?: boolean;
  isMobile?: boolean;
}

/** Pages to display products that users are selling (subscriptions, commissions,
 *  downloads, etc.).
 *
 *  Since the 'all' page is very different from the single product pages, we'll
 *  split into variants and pass a set of elements to them that are common to both
 *  the 'all' and single product pages */
const ShopPage: React.FC<Props> = props => {
  const { totalResults = 0, ...rest } = props;
  const { searchPageType, searchTerm, isMobile, strips } = rest;
  const { t } = useTranslation();

  const isAllProductsVariation = searchPageType === 'all';
  const isDropdownInHeader = !isAllProductsVariation && !isMobile;
  const isShowingSellYourArtPage = !searchTerm && isAllProductsVariation;
  const isShowingTotalResults = !isMobile && !!searchTerm;

  return (
    <BasePageLayout
      allowSaleBanner={false}
      withNetworkBar={false}
      contentClassName={c(
        isAllProductsVariation &&
          isShowingSellYourArtPage &&
          s['sell-your-art-content']
      )}
      customHeader={<ShopPageHeader searchTerm={searchTerm} />}
      title={
        isShowingTotalResults && (
          <span className={s['results']}>
            {t('shopPage.numResults', {
              postProcess: ['reactPostprocessor', 'featureBrancher'],
              formattedCount: shortenNumber(totalResults),
              count: totalResults,
              term: (
                <span className={s['results-searchterm']}>{searchTerm}</span>
              ),
            })}
          </span>
        )
      }
      // order selector is placed inside the header on desktop
      headerRightSideContent={
        isDropdownInHeader && <OrderDropdown localeSpecifier="shopPage" />
      }
    >
      {isShowingSellYourArtPage ? (
        <ShopSellYourArtPage key="sell-your-art" strips={strips} />
      ) : (
        <ShopPageSingleProduct key="single-product" {...props} />
      )}
    </BasePageLayout>
  );
};
export default ShopPage;

// ----------------------------------------------------------------------------
/** the 'all' products page displays a strip for each product type and no filters */
const ShopPageAllProducts: React.FC<Props> = ({
  strips = [],
  initialOffset,
  searchTerm,
}) => {
  return (
    <BiLoggerContextProvider value={{ _rankOffset: initialOffset }}>
      {strips?.length ? (
        <Strips
          className={s['all-tab-strips']}
          stripClassName={s['all-tab-strip']}
        />
      ) : (
        <ShopEmptyResult searchTerm={searchTerm} />
      )}

      <SellersLandingPageLink />
    </BiLoggerContextProvider>
  );
};
ShopPageAllProducts.displayName = 'ShopPageAllProducts';

// ----------------------------------------------------------------------------
/** single product pages have a inf. scrolling grid to display results and
 *  filters to aid searching */
const ShopPageSingleProduct: React.FC<Props> = ({
  searchPageType = 'all',
  searchTerm,
  initialOffset,
  isInfiniteScroll,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [areFiltersOpen, setFiltersOpen] = useState(!isMobile);
  const { activeFilterCount, resetFiltersAndReload } = useShopFilterState();

  const openButtonLabel =
    isMobile && !!activeFilterCount
      ? t('shopPage.openFiltersButtonLabel.withCount', {
          activeFilterCount,
        })
      : t('shopPage.openFiltersButtonLabel');

  const filtersColumnClass = areFiltersOpen
    ? s['filters-column-open']
    : s['filters-column-closed'];

  return (
    <div className={s['page-layout']}>
      <div className={filtersColumnClass}>
        <ShopFiltersDisclosure
          openButtonClassName={s['open-filters-button']}
          openButtonLabel={openButtonLabel}
          panelClassName={s['filters']}
          isOpen={areFiltersOpen}
          onOpenClick={() => setFiltersOpen(true)}
          onCloseClick={() => setFiltersOpen(false)}
        >
          <ShopFiltersForm />
        </ShopFiltersDisclosure>

        {isMobile && (
          <OrderDropdown
            className={s['order-dropdown']}
            nativeTitleClassName={s['order-dropdown-native-title']}
            localeSpecifier="shopPage"
          />
        )}
      </div>

      <div className={s['grid-layout']}>
        <BiLoggerContextProvider
          value={{
            sectionname: SEARCHTYPE_CONVERTED_TO_SECTIONNAME[searchPageType],
            _rankOffset: initialOffset,
          }}
        >
          <ContentLoader
            customEmptyResult={
              <ShopEmptyResult
                searchTerm={searchTerm}
                // in both mobile and desktop, this link should reload the page
                clearFilters={
                  activeFilterCount ? resetFiltersAndReload : undefined
                }
              />
            }
          >
            <ShopThumbLayout
              searchPageType={searchPageType}
              isNarrowGrid={areFiltersOpen}
            />
          </ContentLoader>

          {!isInfiniteScroll && <SellersLandingPageLink />}
        </BiLoggerContextProvider>
      </div>
    </div>
  );
};
ShopPageSingleProduct.displayName = 'ShopPageSingleProduct';
