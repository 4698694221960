import React, { useContext } from 'react';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import DesktopArtistWatchPage from './DesktopArtistWatchPage';
import MobileArtistWatchPage from './MobileArtistWatchPage';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { BiEvent } from '@wix/da-bi/pkg/events';

export interface Props {
  feedType?: string;
  thisUser: PapiUser;
  isEmpty?: boolean;
  showRemoveAll?: boolean;
  removeAll: (biData: BiEvent) => void;
  removeDeviation: (deviation: PapiDeviation, offset: number) => void;
}

const ArtistWatchPage: React.FC<Props> = props => {
  const isMobile = useContext(MobileContext);

  return (
    <BiLoggerContextProvider
      value={{
        section_typeid: ResourceType.USER,
        sectionid: props.thisUser.userId,
        is_grouped: 0,
      }}
    >
      {isMobile ? (
        <MobileArtistWatchPage {...props} />
      ) : (
        <DesktopArtistWatchPage {...props} />
      )}
    </BiLoggerContextProvider>
  );
};
ArtistWatchPage.displayName = 'ArtistWatchPage';

export default ArtistWatchPage;
