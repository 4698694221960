import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Url } from '@wix/da-url';
import { PapiDeviation, PapiNetworkbarStrip } from '@wix/da-papi-types';
import {
  BiContext,
  BiLoggerContextProvider,
} from '@wix/da-bi/pkg/BiLogger.context';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/hooks';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/constants';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { getDeviationType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import type { BrowseItem } from '../../../../../types/api';
import { isDeviation } from '../../../../helpers/streams';
import DeviantsBar from '../../../DeviantsBar';
import DeviantsSearchButton from '../../../DeviantsSearchButton';
import BasePageLayout from '../../_partials/BasePageLayout';
import DefaultPageContent from '../../_partials/DefaultPageContent';
import ViewSwitcher from '../../_partials/ViewSwitcher';
import Strips from '../../../Strips';
import DesktopWatchFTUE from './DesktopWatchFTUE';
import { BiEvent } from '@wix/da-bi/pkg/events';

import s from './WatchPage.scss';

export interface Props {
  feedType?: string;
  isStacked?: boolean;
  watchCount: number;
  isEmpty?: boolean;
  /** the current user is new and watching 0 deviants */
  isWatchingNoDeviants?: boolean;
  strips?: PapiNetworkbarStrip[];
  removeDeviation: (deviation: PapiDeviation, offset: number) => void;
  removeAllFromStack: (
    biData: BiEvent,
    stack?: {
      deviation: PapiDeviation;
      offset: number;
    }
  ) => void;
}

const WatchPage: React.FC<Props> = ({
  feedType,
  isStacked,
  watchCount,
  isEmpty,
  isWatchingNoDeviants,
  strips,
  removeDeviation,
  removeAllFromStack,
}) => {
  const { t } = useTranslation();
  const biData = useContext(BiContext);
  const isMobile = useContext(MobileContext);
  const { hasFlag: hasFTUE, dropFlag: dropFTUE } = useFirstTimeFlag(
    FirstTimeFlag.IsDyw3RemoveNotSeen
  );
  const [showFTUE, setShowFTUE] = useState(hasFTUE);
  const onDropFlag = useCallback(() => {
    dropFTUE();
    setShowFTUE(false);
  }, [dropFTUE, setShowFTUE]);

  const getItemUrl = useCallback(
    (item: BrowseItem) => {
      if (!isDeviation(item) || !item.author) {
        return;
      }

      return Url.browseDeviantsYouWatchLink(
        feedType as any,
        item.author.username
      );
    },
    [feedType]
  );

  const showViewSwitcher = feedType === 'deviations' && !isEmpty;
  const renderFTUEOnFirstThumb = useCallback(
    (item, idx) => {
      return (
        idx === 0 &&
        isDeviation(item) &&
        getDeviationType(item) !== DeviationTypes.TIER &&
        showFTUE &&
        showViewSwitcher &&
        !isMobile && (
          <DesktopWatchFTUE className={s['ftue']} onDropFlag={onDropFlag} />
        )
      );
    },
    [showFTUE, showViewSwitcher, isMobile, onDropFlag]
  );

  const onRemove = useMemo(() => {
    if (!isStacked) {
      return removeDeviation;
    }

    return (deviation: PapiDeviation, offset: number) =>
      removeAllFromStack(
        {
          ...biData,
          is_grouped: isStacked ? 1 : 0,
          sectionname: 'general',
        } as any,
        { deviation, offset }
      );
  }, [biData, isStacked, removeAllFromStack, removeDeviation]);

  // don't show the deviantsBar and tab header if user isn't watching anyone
  const showDYWHeader = !isEmpty;
  const isEmptyButShowingStrips =
    isEmpty && !!strips?.length && !isWatchingNoDeviants;

  return (
    <BiLoggerContextProvider
      value={{
        is_grouped: isStacked ? 1 : 0,
      }}
    >
      <BasePageLayout
        title={isMobile ? null : t('subnavigation.title.watch')}
        tagLine={
          isMobile || isEmpty
            ? undefined
            : t('subnavigation.subtitle.watchingDeviants', {
                count: watchCount,
              })
        }
        {...((showDYWHeader as boolean) && {
          customHeader: <DeviantsBar />,
          withNavigation: true,
          headerIsStickyOnScrollUp: true,
          headerLeftSideContent: isMobile && showViewSwitcher && (
            <ViewSwitcher />
          ),
          headerRightSideContent: isMobile ? (
            <DeviantsSearchButton />
          ) : (
            showViewSwitcher && (
              <div className={s['view-switcher']}>
                <ViewSwitcher />
              </div>
            )
          ),
        })}
        withTabHeader={showDYWHeader}
        contentClassName={classnames(
          s['content'],
          showViewSwitcher && s['with-view-switcher']
        )}
      >
        <OverlayContextProvider
          value={{
            showPermanentEngagementIcon: true,
            showAuthorWatchButton: false,
          }}
        >
          <DefaultPageContent
            getItemUrl={isStacked ? getItemUrl : undefined}
            onRemove={onRemove}
            renderThumbExtras={renderFTUEOnFirstThumb}
            emptyContentStrip={isEmptyButShowingStrips && <Strips />}
            contentStripIndex={3}
            withWatchOrSubscribeButtons={false}
          />
        </OverlayContextProvider>
      </BasePageLayout>
    </BiLoggerContextProvider>
  );
};
WatchPage.displayName = 'WatchPage';

export default WatchPage;
