import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import AddIcon from '@wix/da-ds/pkg/Icons/24x24/Add';
import useSubmitButtonProps from './useSubmitButtonProps';

export interface Props {
  className?: string;
}

const SubmitButton: React.FC<PropsWithChildren<Props>> = ({ className }) => {
  const { t } = useTranslation();
  const submitButtonProps = useSubmitButtonProps();

  return (
    <TextButton
      className={className}
      variant="main"
      size="large"
      icon={AddIcon}
      {...submitButtonProps}
    >
      {t('browse.submitButton.mobile')}
    </TextButton>
  );
};
SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
