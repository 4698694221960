import React from 'react';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { ThemeSurface } from '@wix/da-react-context/pkg/ThemeContext';
import Surface from '@wix/da-ds/pkg/Surface';
import GlobalShopStatisticsBlock from './_partials/GlobalShopStatisticsBlock';
import DreamupBlock from './_partials/DreamupBlock';
import { useStrips } from './strips/useStrips';
import commonStyles from './common.scss';

interface Props {
  strips?: PapiNetworkbarStrip[];
}

const ShopSellYourArtPage: React.FC<Props> = ({ strips }) => {
  const renderedStrips = useStrips(strips);

  return (
    <>
      {renderedStrips}
      <Surface
        type={ThemeSurface.SECONDARY}
        className={commonStyles['section-horizontal-sizing']}
      >
        <GlobalShopStatisticsBlock />
      </Surface>
      <Surface
        type={ThemeSurface.PRIMARY}
        className={commonStyles['section-horizontal-sizing']}
      >
        <DreamupBlock />
      </Surface>
    </>
  );
};

ShopSellYourArtPage.displayName = 'ShopSellYourArtPage';

export default ShopSellYourArtPage;
