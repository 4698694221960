import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import MobileNativeDropdown from '@wix/da-ds/pkg/Dropdown/MobileNativeDropdown';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import { SubNavigation } from '../../../../types/store';
import { BiData, SortTypeSelectBiEvent } from '@wix/da-bi/pkg/events';
import s from './OrderDropdown.scss';

export interface Props {
  subnav?: SubNavigation;
  className?: string;
  menuClassName?: string;
  nativeTitleClassName?: string;
  localeSpecifier?: string;
  arrowDown?: (className: string) => React.ReactNode;
}

const OrderDropdown: React.FC<Props> = ({
  subnav,
  className,
  menuClassName,
  nativeTitleClassName,
  localeSpecifier = 'subnavigation',
  arrowDown,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const baseLocaleKey = `${localeSpecifier}.orderDropdown.values`;
  const mobileLocale = isMobile ? '.mobile' : '';

  const dropdownItems: NullableDropdownItem[] | undefined = useMemo(
    () =>
      subnav?.options?.map(option => {
        const label = t(`${baseLocaleKey}.${option.subnav}${mobileLocale}`, {
          defaultValue: option.subnav,
        });

        if (isMobile) {
          return {
            value: option.subnav,
            label,
            selected: option.subnav === subnav.currentValue,
            biData: BiData<SortTypeSelectBiEvent>({
              evid: 122,
              menu_selection: option.subnav,
              dropdown_type: 'sort',
            }),
          };
        }

        return {
          link: option.url,
          value: option.subnav,
          label,
          biData: BiData<SortTypeSelectBiEvent>({
            evid: 122,
            menu_selection: option.subnav,
            dropdown_type: 'sort',
          }),
        };
      }),
    [subnav, isMobile, t, baseLocaleKey, mobileLocale]
  );

  const onMobileSelect = useCallback(
    selected => {
      const found = subnav?.options?.find(o => o.subnav === selected);
      if (found) {
        window.location.assign(found.url);
      }
    },
    [subnav]
  );

  if (!dropdownItems?.length) {
    return null;
  }

  if (isMobile) {
    return (
      <label className={className}>
        <VisuallyHidden>{t('facetPage.a11y.orderDropdown')}</VisuallyHidden>
        <MobileNativeDropdown
          className={s['dropdown-wrapper']}
          arrowClassName={s['order-dropdown-arrow']}
          titleClassName={classnames(
            s['order-dropdown-title'],
            nativeTitleClassName
          )}
          title={t(`${baseLocaleKey}.${subnav?.currentValue}${mobileLocale}`, {
            defaultValue: subnav?.currentValue,
          })}
          onSelect={onMobileSelect}
          items={dropdownItems}
        />
      </label>
    );
  }

  return (
    <div className={classnames(s['dropdown-wrapper'], className)}>
      <StandardDropdown
        role="menu"
        selectedValue={subnav?.currentValue ?? undefined}
        items={dropdownItems}
        className={s['dropdown']}
        menuClassName={menuClassName}
        toggleClassName={s['order-dropdown-toggle']}
        renderArrowDown={arrowDown}
        arrowSize={IconSize.TINY}
        placement="bottom-end"
      />
    </div>
  );
};
OrderDropdown.displayName = 'OrderDropdown';

export default OrderDropdown;
