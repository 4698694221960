import { connect, MapStateToProps } from 'react-redux';
import { getCurrentUserId } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { PublicSessionState } from '@wix/da-react-context/pkg/publicSession';

import { Props } from './MiniArtistCard';

type StateProps = Pick<Props, 'isOwnUser'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  PublicSessionState
> = (state, { user }) => ({
  isOwnUser: user ? user.userId === getCurrentUserId(state) : false,
});

export default connect(mapStateToProps);
