import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import Ellipsis from '@wix/da-ds/pkg/Icons/Ellipsis';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { BiContext } from '@wix/da-bi/pkg/BiLogger.context';
import {
  BiData,
  BiEvent,
  MoreMenuClickBiEvent,
  WatchItemRemoveClickBiEvent,
} from '@wix/da-bi/pkg/events';

export interface Props {
  user: PapiUser;
  className?: string;
  removeAll: (biData: BiEvent) => void;
}

const WatchedUserMenu: React.FC<Props> = ({ user, className, removeAll }) => {
  const biData = useContext(BiContext);
  const { t } = useTranslation();

  const dropdownItems = useMemo(() => {
    return [
      {
        label: t('subnavigation.button.userDropdown.items.removeAll'),
        biData: BiData<WatchItemRemoveClickBiEvent>({
          evid: 405,
          sectionname: 'button',
        }),
        onClick: () => removeAll({ ...biData, sectionname: 'button' } as any),
      },
    ];
  }, [t, removeAll, biData]);

  return (
    <IconButtonWithContextMenu
      items={dropdownItems}
      className={className}
      onOpenBiData={BiData<MoreMenuClickBiEvent>({
        evid: 417,
        typeid: ResourceType.USER,
        itemid: user.userId,
      })}
      withButton
      usePortal={false}
    >
      <Ellipsis size={IconSize.MEDIUM} />
    </IconButtonWithContextMenu>
  );
};
WatchedUserMenu.displayName = 'WatchedUserMenu';

export default WatchedUserMenu;
