import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Url } from '@wix/da-url';
import { formatDDDate, getDDLatestPublishedRawDate } from '../../../../utils';
import { FIRST_DAILY_DEVIATION_DATE } from '../constants';
import s from './NativeDatePicker.scss';

export interface Props {
  isFocused?: boolean;
  placeholder?: string;
  date?: string;
  className?: string;
  onFocus: () => void;
  onBlur: () => void;
}

const NativeDatePicker: React.FC<Props> = ({
  date,
  isFocused,
  placeholder,
  className,
  onFocus,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);
  return (
    <div className={className}>
      <label className={s['label']} onClick={onFocus}>
        {!date && !isFocused && placeholder}
        <input
          ref={inputRef}
          id="daily-deviations-date-input"
          type="date"
          className={classnames(
            s['calendar-native-input'],
            (isFocused || date) && s['focused']
          )}
          value={date}
          placeholder={placeholder}
          min={FIRST_DAILY_DEVIATION_DATE}
          max={formatDDDate(getDDLatestPublishedRawDate())}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={e => {
            window.location.assign(
              Url.browseDailyDeviationsLink(new Date(e.target.value))
            );
          }}
        />
      </label>
    </div>
  );
};
NativeDatePicker.displayName = 'NativeDatePicker';

export default NativeDatePicker;
