import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PapiDeviation, PapiNetworkbarStrip } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { useWindowResizeEvent } from '@wix/da-hooks/pkg/useWindowResizeEvent';
import { useThrottle } from '@wix/da-hooks/pkg/useThrottle';

import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import DeviationDuperbrowseContext from '@wix/da-shared-react/pkg/Duperbrowse/DeviationDuperbrowseContext';
import Slider from '@wix/da-ds/pkg/Slider';
import TierStandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard/Tier';
import CommissionShopCard from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import StripWrapper from '../StripWrapper';
import { getStripDeviationType, getStripItems } from '../../../helpers/streams';
import {
  RESIZE_THROTTLE_DELAY,
  RESIZE_THROTTLE_OPTIONS,
  STRIP_ITEM_LARGE_WIDTH,
  STRIP_ITEM_SMALL_WIDTH,
  STRIP_ITEM_WIDTH_BREAKPOINT,
} from './constants';
import { StripDeviationType } from '../../../types/shop';

import s from './ShopStrip.scss';

interface Props {
  strip: PapiNetworkbarStrip;
  className?: string;
}

const ShopStrip: React.FC<Props> = ({ strip, className }) => {
  const { t } = useTranslation();
  const [itemWidth, setItemWidth] = useState(STRIP_ITEM_LARGE_WIDTH);
  const items = getStripItems(strip);

  const handleResizeEvent = useThrottle(
    (screenWidth, _) => {
      setItemWidth(
        screenWidth > STRIP_ITEM_WIDTH_BREAKPOINT
          ? STRIP_ITEM_LARGE_WIDTH
          : STRIP_ITEM_SMALL_WIDTH
      );
    },
    [],
    RESIZE_THROTTLE_DELAY,
    RESIZE_THROTTLE_OPTIONS
  );
  useWindowResizeEvent(handleResizeEvent, true);

  if (!items || items.length < 1) {
    return null;
  }

  const stripDeviationType = getStripDeviationType(strip);

  return (
    <StripWrapper
      strip={strip}
      className={cn(className, s['strip'])}
      variant="shop"
    >
      <DeviationDuperbrowseContext
        streamId={strip.codename}
        deviations={strip.deviations!}
        parent={{
          title: t('duperbrowse.backButton.shop'),
        }}
      >
        <Slider scrollBy={itemWidth * 4} permArrows>
          {items.map((item, index) => {
            let thumb;
            switch (stripDeviationType) {
              case StripDeviationType.COMMISSION:
                thumb = (
                  <CommissionShopCard
                    deviation={item as PapiDeviation}
                    width={itemWidth}
                    withIndicators={false}
                  />
                );
                break;
              case StripDeviationType.PCP:
                thumb = (
                  <PCPShopCard
                    deviation={item as PapiDeviation}
                    width={itemWidth}
                    withIndicators={false}
                  />
                );
                break;
              case StripDeviationType.TIER:
                thumb = (
                  <TierStandardThumb
                    deviation={item as PapiDeviation}
                    width={itemWidth}
                    responsive
                  />
                );
                break;
              case StripDeviationType.ADOPTABLE:
                thumb = (
                  <AdoptableShopCard
                    deviation={item as PapiDeviation}
                    width={itemWidth}
                    withAuthorDetails
                    withIndicators={false}
                  />
                );
                break;
              default:
                thumb = null;
            }
            return (
              <div
                className={s['strip-item']}
                key={`${strip.codename}-${index}`}
              >
                <BiLoggerContextProvider value={{ _rankIndex: index }}>
                  {thumb}
                </BiLoggerContextProvider>
              </div>
            );
          })}
        </Slider>
      </DeviationDuperbrowseContext>
    </StripWrapper>
  );
};
ShopStrip.displayName = 'ShopStrip';

export default ShopStrip;
