import { useSelector } from 'react-redux';
import { SubNavigation, AppState } from '../types/store';
import { getSubnavigationByType } from '../selectors/page';

export default function useSubnav(
  subnavType: string
): SubNavigation | undefined {
  return useSelector((state: AppState) =>
    getSubnavigationByType(state, subnavType)
  );
}
