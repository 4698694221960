import React from 'react';
import { useTranslation } from 'react-i18next';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import { ListItemRadioButton } from '@wix/da-ds/pkg/List/ListItem';
import { SubNavigation } from '../../../../../types/store';

export interface Props {
  subnav: SubNavigation;
  /** `currentValue` of the subnav (does not always match value from server) */
  currentValue?: string;
  /** human readable explanantion of control */
  legend: string;
  /** will append the `subnav` from each option and use as an i18n key to lookup lable */
  labelLocaleKeyPrefix?: string;
  className?: string;
  buttonClassName?: string;
  onChange?: (newFilter) => void;
}

/** displays the currentValue of a subnav and lets the user choose an option */
const ShopFiltersSubnavRadioGroup: React.FC<Props> = ({
  subnav,
  currentValue,
  legend,
  labelLocaleKeyPrefix,
  className,
  buttonClassName,
  onChange = noop,
}) => {
  const { t } = useTranslation();

  const { type: subnavName, options } = subnav;
  if (!Array.isArray(options)) {
    return null;
  }

  const legendId = `shop-filters-${subnavName}`;
  return (
    <fieldset
      className={className}
      role="radiogroup"
      aria-labelledby={legendId}
    >
      <p id={legendId}>{legend}</p>

      {options.map(({ subnav: label }) => {
        return (
          <ListItemRadioButton
            key={`${label}`}
            className={buttonClassName}
            name={subnavName}
            label={
              labelLocaleKeyPrefix
                ? t(`${labelLocaleKeyPrefix}${label}`)
                : label
            }
            value={label}
            checked={label === currentValue}
            onChange={(_, value) => {
              return onChange({ [subnavName]: value });
            }}
          />
        );
      })}
    </fieldset>
  );
};

ShopFiltersSubnavRadioGroup.displayName = 'ShopFiltersSubnavRadioGroup';
export default ShopFiltersSubnavRadioGroup;
