import { connect, MapStateToProps } from 'react-redux';
import { Props } from './DailyDeviationPage';
import { AppState } from '../../../../types/store';
import { getDailyDeviationDate } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'date'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    date: getDailyDeviationDate(state),
  };
};

export default connect(mapStateToProps);
