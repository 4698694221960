import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './NetworkBarWrapper';
import { AppState } from '../../../../types/store';
import { getIsBurgerMenuOpen } from '@wix/da-shared-react/pkg/SiteHeader/redux/selectors';
import { getSelectedNetworkBarItem } from '../../../../selectors/app';
import { toggleBurgerMenuRequested } from '@wix/da-shared-react/pkg/SiteHeader/redux/actions';

export type StateProps = Pick<Props, 'isNetworkbarCollapsed' | 'selectedItem'>;
export type DispatchProps = Pick<Props, 'toggleBurgerMenu'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isNetworkbarCollapsed: !getIsBurgerMenuOpen(state),
  selectedItem: getSelectedNetworkBarItem(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  toggleBurgerMenu: toggleBurgerMenuRequested,
};

export default connect(mapStateToProps, mapDispatchToProps);
