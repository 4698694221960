/** A slider used inside a strip that allows horizontal scrolling over the content */
import React from 'react';
import classnames from 'classnames';
import Slider from '@wix/da-ds/pkg/Slider';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import {
  getStripType,
  getItemId,
  getStripItems,
  getStripDeviationType,
} from '../../helpers/streams';
import { StripDeviationType } from '../../types/shop';
import StripItem from './StripItem';
import { useElementSize } from './hooks/useElementSize';

import s from './StripSlider.scss';

export interface Props {
  /** stream data and initial array of content and url pointing to larger collection */
  strip: PapiNetworkbarStrip;
  disableShowLess?: boolean;
  /** @deprecated unused */
  className?: string;
  /** @deprecated unused */
  contentClassName?: string;
}

const StripSlider: React.FC<Props> = ({ strip, disableShowLess }) => {
  const type = getStripType(strip);
  const items = getStripItems(strip);
  const elementSize = useElementSize(strip);
  // why do we have custom spacings here? bc they designed top strips differently for our dumb renderer
  const withCustomSpacing =
    getStripDeviationType(strip) !== StripDeviationType.TIER;

  if (!items) {
    return null;
  }

  return (
    <Slider
      className={classnames(
        s['root'],
        withCustomSpacing && s['custom-spacing']
      )}
      scrollBy={elementSize.width * 4}
      permArrows
    >
      {items.map((item, index) => (
        <StripItem
          itemIndex={index}
          key={`strip-${type}-${getItemId(item)}`}
          type={type!}
          item={item}
          elementSize={elementSize}
          disableShowLess={disableShowLess}
        />
      ))}
    </Slider>
  );
};
StripSlider.displayName = 'StripSlider';

export default StripSlider;
