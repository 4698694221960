import React, { AriaAttributes } from 'react';
import PromoBadge from '@wix/da-ds/pkg/Badges/PromoBadge';
import AllDeviants from '@wix/da-ds/pkg/Icons/32x32/AllDeviants';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import DeviantsBarGradientButton from '../DeviantsBarGradientButton';

export interface Props {
  filterState?: string;
  size?: 'regular' | 'small';
  'aria-hidden'?: AriaAttributes['aria-hidden'];
}

const AllButton: React.FC<Props> = ({
  filterState,
  size,
  'aria-hidden': ariaHidden,
}) => {
  return (
    <DeviantsBarGradientButton size={size} aria-hidden={ariaHidden}>
      {({ className, iconSize }) =>
        filterState === 'subscribed' ? (
          <PromoBadge
            badgeType="tier"
            size={iconSize === IconSize.SMALL ? 24 : 32}
            className={className}
          />
        ) : (
          <AllDeviants
            size={iconSize}
            className={className}
            disableDefaultColors
          />
        )
      }
    </DeviantsBarGradientButton>
  );
};
AllButton.displayName = 'AllButton';

export default AllButton;
