import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import {
  PapiDaSharedLessLike,
  PapiDaSharedLessLikeUndo,
  PapiRequestDaSharedLessLike,
  PapiRequestDaSharedLessLikeUndo,
} from '@wix/da-papi-types';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import { actions as notificationActions } from '@wix/da-shared-react/pkg/Notifications';
import { deleteItem } from '@wix/da-shared-react/pkg/Stream/base/actions';
import {
  insertItemsAtOffsetAndSetCursor,
  setCursor,
} from '@wix/da-shared-react/pkg/Stream/withCursor/actions';
import {
  showLessLikeThis,
  undoShowLessLikeThis,
} from '../actions/recommendation';
import { NotificationType } from '../types/notification';
import { PAGE_STREAM_ID } from '../../constants';
import { getCurrentCursor } from '../selectors/stream';

function* handleShowLessLikeThis(action: ReturnType<typeof showLessLikeThis>) {
  const { deviationId, reason, typeId, ownerId } = action.payload;
  const cursor = yield select(getCurrentCursor);
  const data: PapiRequestDaSharedLessLike = {
    itemid: deviationId,
    typeid: typeId,
    reason_shown: reason,
    ownerid: ownerId?.toString(),
    cursor,
  };
  const result: PapiDaSharedLessLike | false = yield call(requestPuppy, {
    method: 'post',
    url: 'less_like',
    data,
  });

  if (!result || isApiError(result)) {
    // ...dunno
    return;
  }

  yield put(
    notificationActions.addNotification(
      NotificationType.SHOW_LESS,
      action.payload
    )
  );

  yield put(
    deleteItem({
      streamId: PAGE_STREAM_ID,
      item: action.payload.deviationId,
      doNotUpdateNextOffset: true,
    })
  );

  if (result.updatedCursor) {
    yield put(setCursor(PAGE_STREAM_ID, result.updatedCursor));
  }
}

function* handleUndoShowLessLikeThis(
  action: ReturnType<typeof undoShowLessLikeThis>
) {
  const { deviationId, offset, reason, typeId, ownerId } = action.payload;
  const cursor = yield select(getCurrentCursor);

  const data: PapiRequestDaSharedLessLikeUndo = {
    itemid: deviationId,
    typeid: typeId,
    reason_shown: reason,
    ownerid: ownerId?.toString(),
    cursor,
  };
  const result: PapiDaSharedLessLikeUndo | false = yield call(requestPuppy, {
    method: 'post',
    url: 'less_like_undo',
    data,
  });

  if (!result || isApiError(result)) {
    // i guess just yeet
    return;
  }

  yield put(
    insertItemsAtOffsetAndSetCursor({
      streamId: PAGE_STREAM_ID,
      items: [deviationId],
      offset,
      newCursor: result.updatedCursor,
    })
  );
}

export default function* recommendation() {
  yield all([
    takeEvery(showLessLikeThis, handleShowLessLikeThis),
    takeEvery(undoShowLessLikeThis, handleUndoShowLessLikeThis),
  ]);
}
