import { createAction } from 'redux-actions';
import { PapiDeviation } from '@wix/da-papi-types';
import {
  AnyPapiDaBrowseNetworkbarRequest,
  AnyPapiDaBrowseNetworkbarResponse,
} from '../../types/api';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';

export const initPostsFeed = createAction(
  'BROWSE.INIT_POSTS_FEED',
  (deviations: PapiDeviation[]) => ({ deviations })
);

export const initPageData = createAction(
  'BROWSE.INIT_PAGE_DATA_CURSOR',
  (data: {
    pageType: string;
    feedType: string;
    initData: AnyPapiDaBrowseNetworkbarResponse;
    requestParams: AnyPapiDaBrowseNetworkbarRequest;
    requestEndpoint: string;
  }) => data
);

export const fetchPageStreamNext = createCallbackActionCreator(
  'BROWSE.STREAM_NEXT_CURSOR'
);

export const markCurrentPageAsSeen = createAction(
  'BROWSE.MARK_PAGE_READ',
  () => ({})
);
