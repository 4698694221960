import { connect, MapStateToProps } from 'react-redux';
import { Props } from './PageContainer';
import { AppState } from '../../../../types/store';
import { getShouldShowFooter } from '../../../../selectors/app';
import { getBiDetails } from '../../../../selectors/biLogger';
import { getIsInfiniteScroll } from '../../../../selectors/page';
import { getHasMore } from '../../../../selectors/stream';
import { BiEvent } from '@wix/da-bi/pkg/events';

export type StateProps = Pick<
  Props,
  'withFooter' | 'biData' | 'isInfiniteScroll' | 'hasMore'
>;
export type OwnProps = Props;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { withFooter }
) => ({
  // favor prop if passed then selector
  withFooter: withFooter ?? getShouldShowFooter(state),
  biData: getBiDetails(state) as BiEvent,
  isInfiniteScroll: getIsInfiniteScroll(state),
  hasMore: getHasMore(state),
});

export default connect(mapStateToProps);
