import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DeviationFlag from '@wix/da-ds/pkg/Indicators/DeviationFlag';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import DateControls from '../../../DateControls';
import BasePageLayout from '../../_partials/BasePageLayout';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import s from './DailyDeviationPage.scss';

export interface Props {
  date?: string;
}

const DailyDeviationPage: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  return (
    <OverlayContextProvider value={{ showDailyDeviationLabel: false }}>
      <BasePageLayout
        title={
          <div className={s['title']}>
            <DeviationFlag
              variant="daily-deviation"
              size="medium"
              className={s['dd-flag']}
            />
            {t(`subnavigation.title.daily-deviations`)}
          </div>
        }
        headerNavigation={
          isMobile ? <DateControls date={date} /> : <>{/* empty */}</>
        }
        headerRightSideContent={
          isMobile ? undefined : <DateControls date={date} />
        }
      />
    </OverlayContextProvider>
  );
};
DailyDeviationPage.displayName = 'DailyDeviationPage';

export default DailyDeviationPage;
