import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import DeviationDuperbrowseContext from '@wix/da-shared-react/pkg/Duperbrowse/DeviationDuperbrowseContext';
import Slider from '@wix/da-ds/pkg/Slider';
import DeviationLink from '@wix/da-shared-react/pkg/Duperbrowse/DeviationLink';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import { DeviationStrip } from '../../../types/api';
import { getStripItems } from '../../helpers/streams';
import StripWrapper from './StripWrapper';
import { PapiDeviation } from '@wix/da-papi-types';

import s from './MobileNonSearchDeviationsStrip.scss';

const THUMB_DIMENSIONS = { width: 117, height: 78 };
const SCROLL_BY = THUMB_DIMENSIONS.width * 4;

export interface Props {
  strip: DeviationStrip;
  className?: string;
  sliderClassName?: string;
  sliderContentClassName?: string;
}

/** Strip that shows a larger number of deviations on mobile */
const MobileNonSearchDeviationsStrip: React.FC<Props> = ({
  strip,
  className,
  sliderClassName,
  sliderContentClassName,
}) => {
  // PRECONDITION: only called on mobile, from non-search pages
  const { t } = useTranslation();

  const items = getStripItems(strip);
  if (!items) {
    return null;
  }

  return (
    <StripWrapper className={className} strip={strip} isSearchPage={false}>
      <DeviationDuperbrowseContext
        streamId={`deviation-strip-${strip.codename}`}
        deviations={strip.deviations}
        parent={{ title: t('duperbrowse.backButton.browse') }}
      >
        <Slider
          className={classnames(sliderClassName, s['slider'])}
          contentClassName={classnames(
            sliderContentClassName,
            s['slider-content']
          )}
          scrollBy={SCROLL_BY}
          permArrows
        >
          {items.map((item: PapiDeviation, index) => (
            <DeviationLink key={`thumb-${index}`} deviation={item}>
              <DeviationDumbView deviation={item} {...THUMB_DIMENSIONS} />
            </DeviationLink>
          ))}
        </Slider>
      </DeviationDuperbrowseContext>
    </StripWrapper>
  );
};
MobileNonSearchDeviationsStrip.displayName = 'MobileNonSearchDeviationsStrip';

export default MobileNonSearchDeviationsStrip;
