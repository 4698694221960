import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import WatchSuggestionList from '@wix/da-shared-react/pkg/WatchSuggestions';
import type { WatchSuggestionsStrip } from '../../../../types/api';
import s from './WatchSuggestionStrip.scss';

export interface Props {
  strip: WatchSuggestionsStrip;
}

const WatchSuggestionStrip: React.FC<Props> = ({ strip }) => {
  const { t } = useTranslation();

  return (
    <Section className={s['root']}>
      <Heading className={s['title']}>
        {t('browse.heading.recommended')}
      </Heading>

      <BiLoggerContextProvider
        value={{
          sectionname: strip.title,
          sectiontype: strip.codename,
          _rankOffset: 0,
        }}
      >
        <WatchSuggestionList
          watchSuggestions={strip.watchSuggestions || []}
          numSuggestionsShown={3}
          duperbrowseContextTitle={t('duperbrowse.backButton.watch.deviations')}
          allowUnsuggest
        />
      </BiLoggerContextProvider>
    </Section>
  );
};
WatchSuggestionStrip.displayName = 'WatchSuggestionStrip';

export default WatchSuggestionStrip;
