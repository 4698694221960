import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import AllButton from '../AllButton';
import { ThemeOptions } from '@wix/da-react-context/pkg/constants';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import {
  BiData,
  WatchSubFilterSelectBiEvent,
  type BiEvent,
  WatchSubFilterOpenBiEvent,
} from '@wix/da-bi/pkg/events';
import PromoBadge from '@wix/da-ds/pkg/Badges/PromoBadge';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import AllDeviants from '@wix/da-ds/pkg/Icons/24x24/AllDeviants';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import SelectionListItemDropdown from '@wix/da-ds/pkg/Dropdown/Preset/SelectionListItemDropdown';
import StandardDropdownToggle from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown/StandardDropdownToggle';
import { DropdownDebugContext } from '@wix/da-ds/pkg/Dropdown/DropdownStateManager';
import type { SubNavigation } from '../../../types/store';
import s from './SubscribedFilter.scss';

export interface Props {
  filter: SubNavigation;
  size?: 'regular' | 'small';
  isActive?: boolean;
  hasNewContent?: boolean;
  biData?: BiEvent;
  className?: string;
  hideToggle?: boolean;
  usePortal?: boolean;
  forceOpen?: boolean;
  onRedirect?: (url: string) => void;
  menuRef?: React.RefCallback<HTMLDivElement>;
}

const iconsBySubnav = {
  everyone: <AllDeviants size={IconSize.MEDIUM} disableDefaultColors />,
  subscribed: <PromoBadge badgeType="tier" size={24} />,
};

const SubscribedFilter = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      filter: { currentValue, options: subnavOptions = [] },
      isActive,
      hasNewContent,
      className,
      size = 'regular',
      biData,
      hideToggle,
      usePortal,
      onRedirect,
      menuRef,
      forceOpen = false,
    },
    ref
  ) => {
    const currentFilter = currentValue ?? 'everyone';
    const currentSubnav = subnavOptions.find(
      option => option.subnav === currentFilter
    );
    const logBiEvent = useBiLogger();
    const { t } = useTranslation();
    const [isClicked, setIsClicked] = useState(false);

    const items = useMemo(
      () =>
        subnavOptions.map(({ subnav }) => ({
          label: t(`deviantsBar.button.all.dropdown.${subnav}.label`),
          subLabel: t(`deviantsBar.button.all.dropdown.${subnav}.sublabel`),
          value: subnav,
          prefix: iconsBySubnav[subnav],
        })),
      [subnavOptions, t]
    );

    const handleSelect = useCallback(
      value => {
        const selection = subnavOptions.find(option => option.subnav === value);
        if (selection?.url) {
          // only log if switching filters
          if (currentFilter !== value) {
            logBiEvent(
              BiData<WatchSubFilterSelectBiEvent>({
                evid: 435,
                filter_name: value === 'subscribed' ? 'subscribed' : 'everyone',
              })
            );
          }
          if (onRedirect) {
            onRedirect(selection.url);
          } else {
            window.location.assign(selection.url);
          }
        }
      },
      [subnavOptions, currentFilter, onRedirect, logBiEvent]
    );

    return (
      <div
        className={classnames(
          s['root'],
          hasNewContent && s['new'],
          isActive && s['active'],
          isClicked && s['clicked'],
          hideToggle && s['hide-toggle'],
          s[`size-${size}`],
          className
        )}
        ref={ref}
      >
        <PlainButtonOrLink
          href={currentSubnav?.url}
          biData={biData}
          onClick={() => setIsClicked(true)}
          className={s['selection-frame']}
        >
          <AllButton filterState={currentFilter} size={size} />
        </PlainButtonOrLink>
        <DropdownDebugContext.Provider value={{ forceOpen }}>
          <SelectionListItemDropdown
            items={items}
            selectedValue={currentFilter}
            onSelect={handleSelect}
            className={s['dropdown']}
            menuClassName={s['dropdown-menu']}
            menuRef={menuRef}
            usePortal={usePortal}
            forceTheme={ThemeOptions.DARK}
            isInPopup
            renderToggle={(id, selectedItem) => (
              <StandardDropdownToggle
                dropdownId={id}
                toggleClassName={s['label']}
                arrowSize={IconSize.TINY}
                arrowClassName={s['arrow']}
                selectedItem={selectedItem}
                placeholder={t(`deviantsBar.button.all.${currentFilter}`)}
                onOpenBiData={BiData<WatchSubFilterOpenBiEvent>({
                  evid: 434,
                  filter_name:
                    currentFilter === 'subscribed' ? currentFilter : 'everyone',
                })}
              />
            )}
          />
        </DropdownDebugContext.Provider>
      </div>
    );
  }
);
SubscribedFilter.displayName = 'SubscribedFilter';

export default SubscribedFilter;
