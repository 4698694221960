import { connect, MapStateToProps } from 'react-redux';
import { Props } from './BrowsePage';
import { AppState } from '../../../../types/store';
import { getOrderSubnavigation } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'hasOrderSubnav'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  hasOrderSubnav: !!getOrderSubnavigation(state),
});

export default connect(mapStateToProps);
