import React from 'react';
import EmptyResult from '../EmptyResult';

export interface Props {
  /**
   * Alternative to EmptyResult
   */
  customEmptyResult?: React.ReactNode;
  /**
   * A strip that we'll show after the empty state if we've got no content.
   */
  emptyContentStrip?: React.ReactNode;
  isEmpty?: boolean;
  children?: React.ReactNode;
}

export const ContentLoader: React.FC<Props> = ({
  isEmpty,
  customEmptyResult,
  emptyContentStrip,
  children,
}) => {
  if (isEmpty) {
    return (
      <>
        {customEmptyResult || <EmptyResult />}
        {emptyContentStrip}
      </>
    );
  }

  return <>{children}</>;
};
ContentLoader.displayName = 'ContentLoader';

export default ContentLoader;
