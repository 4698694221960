import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import DeviantsListCard from '@wix/da-shared-react/pkg/DeviantsList/DeviantsListCard';
import IconButtonWithPopover from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithPopover';
import SearchDeviants from '@wix/da-ds/pkg/Icons/24x24/SearchDeviants';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import DeviantsBarGradientButton from '../DeviantsBar/DeviantsBarGradientButton';
import { BiData, MoreMenuClickBiEvent } from '@wix/da-bi/pkg/events';

export interface Props {
  className?: string;
  selectedUserId?: number;
  feedType?: string;
  showDeviantsListModal: (
    selectedUserId?: number,
    onItemClick?: (user: PapiUser) => void
  ) => void;
}

const DeviantsSearchButton: React.FC<Props> = ({
  selectedUserId,
  feedType,
  showDeviantsListModal,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const onItemClick = useCallback(
    (user: PapiUser) => {
      window.location.assign(
        Url.browseDeviantsYouWatchLink(feedType as any, user.username)
      );
    },
    [feedType]
  );

  return isMobile ? (
    <IconButton
      size="medium"
      icon={SearchDeviants}
      onClick={() => showDeviantsListModal(selectedUserId, onItemClick)}
      biData={BiData<MoreMenuClickBiEvent>({
        evid: 417,
      })}
      aria-label={t(
        'deviantsYouWatchPage.deviantsBar.deviantsSearchButton.a11y.label'
      )}
    />
  ) : (
    <IconButtonWithPopover
      noHoverEffect
      popoverChildren={
        <BiLoggerContextProvider value={{ sectionname: 'deviantssearch' }}>
          <DeviantsListCard
            selectedUserId={selectedUserId}
            onItemClick={onItemClick}
          />
        </BiLoggerContextProvider>
      }
      aria-label={t(
        'deviantsYouWatchPage.deviantsBar.deviantsSearchButton.a11y.label'
      )}
      onOpenBiData={BiData<MoreMenuClickBiEvent>({
        evid: 417,
      })}
    >
      <DeviantsBarGradientButton>
        {({ className, iconSize }) => (
          <SearchDeviants
            className={className}
            size={iconSize}
            disableDefaultColors
          />
        )}
      </DeviantsBarGradientButton>
    </IconButtonWithPopover>
  );
};
DeviantsSearchButton.displayName = 'DeviantsSearchButton';

export default DeviantsSearchButton;
