import { call } from 'redux-saga/effects';
import { requestPuppy } from '@wix/da-http-client';

export function* apiGet(endpoint: string, params?: any) {
  const result = yield call(
    requestPuppy,
    { url: endpoint, params, method: 'get' },
    undefined,
    'dabrowse'
  );
  return result;
}

export function* apiPost(endpoint: string, params: any = {}) {
  const result = yield call(
    requestPuppy,
    { url: endpoint, data: params, method: 'post' },
    undefined,
    'dabrowse'
  );
  return result;
}
