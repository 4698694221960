import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './FeedPostboxDesktop';
import { AppState } from '../../../types/store';
import { showSubmitModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { getCanSubmitToPostFeed, getThisGroup } from '../../../selectors/page';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';

export type StateProps = Pick<
  Props,
  | 'canSubmitJournal'
  | 'canSubmitPoll'
  | 'canSubmitStatus'
  | 'thisGroup'
  | 'currentUser'
>;
export type DispatchProps = Pick<Props, 'showSubmitModal'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  currentUser: getCurrentUser(state),
  thisGroup: getThisGroup(state),
  canSubmitJournal: getCanSubmitToPostFeed(state, 'journal'),
  canSubmitPoll: getCanSubmitToPostFeed(state, 'poll'),
  canSubmitStatus: getCanSubmitToPostFeed(state, 'status'),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showSubmitModal,
};

export default connect(mapStateToProps, mapDispatchToProps);
