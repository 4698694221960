// DS: C85A, C85A_M
import React, { useContext } from 'react';
import classnames from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { TabBarProps } from './types';
import SearchBar from './_partials/SearchBar';
import TabBar from './_partials/TabBar';
import TitleBlock from './_partials/TitleBlock';

import s from './Header.scss';

const Header: React.FC<TabBarProps> = ({
  isSearchPage,
  pageType,
  items,
  title,
  tagline,
  rightSideContent,
  leftSideContent,
  activeMenuItem,
  subheader,
  children,
  className,
  innerClassName,
  rightSideClassName,
  titleClassName,
  withNavigation = true,
  isSticky,
}) => {
  const isMobile = useContext(MobileContext);

  const hasLeftSideContent = !isMobile || leftSideContent;
  const hasRightSideContent = !isMobile || rightSideContent;
  const showNavigationBar =
    !isMobile || withNavigation || hasRightSideContent || hasLeftSideContent;

  return (
    <>
      {isSearchPage && <SearchBar pageType={pageType} />}
      {isMobile && (
        <TitleBlock
          title={title}
          tagline={tagline}
          className={classnames(s['title'], titleClassName)}
          withBackground={withNavigation}
        />
      )}
      {showNavigationBar && (
        <div
          className={classnames(
            s['header-container'],
            isSticky && s['sticky'],
            className
          )}
        >
          <header
            className={classnames(
              s['header'],
              isMobile && isSearchPage && s['mobile-search'],
              innerClassName
            )}
          >
            {hasLeftSideContent &&
              (leftSideContent ? (
                <div className={s['left-side']}>{leftSideContent}</div>
              ) : (
                !isMobile && (
                  <TitleBlock
                    title={title}
                    tagline={tagline}
                    className={classnames(s['left-side'], titleClassName)}
                  />
                )
              ))}
            {withNavigation && (
              <TabBar
                items={items}
                activeMenuItem={activeMenuItem}
                className={s['navigation']}
                forceExclusivesNewLabel={pageType === 'shop'}
              >
                {children}
              </TabBar>
            )}
            {hasRightSideContent && (
              <div className={classnames(s['right-side'], rightSideClassName)}>
                {rightSideContent}
              </div>
            )}
            {subheader}
          </header>
        </div>
      )}
    </>
  );
};
Header.displayName = 'Header';

export default Header;
