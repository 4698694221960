import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadableDeviationDuperbrowseContext from '@wix/da-shared-react/pkg/Duperbrowse/LoadableDeviationDuperbrowseContext';
import { BrowseItem } from '../../../../types/api';
import { getItemType } from '../../../helpers/streams';
import { ItemTypes } from '../../../../constants';

export interface Props {
  streamId: string;
  items: BrowseItem[];
  pageType?: string;
  feedType?: string;
  isLoadMoreAllowed?: boolean;
  children: React.ReactNode;
}

const BrowseDuperContext: React.FC<Props> = ({
  items,
  streamId,
  isLoadMoreAllowed,
  pageType,
  feedType,
  children,
}) => {
  const { t } = useTranslation();
  // this comes with the assumption that either all items are deviations or none, which is the case for now at least
  const isDeviationStream = getItemType(items[0]) === ItemTypes.Deviation;

  if (!items.length || !isDeviationStream) {
    return <>{children}</>;
  }

  return (
    <LoadableDeviationDuperbrowseContext
      streamId={streamId}
      parent={{
        title: t([
          `duperbrowse.backButton.${pageType}.${feedType}`,
          `duperbrowse.backButton.${pageType}`,
          'duperbrowse.backButton.browse',
        ]).trim(),
      }}
      isLoadMoreAllowed={isLoadMoreAllowed}
    >
      {children}
    </LoadableDeviationDuperbrowseContext>
  );
};
BrowseDuperContext.displayName = 'BrowseDuperContext';

export default BrowseDuperContext;
