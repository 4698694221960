import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import StandardThumbView from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard';
import EmptyResult from '../../../EmptyResult';
import BasePageLayout from '../../_partials/BasePageLayout';
import DefaultPageContent from '../../_partials/DefaultPageContent';

import s from './MoreLikeThisPage.scss';

export interface Props {
  deviation?: PapiDeviation;
}

const MoreLikeThisPage: React.FC<Props> = ({ deviation }) => {
  const { t } = useTranslation();

  if (!deviation) {
    return <EmptyResult />;
  }

  return (
    <BasePageLayout
      wrapperClassName={s['root']}
      headerClassName={s['header']}
      innerHeaderClassName={s['inner-header']}
      headerIsStickyOnScrollUp={false}
      headerLeftSideContent={(
        <div className={s['headline']}>
          <h1 className={s['title']}>{t('mltPage.headline')}</h1>
          <OverlayContextProvider value={{ showOverlay: false }}>
            <StandardThumbView height={48} deviation={deviation} />
          </OverlayContextProvider>
        </div>
      )}
      withNetworkBar={false}
      withNavigation={false}
      contentClassName={s['content-block']}
    >
      <DefaultPageContent />
    </BasePageLayout>
  );
};
MoreLikeThisPage.displayName = 'MoreLikeThisPage';

export default MoreLikeThisPage;
