// DS: C33D
// zeplin: https://zpl.io/25JBpGn
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiGallection } from '@wix/da-papi-types';
import Flex from '@wix/da-ds/pkg/Flex';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';

import s from './CollectionCardFooter.scss';

export interface Props {
  gallection: PapiGallection;
}

const CollectionCardFooter: React.FC<Props> = ({ gallection }) => {
  const { name, size } = gallection;
  const { t } = useTranslation();
  return (
    <Flex className={s['root']} alignItems="center">
      <div className={s['name']}>{name}</div>
      <div className={s['size']}>
        {t('collectionCard.artworkStat', {
          formattedCount: shortenNumber(size, 0),
          count: size,
        })}
      </div>
    </Flex>
  );
};

CollectionCardFooter.displayName = 'CollectionCardFooter';

export default CollectionCardFooter;
