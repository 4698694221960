export const DEFAULT_STRIP_ITEM_SIZE = {
  width: 205,
  height: 177,
};

export const COMMISSION_STRIP_ITEM_SIZE = {
  width: 330,
  height: 284 + 82,
};

export const TIER_STRIP_ITEM_SIZE = {
  width: 381,
  height: 207,
};

export const TIER_STRIP_ITEM_SIZE_MOBILE = {
  width: 343,
  height: 207,
};
