import React, { useMemo } from 'react';
import { PapiDeviation, PapiNetworkbarStrip } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import type { BrowseItem } from '../../../types/api';
import { BrowseLayout } from '../../../constants';
import { StreamItems } from '../../types/stream';
import Strips from '../Strips';
import BrowseTorpedo from './BrowseTorpedo';
import BrowseGrid from './BrowseGrid';
import BrowseFeed from './BrowseFeed';
import MobileGrid from './MobileGrid';
import BrowseStacks from './BrowseStacks';
import PagingWrapper from './PagingWrapper';
import BrowseDuperContext from './BrowseDuperContext';

export interface Props {
  streamId: string;
  searchType?: string;
  layout: BrowseLayout;
  isInfiniteScroll?: boolean;
  withWatchOrSubscribeButtons?: boolean;
  strips?: PapiNetworkbarStrip[];
  contentStrip?: React.ReactNode;
  contentStripIndex?: number;
  contentStripHeight?: number;
  effectiveOrder: string;
  customEndOfResults?: React.ReactNode;
  items: StreamItems;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
  getItemUrl?: (item: BrowseItem) => string | undefined;
  renderThumbExtras?: (item: BrowseItem, index: number) => React.ReactNode;
}

const BrowseThumbLayout: React.FC<Props> = ({
  items,
  searchType,
  isInfiniteScroll,
  withWatchOrSubscribeButtons,
  streamId,
  strips,
  contentStrip = strips?.length ? <Strips /> : null,
  contentStripIndex,
  contentStripHeight,
  layout,
  effectiveOrder,
  customEndOfResults,
  onRemove,
  getItemUrl,
  renderThumbExtras,
}) => {
  const thumbLayout = useMemo(() => {
    switch (layout) {
      case BrowseLayout.Torpedo:
        return (
          <BrowseTorpedo
            items={items}
            isInfiniteScroll={isInfiniteScroll}
            contentStrip={contentStrip}
            contentStripIndex={contentStripIndex}
            onRemove={onRemove}
            renderThumbExtras={renderThumbExtras}
          />
        );
      case BrowseLayout.MobileGrid:
        return (
          <MobileGrid
            items={items}
            searchType={searchType}
            contentStrip={contentStrip}
            contentStripIndex={contentStripIndex}
            contentStripHeight={contentStripHeight}
            isInfiniteScroll={isInfiniteScroll}
            onRemove={onRemove}
          />
        );
      case BrowseLayout.Grid:
        return (
          <BrowseGrid
            items={items}
            searchType={searchType}
            contentStrip={contentStrip}
            contentStripIndex={contentStripIndex}
            contentStripHeight={contentStripHeight}
            isInfiniteScroll={isInfiniteScroll}
            onRemove={onRemove}
          />
        );
      case BrowseLayout.Feed:
        return (
          <BrowseFeed
            items={items}
            withWatchOrSubscribeButtons={withWatchOrSubscribeButtons}
          />
        );
      case BrowseLayout.Stacked:
        return (
          <BrowseStacks
            items={items}
            getItemUrl={getItemUrl}
            isInfiniteScroll={isInfiniteScroll}
            contentStrip={contentStrip}
            contentStripIndex={contentStripIndex}
            contentStripHeight={contentStripHeight}
            onRemove={onRemove}
            renderThumbExtras={renderThumbExtras}
          />
        );
      default:
        return null;
    }
  }, [
    contentStrip,
    contentStripHeight,
    contentStripIndex,
    getItemUrl,
    isInfiniteScroll,
    items,
    layout,
    onRemove,
    renderThumbExtras,
    searchType,
    withWatchOrSubscribeButtons,
  ]);

  return (
    <BiLoggerContextProvider value={{ sort_type: effectiveOrder }}>
      <OverlayContextProvider value={{ showAuthorTooltip: true }}>
        <PagingWrapper
          streamId={streamId}
          customEndOfResults={customEndOfResults}
        >
          <BrowseDuperContext
            items={items}
            streamId={streamId}
            isLoadMoreAllowed={isInfiniteScroll}
          >
            {thumbLayout}
          </BrowseDuperContext>
        </PagingWrapper>
      </OverlayContextProvider>
    </BiLoggerContextProvider>
  );
};
BrowseThumbLayout.displayName = 'BrowseThumbLayout';

export default BrowseThumbLayout;
