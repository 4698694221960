/** Outer strip layout shared between types containing title, 'view all' */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import Button from '@wix/da-shared-react/pkg/Button';
import { BiData, SeeAllClickBiEvent } from '@wix/da-bi/pkg/events';

import s from './StripWrapper.scss';

const CUSTOMIZED_TITLES = [
  'marketplace_subscriptions',
  'marketplace_commissions',
  'marketplace_downloads',
  'marketplace_adoptables',
  'marketplace_exclusives',
];

export interface Props {
  /** stream data and initial array of content and url pointing to larger collection */
  strip: PapiNetworkbarStrip;
  variant?: 'default' | 'shop';
  isSearchPage?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const StripWrapper: React.FC<Props> = ({
  strip,
  variant = 'default',
  isSearchPage,
  className,
  children,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  let titlePrefix = '';
  let stripTitle = '';
  if (CUSTOMIZED_TITLES.includes(strip.codename)) {
    stripTitle = t(`strip.title.byCodename.${strip.codename}`) || '';
  } else if (strip.titleType !== 'none') {
    titlePrefix = t([
      `strip.prefix.${strip.titleType}`,
      'strip.prefix.explore',
    ]);
    stripTitle = strip.title || '';
  }

  const titleIsLink = strip.urlType !== 'submit';

  const linkLabel =
    strip.urlType !== 'none'
      ? t([`strip.link.${strip.urlType}`, 'strip.link.view_all'])
      : '';

  return (
    <BiLoggerContextProvider
      value={{
        sectionname: strip.title,
        sectiontype: strip.codename,
        _rankOffset: 0,
      }}
      key={strip.title}
    >
      <div className={classnames(s[`variant-${variant}`], className)}>
        {(stripTitle || linkLabel) && (
          <div
            className={classnames(
              s['top-row'],
              isSearchPage && s['search-result']
            )}
          >
            {stripTitle && (
              <div className={s['title-and-results']}>
                <h2 className={s['title']}>
                  {!isSearchPage && titlePrefix && (
                    <span className={s['title-prefix']}>{titlePrefix} </span>
                  )}

                  {titleIsLink ? (
                    <BiLink
                      href={strip.url}
                      biData={BiData<SeeAllClickBiEvent>({ evid: 106 })}
                      className={s['title-link']}
                    >
                      {stripTitle}
                    </BiLink>
                  ) : (
                    <span className={s['title-link']}>{stripTitle}</span>
                  )}

                  {!!strip.estTotal && (
                    <span className={s['results-count']}>
                      {isMobile
                        ? shortenNumber(strip.estTotal)
                        : t('strip.estTotal', {
                            count: strip.estTotal,
                            formattedCount: shortenNumber(strip.estTotal),
                          })}
                    </span>
                  )}
                </h2>
              </div>
            )}

            {linkLabel && (
              <Button
                view="b33"
                href={strip.url}
                className={s['link']}
                biData={BiData<SeeAllClickBiEvent>({ evid: 106 })}
              >
                <span>{linkLabel}</span>
              </Button>
            )}
          </div>
        )}
        <div>{children}</div>
      </div>
    </BiLoggerContextProvider>
  );
};
StripWrapper.displayName = 'StripWrapper';

export default StripWrapper;
