import {
  getPagingMode,
  getIsMobile,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getMainNavItems } from '@wix/da-shared-react/pkg/NetworkBar/redux/selectors';
import { AppState } from '../types/store';
import { getIsCurrentStreamEmpty } from './stream';

export function getShouldShowFooter(state: AppState): boolean {
  if (getPagingMode(state) !== 'scroll') {
    return true;
  }

  // Show footer in case of no results
  return getIsCurrentStreamEmpty(state);
}

/** is the current page one that initially hides the networkbar
 *  and only shows it when the siteheader menu button is clicked */
const NETWORKBAR_ON_CLICK_PAGETYPES = [
  'search',
  'tag',
  'shop',
  'morelikethis'
];
export function getShowNetworkBarInSiteHeader(state: AppState) {
  return (
    NETWORKBAR_ON_CLICK_PAGETYPES.includes(
      state.currentPageData?.pageType ?? 'home'
    ) || getIsMobile(state)
  );
}

export function getSelectedNetworkBarItem(state: AppState) {
  if (state.currentPageData?.pageType === 'groups') {
    return state.currentPageData?.thisGroup;
  }

  return state.currentPageData?.pageType;
}

export function getShouldMarkSelectedNetworkBarItemAsRead(state: AppState) {
  const networkbar = getMainNavItems(state);
  const selectedItem = getSelectedNetworkBarItem(state);

  return (
    networkbar.find(item => item.key === selectedItem)
      ?.turnOffWithThisPageview ?? false
  );
}
