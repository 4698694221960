import { Reducer } from 'redux';
import modal from './modal';
import subnavs from './subnavs';
import { AppState } from '../types/store';
import { reducers as sharedReducers } from '@wix/da-shared-react/pkg/redux/shared';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, action) =>
    state;

// This type makes sure we do not forget to add a reducer for any slice
// of the store nor can we add extras for non-existing slices.
// Needed because monday (thursday) mornings exist.
type AppStateKeys = keyof AppState;
type AppStateReducersMapObject = { [k in AppStateKeys]: Reducer<any> };

export const rootReducer: AppStateReducersMapObject = {
  ...headReducers,
  ...sharedReducers,
  subnavs,
  currentPageData: buildFakeReducer({}),
  modal,
};

export default rootReducer;
