import { connect, MapStateToProps } from 'react-redux';
import { Props } from './SearchBar';
import { AppState } from '../../../../types/store';
import { getSearchQuery } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'searchTerm'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    searchTerm: getSearchQuery(state) ?? '',
  });

export default connect(mapStateToProps);
