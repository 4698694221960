import { useCallback, useMemo, useState } from 'react';
import omit from 'lodash/omit';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { BiData, MarketplaceFiltersBiEvent } from '@wix/da-bi/pkg/events';
import useSubnav from '../../../../../helpers/useSubnav';
import { SubNavigation, SubNavigationOption } from '../../../../../types/store';
import { SHOP_FILTER_TYPEOFS } from '../../../../../types/shop';

interface ShopFilterSubnavValues {
  filter_price_range?: string;
  filter_adoptable_status?: string;
}

const PRICE_RANGE_SUBNAV_TYPE = 'filter_price_range';
const EXCLUSIVE_STATUS_SUBNAV_TYPE = 'filter_adoptable_status';

// TODO: if backend normalized, they could send `default`s too,
// this could be dynamic and not hard-coded
const SHOP_FILTER_DEFAULTS = {
  [PRICE_RANGE_SUBNAV_TYPE]: 'Any price',
  [EXCLUSIVE_STATUS_SUBNAV_TYPE]: 'open',
};

interface UseShopFilterStateReturn {
  priceRangeSubnav?: SubNavigation;
  statusSubnav?: SubNavigation;

  /** keys are subnav `type`s and values are `currentValue` in state */
  filterSubnavValues: ShopFilterSubnavValues;
  /** how many filters are set to a non-default value */
  activeFilterCount: number;
  /** given a partial object `newFilters`, will merge with existing `filterSubnavValues` */
  updateFilters: (newFilters?: ShopFilterSubnavValues) => void;
  /** as updateFilters, but will convert current subnavs to new querystring and navigate to it */
  updateFiltersAndReload: (newFilters?: ShopFilterSubnavValues) => void;
  /** resets all `filterSubnavValues` to default values */
  resetFilters: () => void;
  /** as updateFiltersAndReload, but will convert current subnavs to new querystring and navigate to it */
  resetFiltersAndReload: () => void;
}

export default function useShopFilterState(): UseShopFilterStateReturn {
  const logBiEvent = useBiLogger();

  const priceRangeSubnav = useSubnav(PRICE_RANGE_SUBNAV_TYPE);
  const statusSubnav = useSubnav(EXCLUSIVE_STATUS_SUBNAV_TYPE);
  const [filterSubnavValues, setFilters] = useState<ShopFilterSubnavValues>(
    getInitialFilters([priceRangeSubnav, statusSubnav])
  );

  const updateFilters = (newFilters: ShopFilterSubnavValues): void => {
    setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
  };

  const updateFiltersAndReload = useCallback(
    (newFilters = {}): void => {
      const updatedFilters = { ...filterSubnavValues, ...newFilters };
      setFilters(updatedFilters);

      // remove any filters set to defaults so we don't send them in the querystring
      const nonDefaultFilters = removeBFromA(
        updatedFilters,
        SHOP_FILTER_DEFAULTS
      );

      // convert current subnav states into queryParams for new querystring
      const queryParams = {
        ...getQueryParamsFromSubnav(priceRangeSubnav, nonDefaultFilters),
        ...getQueryParamsFromSubnav(statusSubnav, nonDefaultFilters),
      };

      logBiEvent(
        BiData<MarketplaceFiltersBiEvent>({
          evid: 377,
          ...queryParams,
        })
      );

      if (typeof window === 'undefined') {
        return;
      }

      const newQuerystring = `${new URLSearchParams({
        ...omit(
          getCurrentSearchParamsAsObject(),
          Object.keys(SHOP_FILTER_TYPEOFS)
        ),
        ...queryParams,
      })}`;
      window.location.assign(
        newQuerystring
          ? `${window.location.pathname}?${newQuerystring}`
          : window.location.pathname
      );
    },
    [filterSubnavValues, logBiEvent, priceRangeSubnav, statusSubnav]
  );

  const activeFilterCount = useMemo(() => {
    const nonDefaultingFilters = removeBFromA(
      filterSubnavValues,
      SHOP_FILTER_DEFAULTS
    );
    return Object.keys(nonDefaultingFilters).length;
  }, [filterSubnavValues]);

  return {
    filterSubnavValues,
    activeFilterCount,
    priceRangeSubnav,
    statusSubnav,
    updateFilters,
    updateFiltersAndReload,
    resetFilters: (): void => updateFilters(SHOP_FILTER_DEFAULTS),
    resetFiltersAndReload: (): void =>
      updateFiltersAndReload(SHOP_FILTER_DEFAULTS),
  };
}

/** builds filter settings at page load by combining subnav currentValues and defaults */
function getInitialFilters(
  subnavs: (SubNavigation | undefined)[]
): ShopFilterSubnavValues {
  const initialFilters = {};
  for (const subnav of subnavs) {
    if (subnav && subnav.type) {
      const value = subnav.currentValue ?? SHOP_FILTER_DEFAULTS[subnav.type];
      initialFilters[subnav.type] = value;
    }
  }
  return initialFilters;
}

/** returns the queryParams for a given subnav based on the current filter settings */
function getQueryParamsFromSubnav(
  subnav: SubNavigation | undefined,
  filters: ShopFilterSubnavValues
): Record<string, any> {
  if (!subnav || !filters[subnav.type]) {
    return {};
  }

  const settingInFilters = filters[subnav.type];
  const optionFound: SubNavigationOption =
    subnav.options?.find(option => option.subnav === settingInFilters) ??
    ({} as SubNavigationOption);
  return optionFound?.queryParams ?? {};
}

/** if an exact key+val pair from B are found in A, remove it from what's returned */
function removeBFromA(
  objA: Record<string, any>,
  objB: Record<string, any>
): Record<string, any> {
  const filtered = {};
  for (const [key, value] of Object.entries(objA)) {
    if (objB[key] !== value) {
      filtered[key] = value;
    }
  }
  return filtered;
}

/** return the window's current querystring params as a plain object */
function getCurrentSearchParamsAsObject(): Record<string, string> {
  return typeof window !== 'undefined'
    ? Object.fromEntries(new URLSearchParams(window.location.search))
    : {};
}
