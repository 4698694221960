import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { MAX_SEARCH_QUERY_LENGTH } from '@wix/da-shared-react/pkg/utils/constants';
import { SEARCH_FIELD_ID } from '../../constants';
import { BiData, SearchClickBiEvent } from '@wix/da-bi/pkg/events';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

import s from './SearchBar.scss';

export interface Props {
  searchTerm: string;
  pageType?: string;
}

const SearchBar: React.FC<Props> = ({ searchTerm }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [inputValue, setInputValue] = useState(searchTerm ?? '');
  const [searchTermFromInput, setSearchTermFromInput] = useState<string>();
  const logBiEvent = useBiLogger();

  const handleChange = (value: string) => {
    setSearchTermFromInput(value.trim());
    setInputValue(value);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!searchTermFromInput) {
        return false;
      }
      logBiEvent(
        BiData<SearchClickBiEvent>({
          evid: 710,
          search_terms: searchTermFromInput,
          sectionname: 'top_bar',
        })
      );
      const params = new URLSearchParams(window.location.search);
      params.delete('page');
      params.set('q', searchTermFromInput);
      window.location.search = params.toString();
      return false;
    },
    [logBiEvent, searchTermFromInput]
  );

  if (!isMobile) {
    return null;
  }

  return (
    <div className={s['search-wrap']}>
      <form
        action="/search"
        onSubmit={handleSubmit}
        className={s['search-form']}
      >
        <TextInput
          id={SEARCH_FIELD_ID}
          label={t('browseHeader.search.input.label')}
          value={inputValue}
          maxLength={MAX_SEARCH_QUERY_LENGTH}
          onChange={e => handleChange(e.target.value)}
          postfix={
            <PlainButtonOrLink onClick={handleSubmit}>
              <SearchIcon disableDefaultColors />
            </PlainButtonOrLink>
          }
          noBottomSpacing
          isLabelHidden
        />
      </form>
    </div>
  );
};
SearchBar.displayName = 'SearchBar';

export default SearchBar;
