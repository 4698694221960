import { connect, MapStateToProps } from 'react-redux';
import { Props } from './BrowseThumbLayout';
import { AppState } from '../../types/store';
import { getCurrentStreamItems } from '../../selectors/stream';
import {
  getCurrentOrder,
  getLayout,
  getSearchType,
  getStrips,
  getIsInfiniteScroll,
} from '../../selectors/page';
import { PAGE_STREAM_ID } from '../../../constants';

export type StateProps = Pick<
  Props,
  | 'searchType'
  | 'streamId'
  | 'isInfiniteScroll'
  | 'strips'
  | 'layout'
  | 'effectiveOrder'
  | 'items'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  items: getCurrentStreamItems(state),
  searchType: getSearchType(state),
  streamId: PAGE_STREAM_ID,
  isInfiniteScroll: getIsInfiniteScroll(state),
  strips: getStrips(state),
  layout: getLayout(state),
  effectiveOrder: getCurrentOrder(state) ?? '',
});

export default connect(mapStateToProps);
