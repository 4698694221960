import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import {
  getCurrentFeedType,
  getOrderSubnavigation,
} from '../../../../selectors/page';

import { Props } from './HomePage';

export type StateProps = Pick<Props, 'hasOrderSubnav' | 'feedType'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  hasOrderSubnav: !!getOrderSubnavigation(state),
  feedType: getCurrentFeedType(state),
});

export default connect(mapStateToProps);
