import { connect, MapDispatchToProps } from 'react-redux';
import { Props } from './DywRemoveNotification';
import { undoRemoveDeviation } from '../../actions/dyw';

export type StateProps = Pick<Props, never>;
export type DispatchProps = Pick<Props, 'undoRemoveDeviation'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  undoRemoveDeviation,
};

export default connect(null, mapDispatchToProps);
