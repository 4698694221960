import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from '@wix/da-hooks/pkg/useThrottle';
import {
  default as OnScrollBottom,
  DISTANCE_UNIT,
} from '@wix/da-shared-react/pkg/OnScrollBottom';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import Pagination from '@wix/da-shared-react/pkg/Pagination';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import ArrowDown from '@wix/da-ds/pkg/Icons/16x16/ArrowDown';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import cn from 'classnames';

import s from './PagingWrapper.scss';

const DISTANCE_FROM_BOTTOM_TO_FETCH_MORE = 1000;
const FETCH_MORE_THROTTLE = 300;

export interface Props {
  streamId: string;
  isInfiniteScroll: boolean;
  isDuperbrowseActive: boolean;
  hasMore: boolean;
  hasLess: boolean;
  isFetching: boolean;
  nextPageUrl: string;
  prevPageUrl: string;
  pageType?: string;
  error?: string;
  customEndOfResults?: React.ReactNode;
  fetchMore: (streamId: string) => void;
  children?: React.ReactNode;
}

const PagingWrapper: React.FC<Props> = ({
  streamId,
  children,
  isFetching,
  hasMore,
  hasLess,
  isDuperbrowseActive,
  isInfiniteScroll,
  nextPageUrl,
  prevPageUrl,
  pageType,
  error,
  customEndOfResults,
  fetchMore,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { isLoggedIn } = useContext(SessionContext);
  const shouldRequireInfiniteScrollStart =
    isInfiniteScroll && !isLoggedIn && isMobile;
  const [mobileInfScrollStarted, setMobileInfScrollStarted] = useState(false);
  const handleScrollToBottom = useThrottle(
    () => {
      if (isFetching || !hasMore || isDuperbrowseActive || error) {
        return;
      }

      fetchMore(streamId);
    },
    [fetchMore, hasMore, isDuperbrowseActive, isFetching, error, streamId],
    FETCH_MORE_THROTTLE
  );

  const currentPage = hasLess ? 2 : 1;
  const totalPages = hasMore ? currentPage + 1 : currentPage;
  return (
    <>
      {children}
      {isFetching && <LoadingIndicator />}
      {!isFetching &&
        !hasMore &&
        (customEndOfResults ?? (
          <div className={s['end-of-results']}>
            {t([`facetPage.${pageType}.endResults`, 'facetPage.endResults'])}
          </div>
        ))}
      {!isInfiniteScroll && (
        <div className={s['paged']}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            showPageNumbers={false}
            getPageUrl={page =>
              page > currentPage ? nextPageUrl : prevPageUrl
            }
          />
        </div>
      )}
      {hasMore && shouldRequireInfiniteScrollStart && (
        <div
          className={cn(
            s['start-infscroll-button'],
            mobileInfScrollStarted && s['hidden']
          )}
        >
          <SolidButton
            icon={ArrowDown}
            postfixIcon={ArrowDown}
            variant={'approval-green'}
            size={'large'}
            href={nextPageUrl}
            onClick={e => {
              e.preventDefault();
              setMobileInfScrollStarted(true);
            }}
          >
            {t('facetPage.startInfiniteScroll')}
          </SolidButton>
        </div>
      )}
      {isInfiniteScroll &&
        (mobileInfScrollStarted || !shouldRequireInfiniteScrollStart) && (
          <OnScrollBottom
            checkOnMount
            checkOnUpdate={hasMore}
            onScrolledToBottom={handleScrollToBottom}
            distanceToBottom={DISTANCE_FROM_BOTTOM_TO_FETCH_MORE}
            distanceUnit={DISTANCE_UNIT.PX}
          />
        )}
    </>
  );
};
PagingWrapper.displayName = 'PagingWrapper';

export default PagingWrapper;
