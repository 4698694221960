import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import {
  getThisTag,
  getOrderSubnavigation,
  getDailyChallenges,
} from '../../../../selectors/page';

import { Props } from './TagPage';

export type StateProps = Pick<
  Props,
  'tag' | 'hasOrderSubnav' | 'dailyChallenges'
>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  tag: getThisTag(state),
  hasOrderSubnav: !!getOrderSubnavigation(state),
  dailyChallenges: getDailyChallenges(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
