import { createAction } from 'redux-actions';

export interface ShowLessLikeThisPayload {
  deviationId: number;
  offset: number;
  typeId: number;
  reason: string;
  ownerId: number | undefined;
}

export const showLessLikeThis = createAction(
  'BROWSE.SHOW_LESS_LIKE_THIS',
  (payload: ShowLessLikeThisPayload) => payload
);

export const undoShowLessLikeThis = createAction(
  'BROWSE.UNDO_SHOW_LESS_LIKE_THIS',
  (payload: ShowLessLikeThisPayload) => payload
);
