import React, { useContext, useRef } from 'react';
import classnames from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useScrollDirection } from './hooks/useScrollDirection';
import s from './ScrollDependentStickyContainer.scss';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const ScrollDependentStickyContainer: React.FC<Props> = ({
  className,
  children,
}) => {
  const isMobile = useContext(MobileContext);
  const ref = useRef<HTMLDivElement>(null);
  useScrollDirection(ref, {
    scrollingDownClassName: s['scrolling-down'],
    showThreshold: 100,
    hideThreshold: isMobile ? 100 : 300,
  });

  return (
    <div ref={ref} className={classnames(s['root'], className)}>
      {children}
    </div>
  );
};
ScrollDependentStickyContainer.displayName = 'ScrollDependentStickyContainer';

export default React.memo(ScrollDependentStickyContainer);
