import { GridBreakpoint } from '@wix/da-shared-react/pkg/Grid';

export const DEFAULT_SHOP_GRID_SPACING = 40;

export const DEFAULT_SHOP_GRID_BREAKPOINTS: GridBreakpoint[] = [
  {
    maxWidth: 767,
    elementHeight: 296,
    elementsPerRow: 1,
  },
  {
    maxWidth: 1365,
    elementWidth: 253,
    elementHeight: 296,
    elementsPerRow: 3,
  },
  {
    maxWidth: 1535,
    elementWidth: 328,
    elementHeight: 296,
    elementsPerRow: 3,
  },
  {
    maxWidth: 1919,
    elementWidth: 300,
    elementHeight: 276,
    elementsPerRow: 4,
  },
  {
    maxWidth: 9999,
    elementWidth: 330,
    elementHeight: 296,
    elementsPerRow: 4,
  },
];

export const NARROW_SHOP_GRID_BREAKPOINTS: GridBreakpoint[] = [
  {
    maxWidth: 767,
    elementHeight: 296,
    elementsPerRow: 1,
  },
  {
    maxWidth: 1365,
    elementWidth: 294,
    elementHeight: 296,
    elementsPerRow: 2,
  },
  {
    maxWidth: 1535,
    elementWidth: 328,
    elementHeight: 296,
    elementsPerRow: 3,
  },
  {
    maxWidth: 1919,
    elementWidth: 280,
    elementHeight: 296,
    elementsPerRow: 4,
  },
  {
    maxWidth: 9999,
    elementWidth: 330,
    elementHeight: 296,
    elementsPerRow: 4,
  },
];

const TIER_CARD_HEIGHT = 384;
export const TIER_GRID_BREAKPOINTS = DEFAULT_SHOP_GRID_BREAKPOINTS.map(
  breakpoint => ({
    ...breakpoint,
    elementHeight: TIER_CARD_HEIGHT,
  })
);
export const NARROW_TIER_GRID_BREAKPOINTS = NARROW_SHOP_GRID_BREAKPOINTS.map(
  breakpoint => ({
    ...breakpoint,
    elementHeight: TIER_CARD_HEIGHT,
  })
);

export function getBreakpoints(
  searchPageType?: string,
  isNarrow?: boolean
): GridBreakpoint[] {
  // note that, since these are spread back out to props and are defined statically
  // on the module level above, they don't need to be memo'ized
  if (searchPageType === 'subscriptions') {
    return isNarrow ? NARROW_TIER_GRID_BREAKPOINTS : TIER_GRID_BREAKPOINTS;
  }
  return isNarrow
    ? NARROW_SHOP_GRID_BREAKPOINTS
    : DEFAULT_SHOP_GRID_BREAKPOINTS;
}
