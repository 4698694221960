import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GenericNotification from '@wix/da-shared-react/pkg/Notifications/GenericNotification';
import { NotificationDuration } from '@wix/da-shared-react/pkg/Notifications/Notification';
import type { NotificationQueueItem } from '@wix/da-shared-react/pkg/Notifications/redux/types';
import { BiData, WatchPopupUndoRemovalBiEvent } from '@wix/da-bi/pkg/events';

export interface Props extends Omit<NotificationQueueItem, 'type'> {
  deviationId: number;
  offset: number;
  undoRemoveDeviation: (deviationId: number, offset: number) => void;
}

const DywRemoveNotification: React.FC<Props> = ({
  id,
  queueName,
  undoRemoveDeviation,
  deviationId,
  offset,
}) => {
  const { t } = useTranslation();

  const cardProps = useMemo(
    () => ({
      multiLine: true,
      limitTextWidth: false,
      duration: NotificationDuration.M,
      actions: [
        {
          label: t('watch.remove.notification.button.undo'),
          onClick: () => undoRemoveDeviation(deviationId, offset),
          closeOnClick: true,
          biData: BiData<WatchPopupUndoRemovalBiEvent>({
            evid: 423,
            undo_action: 'remove',
            is_grouped: 0,
            items_selected_count: 0,
          }),
        },
      ],
    }),
    [deviationId, offset, t, undoRemoveDeviation]
  );

  return (
    <GenericNotification
      id={id}
      queueName={queueName}
      message={t('watch.remove.notification.body')}
      cardProps={cardProps}
    />
  );
};
DywRemoveNotification.displayName = 'DywRemoveNotification';

export default DywRemoveNotification;
