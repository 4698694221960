import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

import s from './ShopEmptyResult.scss';

const SVG_URL = Url.staticCDNLink(
  'eclipse/global/svg/il55-explore-deviant.svg'
);

export interface Props {
  searchTerm?: string;
  className?: string;
  clearFilters?: () => void;
}

const ShopEmptyResult: React.FC<Props> = ({
  searchTerm,
  className,
  clearFilters,
}) => {
  const { t } = useTranslation();

  return (
    <Section className={classnames(className, s['root'])}>
      <img className={s['illustration']} alt="" src={SVG_URL} />

      <Heading className={s['text']}>
        {clearFilters && (
          <>
            {t('shopPage.shopEmptyResult.checkYourFilters')}{' '}
            <PlainButtonOrLink
              className={classnames(
                s['clear-filters-link'],
                'text-link-level-1-brand'
              )}
              onClick={clearFilters}
            >
              {t('shopPage.shopEmptyResult.clearFilters')}
            </PlainButtonOrLink>
          </>
        )}

        {!clearFilters &&
          searchTerm &&
          t('shopPage.shopEmptyResult.nothingFound', { searchTerm })}

        {!clearFilters &&
          !searchTerm &&
          t('shopPage.shopEmptyResult.noResults')}
      </Heading>
    </Section>
  );
};

ShopEmptyResult.displayName = 'ShopEmptyResult';
export default ShopEmptyResult;
