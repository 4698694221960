import React, { useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  NotificationQueueItem,
  Notification,
} from '@wix/da-shared-react/pkg/Notifications';
import { NotificationDuration } from '@wix/da-shared-react/pkg/Notifications/Notification';
import { removeNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import SystemNotification from '@wix/da-shared-react/pkg/SystemNotification';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import UnwatchIcon from '@wix/da-ds/pkg/Icons/Unwatch';
import { undoShowLessLikeThis } from '../../actions/recommendation';
import { BiData, WatchPopupUndoRemovalBiEvent } from '@wix/da-bi/pkg/events';

export interface Props extends Omit<NotificationQueueItem, 'type'> {
  deviationId: number;
  offset: number;
  typeId: number;
  reason: string;
  ownerId?: number;
}

const ShowLessNotification: React.FC<Props> = ({
  id,
  queueName,
  deviationId,
  offset,
  typeId,
  reason,
  ownerId,
}) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const undo = useCallback(() => {
    dispatch(
      undoShowLessLikeThis({ deviationId, typeId, reason, ownerId, offset })
    );
    dispatch(removeNotification(id));
  }, [dispatch, deviationId, typeId, reason, ownerId, offset, id]);

  return (
    <Notification
      id={id}
      queueName={queueName}
      cardComponent={SystemNotification}
      cardProps={{
        iconComponent: UnwatchIcon,
        multiLine: true,
        limitTextWidth: false,
        duration: NotificationDuration.M,
        actions: [
          {
            label: t('notification.showLess.undo'),
            onClick: undo,
            biData: BiData<WatchPopupUndoRemovalBiEvent>({
              evid: 423,
              undo_action: 'less_like_this',
            }),
          },
        ],
      }}
    >
      {isMobile ? (
        t('notification.showLess.mobile')
      ) : (
        /* prettier-ignore */
        <Trans i18nKey="notification.showLess.desktop">
          Thanks for the feedback.
          <br />
          You&apos;ll see less content like this in the future.
        </Trans>
      )}
    </Notification>
  );
};
ShowLessNotification.displayName = 'ShowLessNotification';

export default ShowLessNotification;
