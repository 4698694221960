import get from 'lodash/get';
import {
  PapiDeviation,
  PapiGallection,
  PapiUser,
  PapiGroup,
  PapiNetworkbarStrip,
} from '@wix/da-papi-types';
import {
  BrowseItem,
  DeviationStrip,
  CollectionStrip,
  UserStrip,
  GroupStrip,
  TopNecoStrip,
  TagStrip,
  WatchSuggestionsStrip,
} from '../../types/api';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import {
  getDeviationType,
  isAdoptable,
  isPCP,
} from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { ItemTypes } from '../../constants';
import { StripDeviationType } from '../types/shop';
import { isGroup as isGroupShared } from '@wix/da-shared-react/pkg/User/helpers';

export function isDeviation(item: BrowseItem): item is PapiDeviation {
  return !!(item as PapiDeviation).deviationId;
}

function isGallection(item: BrowseItem): item is PapiGallection {
  return !!(item as PapiGallection).folderId;
}

function isUser(item: BrowseItem): item is PapiUser {
  return !!(item as PapiUser).userId;
}

function isGroup(item: BrowseItem): item is PapiGroup {
  return isGroupShared(get(item, 'user') as any);
}

export function isDeviationStrip(
  item: PapiNetworkbarStrip
): item is DeviationStrip {
  return !!item.deviations;
}

export function isCollectionStrip(
  item: PapiNetworkbarStrip
): item is CollectionStrip {
  return !!item.collections;
}

export function isUserStrip(item: PapiNetworkbarStrip): item is UserStrip {
  return !!item.users;
}

export function isGroupStrip(item: PapiNetworkbarStrip): item is GroupStrip {
  return !!item.groups;
}

export function isTopNecoStrip(
  item: PapiNetworkbarStrip
): item is TopNecoStrip {
  return !!item.topNecos;
}

export function isTagStrip(item: PapiNetworkbarStrip): item is TagStrip {
  return !!item.tags;
}

export function isWatchSuggestionStrip(
  item: PapiNetworkbarStrip
): item is WatchSuggestionsStrip {
  return !!item.watchSuggestions;
}

export function getItemType(item: BrowseItem) {
  if (!item) {
    return;
  }
  if (isDeviation(item)) {
    return ItemTypes.Deviation;
  }
  if (isUser(item)) {
    return ItemTypes.User;
  }
  if (isGallection(item)) {
    return ItemTypes.Gallection;
  }
  if (isGroup(item)) {
    return ItemTypes.Group;
  }
}

export function getItemId(item: BrowseItem) {
  if (!item) {
    return;
  }
  if (isDeviation(item)) {
    return item.deviationId;
  }
  if (isUser(item)) {
    return item.userId;
  }
  if (isGallection(item)) {
    return item.folderId;
  }
  if (isGroup(item)) {
    return item.user.userId;
  }
}

export function getStripType(strip: PapiNetworkbarStrip) {
  if (!strip) {
    return;
  }

  if (isDeviationStrip(strip)) {
    return ItemTypes.Deviation;
  }

  if (isUserStrip(strip)) {
    return ItemTypes.User;
  }

  if (isGroupStrip(strip)) {
    return ItemTypes.Group;
  }

  if (isCollectionStrip(strip)) {
    return ItemTypes.Gallection;
  }
}

export function getStripItems(
  strip: PapiNetworkbarStrip
): BrowseItem[] | undefined {
  switch (getStripType(strip)) {
    case ItemTypes.Deviation:
      return strip.deviations;
    case ItemTypes.Gallection:
      return strip.collections;
    case ItemTypes.Group:
      return strip.groups;
    case ItemTypes.User:
      return strip.users;
    default:
  }
}

export function getStripDeviationType(
  strip: PapiNetworkbarStrip
): StripDeviationType {
  if (!isDeviationStrip(strip) || !strip.deviations.length) {
    return StripDeviationType.UNKNOWN;
  }
  const deviationType = getDeviationType(strip.deviations[0]);
  switch (deviationType) {
    case DeviationTypes.COMMISSION:
      return StripDeviationType.COMMISSION;
    case DeviationTypes.TIER:
      return StripDeviationType.TIER;
    default: {
      if (isPCP(strip.deviations[0])) {
        return StripDeviationType.PCP;
      } else if (isAdoptable(strip.deviations[0])) {
        return StripDeviationType.ADOPTABLE;
      }
      return StripDeviationType.UNKNOWN;
    }
  }
}
