import { Middleware } from 'redux';
import rootReducer from '../reducers/index';
import rootSaga from '../sagas';
import { AppState } from '../types/store';
import logger from '../helpers/logger';
import { rootModuleStoreFactory } from '@wix/da-shared-react/pkg/redux/rootModuleStore/factory';
import { IModuleStore } from 'redux-dynamic-modules-core';

export function configureStore(initialState: AppState): IModuleStore<AppState> {
  const middleware: Middleware[] = [];

  return rootModuleStoreFactory<AppState>(
    middleware,
    logger,
    initialState,
    rootReducer,
    rootSaga,
    false
  ) as IModuleStore<AppState>;
}
