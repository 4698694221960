import { PapiDeviation } from '@wix/da-papi-types';
import { createAction } from 'redux-actions';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { ModalType } from '../types/notification';
import { BiEvent } from '@wix/da-bi/pkg/events';

export const removeAllFromCurrentUser = createAction(
  'BROWSE.REMOVE_ALL_CURRENT_USER',
  () => ({})
);
export const removeAllFromStack = createAction(
  'BROWSE.REMOVE_ALL_STACK',
  (deviation: PapiDeviation, offset: number) => ({
    deviation,
    offset, // for consistency; not currently used
  })
);
export const removeDeviation = createAction(
  'BROWSE.REMOVE_DEVIATION',
  (deviation: PapiDeviation, offset: number) => ({
    deviation,
    offset,
  })
);
export const undoRemoveDeviation = createAction(
  'BROWSE.UNDO_REMOVE_DEVIATION',
  (deviationId: number, offset: number) => ({
    deviationId,
    offset,
  })
);

export const pushRemoveAllConfirmationModal = (
  biData: BiEvent,
  stack?: {
    deviation: PapiDeviation;
    offset: number;
  }
) =>
  pushModal(ModalType.REMOVE_CONFIRM_DYW, {
    wrapInModal: false,
    params: {
      removeAll: stack
        ? () => removeAllFromStack(stack.deviation, stack.offset)
        : removeAllFromCurrentUser,
      biData,
    },
  });
