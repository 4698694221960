import { connect, MapStateToProps } from 'react-redux';
import { Props } from './GroupDeviationsEmptyResult';
import { AppState } from '../../../types/store';
import { getThisGroup } from '../../../selectors/page';

export type StateProps = Pick<Props, 'thisGroup'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state
) => ({
  thisGroup: getThisGroup(state),
});

export default connect(mapStateToProps);
