import { Url } from '@wix/da-url';
import { BiData, SubmitButtonClickBiEvent } from '@wix/da-bi/pkg/events';
import { Props as BaseButtonProps } from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink/PlainButtonOrLink';

export type UseSubmitButtonPropsProps = {
  /** deviation tags that will be pre-filled in the submit form */
  tags?: string | string[];
  biSectionname?: string;
};

export type SubmitButtonProps = Pick<
  BaseButtonProps,
  'onClick' | 'href' | 'biData'
>;

/** Return the href, biData, and onClick required for a submit button (verification handled) */
export default function useSubmitButtonProps(
  { tags, biSectionname = 'general' } = {} as UseSubmitButtonPropsProps
): SubmitButtonProps {
  const url = Url.buildFromPath('/studio');
  const params = new URLSearchParams();
  params.append('new', '1');
  if (tags) {
    const tagsAsParam = Array.isArray(tags) ? tags.join(',') : tags;
    params.append('tags', tagsAsParam);
  }

  const qs = params.toString();
  const href = `${url}${qs ? `?${qs}` : ''}`;

  const biData = BiData<SubmitButtonClickBiEvent>({
    evid: 704,
    sectionname: biSectionname,
  });

  return {
    href,
    biData,
  };
}
