import { defaultMemoize } from 'reselect';
import { selectors, withCursor } from '@wix/da-shared-react/pkg/Stream';
import {
  getDeviationsByIds,
  getDeviationById,
} from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { AppState } from '../types/store';
import { PAGE_STREAM_ID } from '../../constants';
import type { BrowseStreamParams, StreamItems } from '../types/stream';
import { getPageUrlWithCursor } from '../helpers/getPageUrl';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

export function getIsCurrentStreamEmpty(state: AppState) {
  const items = selectors.getItems(state, PAGE_STREAM_ID);
  return !items || items.length === 0;
}

export function getCurrentStreamItems(state: AppState) {
  return getStreamItems(state, PAGE_STREAM_ID);
}

export const getStreamItems = defaultMemoize(
  (state: AppState, streamId: string) => {
    const items = selectors.getItems(state, streamId);
    const schema = selectors.getStreamParam(
      state,
      streamId,
      'itemType'
    ) as BrowseStreamParams['itemType'];

    if (schema === 'deviation') {
      if (process.env.NODE_ENV !== 'production') {
        // the vm likes to return dupes which spams annoying dupe key errorss
        const uniqueItems = items.filter(
          (item, index) => items.indexOf(item) === index
        );
        return getDeviationsByIds(state, uniqueItems);
      }
      return getDeviationsByIds(state, items);
    }

    // so far only deviations are normalized, but this would be the place
    // to denormalize everything else as well

    return items as StreamItems;
  }
);

export function getHasMore(state: AppState) {
  return selectors.hasMore(state, PAGE_STREAM_ID);
}

export function getTotal(state: AppState) {
  return state.currentPageData?.estTotal;
}

export function getStreamError(state: AppState) {
  return selectors.getErrorMsg(state, PAGE_STREAM_ID);
}

export function getInitialOffset(state: AppState) {
  return (
    (selectors.getStreamParam(
      state,
      PAGE_STREAM_ID,
      'initialOffset'
    ) as BrowseStreamParams['initialOffset']) || 0
  );
}

export function getNextPageUrl(state: AppState) {
  return getPageUrlWithCursor(
    withCursor.selectors.getCursor(state, PAGE_STREAM_ID),
    getEnv(state).requestUrl
  );
}

export function getPrevPageUrl(state: AppState) {
  return getPageUrlWithCursor(
    withCursor.selectors.getPrevCursor(state, PAGE_STREAM_ID),
    getEnv(state).requestUrl
  );
}

export function getCurrentCursor(state: AppState) {
  return withCursor.selectors.getCursor(state, PAGE_STREAM_ID);
}

export function getFirstDeviationInStream(state: AppState) {
  const deviationId = selectors.getItems(state, PAGE_STREAM_ID)?.[0];
  const schema = selectors.getStreamParam(
    state,
    PAGE_STREAM_ID,
    'itemType'
  ) as BrowseStreamParams['itemType'];

  if (!deviationId || schema !== 'deviation') {
    return;
  }

  return getDeviationById(state, deviationId);
}

export function getRequestParams(state: AppState) {
  const streamParams = selectors.getStreamParamsSafe(state, PAGE_STREAM_ID);
  return streamParams.requestParams || {};
}
