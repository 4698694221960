import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../types/store';
import { TabBarProps as Props } from './types';
import {
  getFeedTypeSubnavigation,
  getCurrentPageType,
} from '../../selectors/page';

export type StateProps = Pick<
  Props,
  'items' | 'activeMenuItem' | 'pageType' | 'isSearchPage'
>;
export type OwnProps = Omit<Props, keyof StateProps | 'isStuck'>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => {
    return {
      activeMenuItem:
        getFeedTypeSubnavigation(state)?.currentValue ?? undefined,
      isSearchPage: getCurrentPageType(state) === 'search',
      items: getFeedTypeSubnavigation(state)?.options,
      pageType: getCurrentPageType(state),
    };
  };

export default connect(mapStateToProps);
