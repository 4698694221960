import React, { useContext } from 'react';
import { PapiUser } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { useWatchButton } from '@wix/da-shared-react/pkg/WatchButton/useWatchButton';
import Plus from '@wix/da-ds/pkg/Icons/Plus';
import Check from '@wix/da-ds/pkg/Icons/Check';

export interface Props {
  user: PapiUser;
  className?: string;
}

const WatchButton: React.FC<Props> = ({ user, className }) => {
  const isMobile = useContext(MobileContext);
  const { isWatching, text, onClick, biData } = useWatchButton(user);

  if (isWatching) {
    return (
      <TextButton
        icon={Check}
        size="small"
        variant="main"
        onClick={onClick}
        biData={biData}
        noHorizontalPaddings={isMobile}
        className={className}
      >
        {text}
      </TextButton>
    );
  }

  return (
    <BrandedButton
      icon={Plus}
      size="small"
      variant="brand-green"
      onClick={onClick}
      biData={biData}
      className={className}
    >
      {text}
    </BrandedButton>
  );
};
WatchButton.displayName = 'WatchButton';

export default WatchButton;
