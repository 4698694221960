import {
  PapiRequestDaBrowseNetworkbarDdDeviations,
  PapiUser,
} from '@wix/da-papi-types';
import { getDataEntityById } from '@wix/da-shared-react/pkg/redux/entities/selectors';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { getMainNavItems } from '@wix/da-shared-react/pkg/NetworkBar/redux/selectors';
import {
  getIsLoggedIn,
  getIsMobile,
  getPagingMode,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  selectors as streamSelectors,
  withCursor,
} from '@wix/da-shared-react/pkg/Stream';
import { AppState, SubNavigation } from '../types/store';
import { BrowseLayout, ErrorNames, PAGE_STREAM_ID } from '../../constants';
import { getIsCurrentStreamEmpty } from './stream';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

export function getIsInfiniteScroll(state: AppState) {
  return getPagingMode(state) === 'scroll';
}

// ---- currentPage
export function getCurrentPageType(state: AppState) {
  return state.currentPageData?.pageType;
}

export function getSearchType(state: AppState) {
  return state.currentPageData?.searchType;
}

export function getCurrentFeedType(state: AppState) {
  return state.currentPageData?.feedType;
}

export function getThisUser(state: AppState) {
  const userId = state.currentPageData?.thisUser;
  if (!userId) {
    return;
  }

  return getDataEntityById<PapiUser, AppState>(state, userSchema, userId);
}

export function getActiveUserId(state: AppState) {
  return state.currentPageData?.thisUser;
}

export function getThisGroup(state: AppState) {
  const groupId = state.currentPageData?.thisGroup;
  if (!groupId) {
    return;
  }

  return getDataEntityById<PapiUser, AppState>(state, userSchema, groupId);
}

export function getThisDeviation(state: AppState) {
  return state.currentPageData?.thisDeviation;
}

export function getThisTag(state: AppState) {
  return state.currentPageData?.thisTag;
}

export function getSelectedTags(state: AppState) {
  if (state.currentPageData?.thisTag) {
    return [state.currentPageData.thisTag];
  }
}

// ---- subnavigation
export function getSubnavigationByType(
  state: AppState,
  type: string
): SubNavigation | undefined {
  return state.subnavs.find(subnav => subnav.type === type);
}

export function getFeedTypeSubnavigation(
  state: AppState
): SubNavigation | undefined {
  return getSubnavigationByType(state, 'content_type');
}

export function getOrderSubnavigation(
  state: AppState
): SubNavigation | undefined {
  return getSubnavigationByType(state, 'order');
}

export function getCurrentOrder(state: AppState) {
  return getOrderSubnavigation(state)?.currentValue ?? undefined;
}

export function getWatchFilterSubnavigation(
  state: AppState
): SubNavigation | undefined {
  return getSubnavigationByType(state, 'dyw_filter');
}

export function getStackFilterSubnavigation(state: AppState) {
  return getSubnavigationByType(state, 'stacked_filter');
}

// ---- search
export function getSearchQuery(state: AppState) {
  // this selector is generally used for filling the header searchbar so,
  // when in the shop, return nothing and assume it's shop-local search
  if (getCurrentPageType(state) === 'shop') {
    return;
  }
  return state.currentPageData?.searchQuery;
}

export function getShopSearchQuery(state: AppState) {
  // see getSearchQuery above
  // don't escape for shop pages since we assume we're on it
  return state.currentPageData?.searchQuery;
}

export function getSearchPath(state: AppState) {
  // should only do anything for search subtypes
  const searchType = getSearchType(state);
  if (!searchType || searchType === 'all') {
    return;
  }
  const currentRequestPath = new URL(getEnv(state).requestUrl).pathname;
  // this selector is generally used for header searchbar so,
  // when in the shop, redirect there instead of to shop's search
  if (currentRequestPath.startsWith('/shop')) {
    return;
  }
  return currentRequestPath;
}

// ---- strips
export function getStrips(state: AppState) {
  return state.currentPageData?.strips;
}

export function getStripByType(
  state: AppState,
  stripType: string,
  isTopStrip = false
) {
  if (isTopStrip) {
    return state.currentPageData?.topStrips?.find(
      strip => strip.codename === stripType
    );
  }

  return state.currentPageData?.strips?.find(
    strip => strip.codename === stripType
  );
}

export function getMainContentUrl(state: AppState) {
  // TODO: we do not have this returned by backend atm so we just hardcode deviations route
  return getFeedTypeSubnavigation(state)?.options?.find(
    option => option.subnav === 'search_deviations'
  )?.url;
}

// todo: remove
export function getRelatedTags(state: AppState) {
  return getStripByType(state, 'related_tags', true)?.tags;
}
export function getWatchSuggestionsStrip(state: AppState) {
  return getStripByType(state, 'rfy_watch_suggestions');
}

export function getWatchSuggestions(state: AppState) {
  return getWatchSuggestionsStrip(state)?.watchSuggestions;
}

export function getWatchAllData(state: AppState) {
  // TODO: maybe there is a better way to do this...
  return getMainNavItems(state).find(item => item.key === 'watch');
}

export function getWatchedDeviantsStrip(state: AppState) {
  return getStripByType(state, 'dyw_deviants', true);
}

export function getWatchedDeviants(state: AppState) {
  return getWatchedDeviantsStrip(state)?.users ?? [];
}

export function getDailyChallenges(state: AppState) {
  return getStripByType(state, 'daily_challenge_tags', true)?.dailyChallenges;
}

const nonTopStrips = [
  'related_tags',
  'dyw_deviants',
  'marketplace_header',
  'daily_challenge_tags',
];
export function getTopStrips(state: AppState) {
  return state.currentPageData?.topStrips?.filter(
    strip => !nonTopStrips.includes(strip.codename)
  );
}

// ---- page-specific
export function getIsWatchPage(state: AppState) {
  return getCurrentPageType(state) === 'watch';
}

export function getCanSubmitToPostFeed(
  state: AppState,
  type: 'journal' | 'status' | 'poll'
) {
  // no postbox for logged out
  if (!getIsLoggedIn(state)) {
    return false;
  }

  // no journals on mobile
  if (type === 'journal' && getIsMobile(state)) {
    return false;
  }

  const pageType = getCurrentPageType(state);

  switch (pageType) {
    case 'watch':
      return !getThisUser(state);
    case 'groups':
      // groups only support journals
      if (type !== 'journal') {
        return false;
      }

      // check privs
      return !!getThisGroup(state)?.extendedGroup?.apps?.posts?.canSubmit;
    default:
      return true;
  }
}

export function getDailyDeviationDate(state: AppState) {
  return (
    streamSelectors.getStreamParam(
      state,
      PAGE_STREAM_ID,
      'requestParams'
    ) as PapiRequestDaBrowseNetworkbarDdDeviations
  )?.date;
}

export function getLayout(state: AppState): BrowseLayout {
  const isMobile = getIsMobile(state);
  const defaultLayout = isMobile
    ? BrowseLayout.MobileGrid
    : BrowseLayout.Torpedo;

  const layout = state.currentPageData?.layout ?? defaultLayout;
  return layout;
}

export function getCanShowLessLikeThis(state: AppState) {
  return getCurrentPageType(state) === 'home' && getIsLoggedIn(state);
}

export function getShowTierPromotionTooltip(state: AppState) {
  return ['home', 'watch'].includes(getCurrentPageType(state) || '');
}

export function getCanShowFeedRemoveAll(state: AppState) {
  // only allow on artist feeds
  if (!getThisUser(state)) {
    return false;
  }

  // in pagination, we only allow remove all on the first page
  if (
    getPagingMode(state) === 'page' &&
    withCursor.selectors.getPrevCursor(state, PAGE_STREAM_ID)
  ) {
    return false;
  }

  // only allow remove all on deviation feeds
  if (getCurrentFeedType(state) !== 'deviations') {
    return false;
  }

  // do not show if there is nothing to remove
  if (getIsCurrentStreamEmpty(state)) {
    return false;
  }

  return true;
}

// ---- errors
const errorCodeMap = {
  0: ErrorNames.None,
  1: ErrorNames.Request,
  2: ErrorNames.HighOffset,
  3: ErrorNames.NoResults,
  4: ErrorNames.PastEndOfResults,
  5: ErrorNames.AllMature,
  6: ErrorNames.AllStored,
  7: ErrorNames.AllDeleted,
  8: ErrorNames.AllAntisocial,
  9: ErrorNames.HighLoad,
  10: ErrorNames.AllBlocked,
  11: ErrorNames.NoDeviantsYouWatch,
  742: ErrorNames.TagBlocked,
};

export function getErrorName(state: AppState): ErrorNames {
  const errorCode = state.currentPageData?.errorCode;

  if (errorCode === undefined) {
    return ErrorNames.None;
  }

  return errorCodeMap[errorCode] ?? ErrorNames.None;
}
