import React from 'react';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import { PAGE_STREAM_ID } from '../../../../../../constants';
import { StreamItems } from '../../../../../types/stream';
import PagingWrapper from '../../../../BrowseThumbLayout/PagingWrapper';
import BrowseDuperContext from '../../../../BrowseThumbLayout/BrowseDuperContext';
import ShopGrid from '../ShopGrid';

export interface Props {
  items: StreamItems;
  effectiveOrder: string;
  isInfiniteScroll?: boolean;
  /** the shop resource type: 'subscriptions', 'commissions', 'downloads', 'all' */
  searchPageType?: string;
  /** will the grid be displayed in a narrower width container */
  isNarrowGrid?: boolean;
}

const ShopThumbLayout: React.FC<Props> = ({
  items,
  effectiveOrder,
  isInfiniteScroll,
  searchPageType,
  isNarrowGrid,
}) => {
  return (
    // todo: possibly export these 4 from browse thumblayout instead of duplicating here
    <BiLoggerContextProvider value={{ sort_type: effectiveOrder }}>
      <OverlayContextProvider value={{ showAuthorTooltip: true }}>
        <PagingWrapper streamId={PAGE_STREAM_ID}>
          <BrowseDuperContext
            items={items}
            streamId={PAGE_STREAM_ID}
            isLoadMoreAllowed={isInfiniteScroll}
          >
            <ShopGrid
              items={items}
              searchPageType={searchPageType}
              isNarrow={isNarrowGrid}
            />
          </BrowseDuperContext>
        </PagingWrapper>
      </OverlayContextProvider>
    </BiLoggerContextProvider>
  );
};
ShopThumbLayout.displayName = 'ShopThumbLayout';
export default ShopThumbLayout;
