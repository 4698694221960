import React from 'react';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PapiDeviation } from '@wix/da-papi-types';
import BrowseThumbLayout from '../../../BrowseThumbLayout';
import ContentLoader from '../../../ContentLoader';
import Strips from '../../../Strips';
import FeedLayout from '../../../FeedLayout';
import { BrowseItem } from '../../../../../types/api';

export interface Props {
  initialOffset?: number;
  feedType?: string;
  contentStrip?: React.ReactNode;
  /**
   * This strip will show below the empty state if there are no results.
   */
  emptyContentStrip?: React.ReactNode;
  contentStripIndex?: number;
  contentStripHeight?: number;
  customEndOfResults?: React.ReactNode;
  withWatchOrSubscribeButtons?: boolean;
  getItemUrl?: (item: BrowseItem) => string | undefined;
  renderThumbExtras?: (item: BrowseItem, index: number) => React.ReactNode;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
}

const DefaultPageContent: React.FC<Props> = ({
  initialOffset,
  feedType,
  contentStrip,
  emptyContentStrip,
  contentStripIndex,
  contentStripHeight,
  customEndOfResults,
  withWatchOrSubscribeButtons,
  getItemUrl,
  renderThumbExtras,
  onRemove,
}) => {
  const isPostFeed = feedType === 'posts';

  return (
    <BiLoggerContextProvider
      value={{
        sectionname: 'general',
        _rankOffset: initialOffset,
      }}
    >
      <Strips areTopStrips />
      {isPostFeed && (
        <FeedLayout>
          <ContentLoader emptyContentStrip={emptyContentStrip}>
            <BrowseThumbLayout
              getItemUrl={getItemUrl}
              contentStrip={contentStrip}
              contentStripIndex={contentStripIndex}
              contentStripHeight={contentStripHeight}
              customEndOfResults={customEndOfResults}
              withWatchOrSubscribeButtons={withWatchOrSubscribeButtons}
            />
          </ContentLoader>
        </FeedLayout>
      )}
      {!isPostFeed && (
        <ContentLoader emptyContentStrip={emptyContentStrip}>
          <BrowseThumbLayout
            getItemUrl={getItemUrl}
            onRemove={onRemove}
            renderThumbExtras={renderThumbExtras}
            contentStrip={contentStrip}
            contentStripIndex={contentStripIndex}
            contentStripHeight={contentStripHeight}
            customEndOfResults={customEndOfResults}
            withWatchOrSubscribeButtons={withWatchOrSubscribeButtons}
          />
        </ContentLoader>
      )}
    </BiLoggerContextProvider>
  );
};
DefaultPageContent.displayName = 'DefaultPageContent';

export default DefaultPageContent;
