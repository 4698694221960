/** Strip that shows deviations or a stream of deviations */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import MeasuredDeviationsMosaic from '@wix/da-shared-react/pkg/MeasuredDeviationsMosaic';
import DeviationDuperbrowseContext from '@wix/da-shared-react/pkg/Duperbrowse/DeviationDuperbrowseContext';
import { DeviationStrip } from '../../../types/api';
import StripWrapper from './StripWrapper';
import MeasuredStripSlider from './MeasuredStripSlider';
import StripSlider from './StripSlider';

const MOSAIC_HEIGHT = 375;
const MOSAIC_MOBILE_HEIGHT = 278;

export interface Props {
  /** stream data and initial array of content and url pointing to larger collection */
  strip: DeviationStrip;
  /** should this collection use a single mosaic for it's deviationss in place of a row and slider? */
  useMosaic?: boolean;
  isSearchPage?: boolean;
  disableShowLess?: boolean;

  className?: string;
  sliderClassName?: string;
  sliderContentClassName?: string;
}

const DeviationsStrip: React.FC<Props> = props => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const {
    strip,
    useMosaic,
    isSearchPage,
    sliderClassName,
    sliderContentClassName,
    disableShowLess,
  } = props;

  return (
    <StripWrapper {...props}>
      <DeviationDuperbrowseContext
        streamId={`deviation-strip-${strip.title
          ?.toLowerCase()
          ?.replace(' ', '_')}`}
        deviations={strip.deviations!}
        parent={{
          title: isSearchPage
            ? t('duperbrowse.backButton.search')
            : t('duperbrowse.backButton.browse'),
        }}
      >
        {useMosaic ? (
          <MeasuredDeviationsMosaic
            deviations={strip.deviations!}
            height={isMobile ? MOSAIC_MOBILE_HEIGHT : MOSAIC_HEIGHT}
          />
        ) : isSearchPage ? (
          <MeasuredStripSlider
            strip={strip}
            className={sliderClassName}
            contentClassName={sliderContentClassName}
            disableShowLess={disableShowLess}
          />
        ) : (
          <StripSlider strip={strip} disableShowLess={disableShowLess} />
        )}
      </DeviationDuperbrowseContext>
    </StripWrapper>
  );
};
DeviationsStrip.displayName = 'DeviationsStrip';

export default DeviationsStrip;
