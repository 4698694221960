import i18next from 'i18next';
import { all, call, put, select, takeEvery } from '@redux-saga/core/effects';
import {
  PapiDaBrowseNetworkbarDywDeviationsArchiveDeviation,
  PapiDaBrowseNetworkbarDywDeviationsArchiveUser,
  PapiDaBrowseNetworkbarDywDeviationsUndoArchivedeviation,
  PapiDeviation,
  PapiRequestDaBrowseNetworkbarDywDeviationsArchiveDeviation,
  PapiRequestDaBrowseNetworkbarDywDeviationsArchiveUser,
  PapiRequestDaBrowseNetworkbarDywDeviationsUndoArchivedeviation,
} from '@wix/da-papi-types';
import { addNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import {
  removeAllFromCurrentUser,
  removeAllFromStack,
  removeDeviation,
  undoRemoveDeviation,
} from '../actions/dyw';
import { apiPost } from '../helpers/xhr';
import { getThisUser } from '../selectors/page';
import {
  getCurrentCursor,
  getFirstDeviationInStream,
} from '../selectors/stream';
import { deleteItem } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { PAGE_STREAM_ID } from '../../constants';
import {
  emptyStream,
  insertItemsAtOffsetAndSetCursor,
  setCursor,
} from '@wix/da-shared-react/pkg/Stream/withCursor/actions';
import { isApiError } from '@wix/da-http-client';
import { NotificationType } from '../types/notification';
import { getCompositeId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';

function* doRemoveAll(deviation: PapiDeviation) {
  const cursor: ReturnType<typeof getCurrentCursor> = yield select(
    getCurrentCursor
  );

  let result: PapiDaBrowseNetworkbarDywDeviationsArchiveUser | false = false;

  const userid = deviation.author?.userId;
  if (userid) {
    const data: PapiRequestDaBrowseNetworkbarDywDeviationsArchiveUser = {
      userid,
      effective_dyw_time: deviation.effectiveDywTime || 0,
      cursor,
    };

    result = yield call(
      apiPost,
      '/networkbar/dyw/deviations/archive_user',
      data
    );
  }

  if (
    isApiError(result) ||
    !result ||
    !result.success ||
    result.errorDescription
  ) {
    yield putErrorNotification(i18next.t('watch.removeAll.error'));
    return false;
  }

  return result;
}

function* handleRemoveStack({
  payload: { deviation },
}: ReturnType<typeof removeAllFromStack>) {
  const result: PapiDaBrowseNetworkbarDywDeviationsArchiveUser | false =
    yield call(doRemoveAll, deviation);
  if (!result) {
    return;
  }

  yield put(
    deleteItem({
      streamId: PAGE_STREAM_ID,
      // stacks can have tiers as cover, so we need to go the extra mile
      item: getCompositeId(deviation.typeId, deviation.deviationId),
      doNotUpdateNextOffset: true,
    })
  );
  if (result.updatedCursor) {
    yield put(setCursor(PAGE_STREAM_ID, result.updatedCursor));
  }
}

function* handleRemoveAllFromCurrentUser() {
  const thisUser: ReturnType<typeof getThisUser> = yield select(getThisUser);
  if (!thisUser) {
    return;
  }

  const deviation: ReturnType<typeof getFirstDeviationInStream> = yield select(
    getFirstDeviationInStream
  );
  if (!deviation) {
    return;
  }

  const result = yield call(doRemoveAll, deviation);
  if (!result || isApiError(result)) {
    return;
  }

  yield put(emptyStream(PAGE_STREAM_ID));
}

function* handleRemoveDeviation({
  payload: { deviation, offset },
}: ReturnType<typeof removeDeviation>) {
  const cursor = yield select(getCurrentCursor);
  const data: PapiRequestDaBrowseNetworkbarDywDeviationsArchiveDeviation = {
    deviationid: deviation.deviationId,
    cursor,
  };
  const result: PapiDaBrowseNetworkbarDywDeviationsArchiveDeviation | false =
    yield call(apiPost, '/networkbar/dyw/deviations/archive_deviation', data);

  if (
    isApiError(result) ||
    !result ||
    !result.success ||
    result.errorDescription
  ) {
    yield putErrorNotification(i18next.t('watch.remove.error'));
    return;
  }

  yield put(
    deleteItem({
      streamId: PAGE_STREAM_ID,
      item: deviation.deviationId,
      doNotUpdateNextOffset: true,
    })
  );

  yield put(
    addNotification(NotificationType.REMOVE_DYW, {
      deviationId: deviation.deviationId,
      offset,
    })
  );

  if (result.updatedCursor) {
    yield put(setCursor(PAGE_STREAM_ID, result.updatedCursor));
  }
}

function* handleUndoRemoveDeviation({
  payload: { deviationId, offset },
}: ReturnType<typeof undoRemoveDeviation>) {
  const cursor = yield select(getCurrentCursor);
  const data: PapiRequestDaBrowseNetworkbarDywDeviationsUndoArchivedeviation = {
    deviationid: deviationId,
    cursor,
  };
  const result:
    | PapiDaBrowseNetworkbarDywDeviationsUndoArchivedeviation
    | false = yield call(
    apiPost,
    '/networkbar/dyw/deviations/undo_archivedeviation',
    data
  );

  if (
    isApiError(result) ||
    !result ||
    !result.success ||
    result.errorDescription
  ) {
    yield putErrorNotification(i18next.t('watch.undoRemove.error'));
    return;
  }

  yield put(
    insertItemsAtOffsetAndSetCursor({
      streamId: PAGE_STREAM_ID,
      items: [deviationId],
      offset,
      newCursor: result.updatedCursor,
    })
  );
}

export default function* dywSaga() {
  yield all([
    takeEvery(removeAllFromStack, handleRemoveStack),
    takeEvery(removeAllFromCurrentUser, handleRemoveAllFromCurrentUser),
    takeEvery(removeDeviation, handleRemoveDeviation),
    takeEvery(undoRemoveDeviation, handleUndoRemoveDeviation),
  ]);
}
