import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import s from './GlobalShopStatisticsBlock.scss';

interface Props {
  className?: string;
}

const Statistic: React.FC<{
  number: React.ReactNode;
  name: React.ReactNode;
}> = ({ name, number }) => {
  return (
    <div className={s['statistic']}>
      <div className={s['statistic-number']}>{number}</div>
      <div className={s['statistic-name']}>{name}</div>
    </div>
  );
};

const GlobalShopStatisticsBlock: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  return (
    <Section className={c(s['root'], className)}>
      <Heading className={s['heading']}>
        {t('shopPage.sellYourArt.statistics.heading')}
      </Heading>
      <div className={s['statistic-collection']}>
        <Statistic
          number={t('shopPage.sellYourArt.statistics.stat1.number')}
          name={t('shopPage.sellYourArt.statistics.stat1.name')}
        />
        {!isMobile && <div role="presentation" className={s['divider']} />}
        <Statistic
          number={t('shopPage.sellYourArt.statistics.stat2.number')}
          name={t('shopPage.sellYourArt.statistics.stat2.name')}
        />
        {!isMobile && <div role="presentation" className={s['divider']} />}
        <Statistic
          number={t('shopPage.sellYourArt.statistics.stat3.number')}
          name={t('shopPage.sellYourArt.statistics.stat3.name')}
        />
      </div>
    </Section>
  );
};

GlobalShopStatisticsBlock.displayName = 'GlobalShopStatisticsBlock';

export default GlobalShopStatisticsBlock;
