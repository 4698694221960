import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './GroupMenu';
import { AppState } from '../../types/store';
import { requestLeaveGroup } from '@wix/da-shared-react/pkg/redux/groups/actions';

export type StateProps = Pick<Props, never>;
export type DispatchProps = Pick<Props, 'requestLeave'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  requestLeave: requestLeaveGroup,
};

export default connect(mapStateToProps, mapDispatchToProps);
