import React, { useMemo } from 'react';
import type { PapiNetworkbarStrip } from '@wix/da-papi-types';
import FeaturedShopsStrip from './FeaturedShopsStrip';
import PopularTagsStrip from './PopularTagsStrip';
import RecommendedSellStrip from './RecommendedSellStrip';

const codenameToComponent: {
  [key: string]: React.FC<{ strip: PapiNetworkbarStrip; className?: string }>;
} = {
  marketplace_recommended_to_sell: RecommendedSellStrip,
  marketplace_featured_shops: FeaturedShopsStrip,
  // TODO: backend might want to change that codename
  marketplace_top_topics: PopularTagsStrip,
  marketplace_top_tags: PopularTagsStrip,
};

export function useStrips(strips: PapiNetworkbarStrip[] | undefined) {
  return useMemo(
    () =>
      strips?.map((strip, index) => {
        if (Object.keys(codenameToComponent).includes(strip.codename)) {
          const StripComponent = codenameToComponent[strip.codename];
          return (
            <StripComponent key={`${strip.codename}-${index}`} strip={strip} />
          );
        }
        return null;
      }),
    [strips]
  );
}
