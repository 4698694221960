import React, { useCallback, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { StandardGrid, GridElement } from '@wix/da-shared-react/pkg/Grid';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { getStreamItemId } from '@wix/da-shared-react/pkg/Duperbrowse/redux/helpers';
import { getDeviationType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import TierMobileThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Mobile/Tier';
import TierStandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard/Tier';
import CommissionShopCard from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { BrowseItem } from '../../../../../../types/api';
import {
  getItemId,
  getItemType,
  isDeviation,
} from '../../../../../helpers/streams';
import { DEFAULT_SHOP_GRID_SPACING, getBreakpoints } from './constants';

import s from './ShopGrid.scss';

interface Props {
  items: BrowseItem[];
  /** the shop resource type: 'subscriptions', 'commissions', 'downloads', 'all' */
  searchPageType?: string;
  /** will the grid be displayed in a narrower width container */
  isNarrow?: boolean;
}

const ShopGrid: React.FC<Props> = ({ items, searchPageType, isNarrow }) => {
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    // trigger a resize event to force the measured grid to re-render
    window.dispatchEvent(new Event('resize'));
  }, [isNarrow]);

  const getDuperbrowseItemId = useCallback(
    (index: number) => {
      const item = items[index];

      if (isDeviation(item)) {
        return getStreamItemId(item);
      }

      const type = getItemType(item);
      return `item-${type}-${getItemId(item)}`;
    },
    [items]
  );

  const renderGridItem = useCallback(
    (element: GridElement) => {
      const { index, width, height, style } = element;
      const item = items[index];
      const deviation = item as PapiDeviation;
      const key = `shop-grid-${getItemId(deviation)}`;
      const deviationType = getDeviationType(deviation);
      const isSubscription = deviationType === DeviationTypes.TIER;
      const TierThumb = isMobile ? TierMobileThumb : TierStandardThumb;
      const isCommission = deviationType === DeviationTypes.COMMISSION;
      const isPCP = deviation.isPurchasable;
      const isAdoptable = deviation.isAdoptable;

      const renderShopCard = () => {
        let thumb;
        if (isPCP) {
          thumb = (
            <PCPShopCard
              key={key}
              deviation={deviation}
              width={width}
              withIndicators={false}
            />
          );
        } else if (isCommission) {
          thumb = (
            <CommissionShopCard
              key={key}
              deviation={deviation}
              width={width}
              withIndicators={false}
            />
          );
        } else if (isAdoptable) {
          thumb = (
            <AdoptableShopCard
              key={key}
              deviation={deviation}
              width={width}
              withIndicators={false}
              withOwnerDetails
            />
          );
        }
        return <div style={{ ...style, height: 'auto' }}>{thumb}</div>;
      };

      return (
        deviation && (
          <BiLoggerContextProvider value={{ _rankIndex: index }}>
            {isSubscription ? (
              <TierThumb
                key={key}
                width={width}
                height={height}
                style={style}
                deviation={deviation}
                responsive
              />
            ) : (
              renderShopCard()
            )}
          </BiLoggerContextProvider>
        )
      );
    },
    [items, isMobile]
  );

  const containerClassName = classnames(
    s['grid-container'],
    isNarrow ? s['narrow'] : s['wide']
  );
  return (
    <div className={containerClassName}>
      <StandardGrid
        elementCount={items.length}
        cookieType={MeasuredCookieType.BROWSE}
        getDuperbrowseItemId={getDuperbrowseItemId}
        breakpoints={getBreakpoints(searchPageType, isNarrow)}
        spacing={DEFAULT_SHOP_GRID_SPACING}
        preserveAspectRatio={false}
        withShadowSpace={!isMobile}
      >
        {renderGridItem}
      </StandardGrid>
    </div>
  );
};
ShopGrid.displayName = 'ShopGrid';

export default ShopGrid;
