import { handleActions } from 'redux-actions';
import { AppState } from '../types/store';
import { markCurrentPageAsSeen } from '../actions/app';

const reducer = handleActions<NonNullable<AppState['subnavs']>, any>(
  {
    [markCurrentPageAsSeen.toString()]: (
      state,
      { payload }: ReturnType<typeof markCurrentPageAsSeen>
    ) => {
      const contentSubnav = state.find(
        subnav => subnav.type === 'content_type'
      );
      const currentOption = contentSubnav?.options?.find(
        option => option.subnav === contentSubnav.currentValue
      );
      if (!contentSubnav || !currentOption?.hasNewContent) {
        return state;
      }
      const restSubnav =
        state.filter(subnav => subnav.type !== 'content_type') ?? [];

      return [
        ...restSubnav,
        {
          ...contentSubnav,
          options: contentSubnav.options?.map(option =>
            option === currentOption
              ? { ...option, hasNewContent: false }
              : option
          ),
        },
      ];
    },
  },
  []
);

export default reducer;
