import React, { useContext, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import classnames from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import NetworkBar from '@wix/da-shared-react/pkg/NetworkBar';
import s from './NetworkBarWrapper.scss';

export interface Props {
  isNetworkBarEnabled: boolean;
  isNetworkbarCollapsed: boolean;
  selectedItem?: string | number;
  className?: string;
  toggleBurgerMenu: (isOpenAfter: boolean) => void;
  children?: React.ReactNode;
}

const NetworkBarWrapper: React.FC<Props> = ({
  children,
  isNetworkBarEnabled,
  isNetworkbarCollapsed,
  className,
  selectedItem,
  toggleBurgerMenu,
}) => {
  const isMobile = useContext(MobileContext);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const measureScrollBar = throttle(() => {
      if (!rootRef.current) {
        return;
      }

      rootRef.current.setAttribute(
        'style',
        `--scrollbar-width: ${window.innerWidth - document.body.clientWidth}px`
      );
    }, 500);

    measureScrollBar();
    window.addEventListener('resize', measureScrollBar);
    return () => window.removeEventListener('resize', measureScrollBar);
  }, []);

  useEffect(() => {
    if (isMobile || !isNetworkBarEnabled || isNetworkbarCollapsed) {
      return;
    }

    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        toggleBurgerMenu(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isMobile, isNetworkBarEnabled, isNetworkbarCollapsed, toggleBurgerMenu]);

  if (isMobile || !isNetworkBarEnabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={s['root']} ref={rootRef}>
      <div
        className={classnames(
          s['networkbar-wrapper'],
          !isNetworkbarCollapsed && s['open']
        )}
      >
        <div className={s['networkbar']}>
          <NetworkBar
            selectedItem={selectedItem}
            className={s['networkbar-content']}
            isCollapsed={isNetworkbarCollapsed}
          />
        </div>
      </div>
      <main className={classnames(s['content'], className)}>
        {children}
        {!isNetworkbarCollapsed && (
          <div
            className={s['overlay']}
            onClick={() => toggleBurgerMenu(false)}
          />
        )}
      </main>
    </div>
  );
};
NetworkBarWrapper.displayName = 'NetworkBarWrapper';

export default NetworkBarWrapper;
