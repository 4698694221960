import React, { AriaAttributes } from 'react';
import classnames from 'classnames';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './DeviantsBarGradientButton.scss';

interface Props {
  size?: 'regular' | 'small';
  'aria-hidden'?: AriaAttributes['aria-hidden'];
  children: (props: {
    className?: string;
    iconSize?: IconSize;
  }) => React.ReactNode;
}

const DeviantsBarGradientButton: React.FC<Props> = ({
  size = 'regular',
  'aria-hidden': ariaHidden,
  children,
}) => {
  return (
    <div
      className={classnames(s['root'], s[`size-${size}`])}
      aria-hidden={ariaHidden}
    >
      {children({
        className: s['icon'],
        iconSize: size === 'small' ? IconSize.MEDIUM : IconSize.LARGE,
      })}
    </div>
  );
};
DeviantsBarGradientButton.displayName = 'DeviantsBarGradientButton';

export default DeviantsBarGradientButton;
