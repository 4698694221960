import { connect, MapStateToProps } from 'react-redux';
import { Props } from './EmptyResult';
import { AppState } from '../../types/store';
import {
  getCurrentFeedType,
  getCurrentPageType,
  getErrorName,
  getSearchQuery,
  getShopSearchQuery,
} from '../../selectors/page';

export type StateProps = Pick<
  Props,
  'searchTerm' | 'errorName' | 'pageType' | 'feedType'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  searchTerm: getSearchQuery(state) || getShopSearchQuery(state),
  errorName: getErrorName(state),
  pageType: getCurrentPageType(state),
  feedType: getCurrentFeedType(state),
});

export default connect(mapStateToProps);
