import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Props } from './ArtistWatchPage';
import { AppState } from '../../../../types/store';
import {
  getCanShowFeedRemoveAll,
  getCurrentFeedType,
} from '../../../../selectors/page';
import { getIsCurrentStreamEmpty } from '../../../../selectors/stream';
import {
  pushRemoveAllConfirmationModal,
  removeDeviation,
} from '../../../../actions/dyw';

export type StateProps = Pick<Props, 'feedType' | 'isEmpty' | 'showRemoveAll'>;
export type DispatchProps = Pick<Props, 'removeAll' | 'removeDeviation'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    feedType: getCurrentFeedType(state),
    isEmpty: getIsCurrentStreamEmpty(state),
    showRemoveAll: getCanShowFeedRemoveAll(state),
  });

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  removeAll: pushRemoveAllConfirmationModal,
  removeDeviation,
};

export default connect(mapStateToProps, mapDispatchToProps);
