import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@wix/da-shared-react/pkg/Button';
import { BiData, SeeAllClickBiEvent } from '@wix/da-bi/pkg/events';
import s from './MoreDeviationsBySearch.scss';

export interface Props {
  searchTerm: string;
  mainContentUrl?: string;
}

export const MoreDeviationsBySearch: React.FC<Props> = ({
  searchTerm,
  mainContentUrl,
}) => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <div className={s['top-row']}>
        <span>
          {t('strip.moreDeviationsBySearch', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            term: <span className={s['search-term']}>{searchTerm}</span>,
          })}
        </span>
      </div>
      {mainContentUrl && (
        <Button
          view="b33"
          href={mainContentUrl}
          className={s['view-all']}
          biData={BiData<SeeAllClickBiEvent>({ evid: 106 })}
        >
          {t('common.viewAll')}
        </Button>
      )}
    </div>
  );
};
MoreDeviationsBySearch.displayName = 'MoreDeviationsBySearch';

export default MoreDeviationsBySearch;
