import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import Button from '@wix/da-shared-react/pkg/Button';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import s from './GroupDeviationsEmptyResult.scss';

export interface Props {
  thisGroup?: PapiUser;
}

const GroupDeviationsEmptyResult: React.FC<Props> = ({ thisGroup }) => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <div className={s['title']}>
        {t('groupFeed.deviations.emptyState.header')}
      </div>
      <div className={s['subtitle']}>
        {t('groupFeed.deviations.emptyState.subheader')}
      </div>
      {thisGroup && (
        <UserLink user={thisGroup} className={s['link']}>
          <Button view="b3" isFakeButton>
            {t('groupFeed.deviations.emptyState.button')}
          </Button>
        </UserLink>
      )}
    </div>
  );
};
GroupDeviationsEmptyResult.displayName = 'GroupDeviationsEmptyResult';

export default GroupDeviationsEmptyResult;
