// DS7.4.1, but an MP variant
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import {
  ThemeOptions,
  ThemeOverride,
} from '@wix/da-react-context/pkg/ThemeContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  BannerLayout,
  BannerMessage,
  BannerButton,
} from '@wix/da-ds/pkg/Banner/lego';
import s from './MpBanner.scss';

interface Props {
  className?: string;
}

const MPBanner: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  return (
    <ThemeOverride theme={ThemeOptions.DARK}>
      <BannerLayout
        className={c(s['root'], className)}
        size={isMobile ? 'small' : 'big'}
        body={[
          <img
            key="icon"
            src={t('browse.mpBanner.iconUrl')}
            width="16"
            height="16"
            alt={t('browse.mpBanner.iconLabel')}
            aria-label={t('browse.mpBanner.iconLabel')}
          />,
          <BannerMessage key="message" weight={isMobile ? 'reg' : 'bld'}>
            {t('browse.mpBanner.message')}
          </BannerMessage>,
        ]}
        postfix={
          <BannerButton
            variant={isMobile ? 'underline' : 'ghost'}
            href={t('browse.mpBanner.ctaLink')}
            biData={{ evid: 2, sectionname: 'banner', click_info: 'mp' } as any}
          >
            {t('browse.mpBanner.ctaLabel')}
          </BannerButton>
        }
      />
    </ThemeOverride>
  );
};
MPBanner.displayName = 'MPBanner';

export default MPBanner;
