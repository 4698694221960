import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import { BrowseLayout, ErrorNames } from '../../../../../constants';
import {
  getCurrentFeedType,
  getErrorName,
  getLayout,
  getStrips,
  getWatchedDeviantsStrip,
} from '../../../../selectors/page';
import { getIsCurrentStreamEmpty } from '../../../../selectors/stream';
import {
  removeDeviation,
  pushRemoveAllConfirmationModal,
} from '../../../../actions/dyw';

import { Props } from './WatchPage';

export type StateProps = Pick<
  Props,
  | 'feedType'
  | 'isStacked'
  | 'watchCount'
  | 'isEmpty'
  | 'isWatchingNoDeviants'
  | 'strips'
>;
export type DispatchProps = Pick<
  Props,
  'removeDeviation' | 'removeAllFromStack'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  feedType: getCurrentFeedType(state),
  isStacked: getLayout(state) === BrowseLayout.Stacked,
  watchCount: getWatchedDeviantsStrip(state)?.estTotal ?? 0,
  isEmpty: getIsCurrentStreamEmpty(state),
  isWatchingNoDeviants: getErrorName(state) === ErrorNames.NoDeviantsYouWatch,
  strips: getStrips(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  removeDeviation,
  removeAllFromStack: pushRemoveAllConfirmationModal,
};

export default connect(mapStateToProps, mapDispatchToProps);
