import { handleActions } from 'redux-actions';
import { ModalState } from '../types/store';
import { showModalOnLoad } from '../actions/modal';

const reducer = handleActions<ModalState>(
  {
    [`${showModalOnLoad}`](state, action) {
      return action.payload;
    },
  },
  { modalToShowOnLoad: '', modalParams: {} }
);

export default reducer;
