import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { ErrorNames } from '../../../../constants';
import DefaultEmptyResult from '../DefaultEmptyResult';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import AuthorWatchButton from '@wix/da-shared-react/pkg/Button/AuthorWatchButton';
import s from './PostsEmptyResult.scss';

export interface Props {
  errorName: ErrorNames;
}

// TODO: this is probably not great to hardcode
const teamUser: PapiUser = {
  userId: 47880507,
  useridUuid: 'eae614f8-f83b-4837-935b-ab22f4298eb2',
  username: 'team',
  usericon: 'https://a.deviantart.net/avatars-big/t/e/team.jpg?4',
  type: 'admin',
  isNewDeviant: false,
};

const PostsEmptyResult: React.FC<Props> = ({ errorName }) => {
  const { t } = useTranslation();

  if (
    ![ErrorNames.NoDeviantsYouWatch, ErrorNames.NoResults].includes(errorName)
  ) {
    return <DefaultEmptyResult errorName={errorName} />;
  }

  return (
    <div className={s['root']}>
      <div className={s['title']}>
        {t([
          `postsPage.emptyState.title.${errorName}`,
          'facetPage.emptyState.title',
        ])}
      </div>
      <div className={s['suggestion']}>
        <p className={s['suggestion-line']}>
          {t(`postsPage.emptyState.suggestion.${errorName}`)}
        </p>
        <p className={s['suggestion-line']}>
          {t('postsPage.emptyState.suggestion.watchTeam')}
        </p>
      </div>
      <div className={s['buttons']}>
        <UserAvatar className={s['avatar']} user={teamUser} size={50} />
        <UserLink className={s['userlink']} user={teamUser} />
        <AuthorWatchButton className={s['watch']} author={teamUser} />
      </div>
    </div>
  );
};
PostsEmptyResult.displayName = 'PostsEmptyResult';

export default PostsEmptyResult;
