import { Url } from '@wix/da-url';
import { BiData, DreamupInfoClickBiEvent } from '@wix/da-bi/pkg/events';
import { Props as BaseButtonProps } from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink/PlainButtonOrLink';

export type DreamUpButtonProps = Pick<BaseButtonProps, 'href' | 'biData'>;

/** Return the href, biData, and onClick required for a submit button (verification handled) */
export default function useDreamUpButtonProps(): DreamUpButtonProps {
  const href = Url.dreamupLink();

  const biData = BiData<DreamupInfoClickBiEvent>({
    evid: 923,
    sectionname: 'general',
    typeid: undefined,
    itemid: undefined,
  });

  return {
    href,
    biData,
  };
}
