import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { ThemeSurface } from '@wix/da-react-context/pkg/constants';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { BiData, type TagClickBiEvent } from '@wix/da-bi/pkg/events';
import { FacetButton } from '@wix/da-ds/pkg/FacetList/Facet';
import Surface from '@wix/da-ds/pkg/Surface';

import s from './PopularTagsStrip.scss';
import commonStyles from '../../common.scss';

interface Props {
  strip: PapiNetworkbarStrip;
  className?: string;
}

const PopularTagsStrip: React.FC<Props> = ({ strip, className }) => {
  const { t } = useTranslation();
  return (
    <Surface
      type={ThemeSurface.PRIMARY}
      className={c(
        s['root'],
        commonStyles['section-horizontal-sizing'],
        className
      )}
    >
      <Section className={c(s['content'])}>
        <Heading className={s['heading']}>
          {t('shopPage.sellYourArt.strips.popularTags.heading')}
        </Heading>
        <div className={s['tags']}>
          {strip.topNecos?.map(tag => (
            <FacetButton
              key={tag.itemId}
              size="large"
              href={tag.url}
              biData={BiData<TagClickBiEvent>({
                evid: 391,
                link_url: tag.url ?? '',
                tag_text: tag.name,
                tagid: tag.itemId,
              })}
            >
              {tag.name}
            </FacetButton>
          ))}
        </div>
      </Section>
    </Surface>
  );
};

PopularTagsStrip.displayName = 'PopularTagsStrip';

export default PopularTagsStrip;
