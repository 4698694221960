import { all, fork } from 'redux-saga/effects';
import appSaga from './appSaga';
import biLogger from './biLogger';
import streamHandlerSaga from './streamHandlers';
import recommendation from './recommendation';
import dyw from './dyw';
import { sagas } from '@wix/da-shared-react/pkg/redux/shared';
import { sisuFunnelSaga } from '@wix/da-shared-react/pkg/redux/sisuFunnel/saga';

export default function* daDeviationRootSaga() {
  yield all([
    // project sagas
    fork(appSaga),
    fork(streamHandlerSaga),
    fork(biLogger),
    fork(recommendation),
    fork(dyw),

    // shared sagas
    ...sagas.map(fork),
    fork(sisuFunnelSaga),
  ]);
}
