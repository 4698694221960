import { useRef, useEffect } from 'react';

export function useScrollToActiveItem(isEnabled: boolean, paddingLeft: number) {
  const navListRef = useRef<HTMLDivElement | null>(null);
  const activeItemRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const navRef = navListRef.current;
    const activeRouteRef = activeItemRef.current;
    if (!navRef || !activeRouteRef) {
      return;
    }

    requestAnimationFrame(() => {
      navRef.scrollLeft = activeRouteRef.offsetLeft - paddingLeft;
    });
  }, [paddingLeft, isEnabled]);

  return { activeItemRef, navListRef };
}
