import { connect, MapStateToProps } from 'react-redux';
import { Props } from './ContentLoader';
import { AppState } from '../../types/store';
import { getIsCurrentStreamEmpty } from '../../selectors/stream';

export type StateProps = Pick<Props, 'isEmpty'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isEmpty: getIsCurrentStreamEmpty(state),
});

export default connect(mapStateToProps);
