import { connect, MapStateToProps } from 'react-redux';
import { Props } from './Strips';
import { AppState } from '../../types/store';
import {
  getStrips,
  getCurrentPageType,
  getTopStrips,
} from '../../selectors/page';

export type StateProps = Pick<Props, 'strips' | 'isSearchPage' | 'isShopPage'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const { areTopStrips } = ownProps;
  const pageType = getCurrentPageType(state);
  return {
    strips: areTopStrips ? getTopStrips(state) : getStrips(state),
    isSearchPage: pageType === 'search',
    isShopPage: pageType === 'shop',
  };
};

export default connect(mapStateToProps);
