import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { cardWidth } from '../_partials/TitledStrip';

export function useMeasuredMobileSwipeCookie() {
  const { ref, dimensions } = useMeasureElementCookie(
    MeasuredCookieType.SELL_YOUR_ART_MOBILE_SWIPE_CARD,
    {
      cookieDim: 'width',
      defaultSize: {
        desktop: {
          width: cardWidth,
          height: 270,
        },
        mobile: {
          width: cardWidth,
          height: 270,
        },
      },
    }
  );

  return {
    ref,
    dimensions: {
      // This `- 4` adds a tiny gap between swipe slider cards. Otherwise, the cards are contiguous and it looks a bit
      // off.
      width: dimensions.width - 4,
      height: dimensions.height,
    },
  };
}
