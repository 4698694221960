// DS: CR36
import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiGroup } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import {
  BiContextProvidedValue,
  BiData,
  ItemClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { default as HomeIcon } from '@wix/da-ds/pkg/Icons/24x24/Home';
import PlusIcon from '@wix/da-ds/pkg/Icons/Plus';
import { formatDate, DATE_FORMATS } from '@wix/da-ds/pkg/Time/functions';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import Button from '@wix/da-shared-react/pkg/Button';

import s from './MiniGroupCard.scss';

export interface Props {
  group: PapiGroup;
  style?: React.CSSProperties;
  dataHook?: string;
  className?: string;
}

export const MiniGroupCard: React.FC<React.PropsWithChildren<Props>> = ({
  group: {
    user,
    user: {
      usericon: imageUrl,
      username,
      details,
      stats = { watchers: 0, friends: 0 },
      profile,
      userId,
    },
    description,
  },
  style,
  dataHook,
  className,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useContext(MobileContext);

  const defaultGroupAvatar =
    'https://a.deviantart.net/avatars-big/default_group.gif';

  return (
    <div
      className={classnames(s['root'], className)}
      style={isMobile ? { ...style, height: undefined } : style}
      data-hook={dataHook}
    >
      <BiLink
        className={s['bi-link-overlay']}
        href={Url.userLink(username)}
        title={username}
        biData={BiData<ItemClickBiEvent>({
          evid: 100,
          itemid: userId,
          typeid: ResourceType.GROUP,
          page_typeid: BiContextProvidedValue,
          page_itemid: BiContextProvidedValue,
          sectionid: BiContextProvidedValue,
          sectionname: BiContextProvidedValue,
          section_typeid: BiContextProvidedValue,
          sectiontype: BiContextProvidedValue,
          widgetname: BiContextProvidedValue,
        })}
      ></BiLink>
      <div className={s['inner']}>
        {imageUrl && imageUrl !== defaultGroupAvatar ? (
          <img
            loading="lazy"
            className={s['group-image']}
            src={imageUrl}
            alt={username}
            aria-hidden={true}
          />
        ) : (
          <div
            className={classnames(s['group-image'], s['no-image'])}
            aria-hidden={true}
          />
        )}
        <div className={s['group-name']} title={username}>
          <span className={s['group-name-text']}>{username}</span>
          <HomeIcon
            className={s['group-name-icon']}
            customSizable
            aria-hidden={true}
          />
        </div>
        <div
          className={s['group-description']}
          title={profile && profile.tagline}
        >
          {profile && profile.tagline}
        </div>
        <div className={s['group-stats']}>
          <div className={s['watchers']}>
            {t('miniGroupCard.watchers', {
              formattedCount: shortenNumber(stats.watchers),
              count: stats!.watchers,
            })}
          </div>
          <span className={s['stat-separator']} aria-hidden={true}>
            |
          </span>
          <div className={s['created-date']}>
            {t('miniGroupCard.dateCreated', {
              date: details
                ? formatDate(
                    details.joindate,
                    DATE_FORMATS.Date.MonthYear,
                    i18n.language
                  )
                : '',
            })}
          </div>
        </div>
        <Button
          className={s['join-button']}
          view="b16-undisciplined-designers"
          href={Url.userLink(username)}
          aria-hidden={true}
        >
          <PlusIcon
            size={IconSize.NANO}
            disableDefaultColors={true}
            className={s['plus-icon']}
          />
          {t('miniGroupCard.joinGroup')}
        </Button>
      </div>
    </div>
  );
};
MiniGroupCard.displayName = 'MiniGroupCard';

export default MiniGroupCard;
