import React from 'react';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import {
  getStripType,
  getItemId,
  getStripItems,
  getStripDeviationType,
} from '../../../helpers/streams';
import ItemCard from '../../ItemCard';
import StripWrapper from '../StripWrapper';
import { BrowseItem } from '../../../../types/api';
import {
  DEFAULT_ITEM_WIDTH,
  DEFAULT_STRIP_HEIGHT,
  TIER_STRIP_HEIGHT,
} from './constants';
import { StripDeviationType } from '../../../types/shop';

interface RenderItemProps {
  item: BrowseItem;
  index: number;
  width: number;
}

interface Props {
  strip: PapiNetworkbarStrip;
  renderItem?: (props: RenderItemProps) => React.ReactNode;
  className?: string;
}

const SwipeStrip: React.FC<Props> = ({ strip, renderItem, className }) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    MeasuredCookieType.BROWSE,
    {
      cookieDim: 'width',
    }
  );

  const type = getStripType(strip);
  const items = getStripItems(strip);
  const isTierStrip = getStripDeviationType(strip) === StripDeviationType.TIER;
  const itemWidth = measuredContainer?.width ?? DEFAULT_ITEM_WIDTH;

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <>
      <div ref={ref} className={className}>
        <StripWrapper strip={strip} variant="shop">
          <SwipeSlider>
            {items.map((item, index) =>
              renderItem ? (
                renderItem({ item, index, width: itemWidth })
              ) : (
                <ItemCard
                  key={`strip-${type}-${getItemId(item)}`}
                  itemIndex={index}
                  type={type!}
                  item={item}
                  width={itemWidth}
                  height={
                    isTierStrip ? TIER_STRIP_HEIGHT : DEFAULT_STRIP_HEIGHT
                  }
                  inclusiveFooter
                  disableShowLess={undefined}
                  showTierPromotionTooltip={false}
                />
              )
            )}
          </SwipeSlider>
        </StripWrapper>
      </div>
    </>
  );
};
SwipeStrip.displayName = 'SwipeStrip';

export default SwipeStrip;
