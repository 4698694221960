import { SagaIterator } from 'redux-saga';
import {
  call,
  put,
  select,
  takeEvery,
  all,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { PapiDeviation } from '@wix/da-papi-types';
import { isApiError } from '@wix/da-http-client';
import {
  selectors,
  withCursor,
  actions,
} from '@wix/da-shared-react/pkg/Stream';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { initializeThread } from '@wix/da-shared-react/pkg/CommentThread/actionCreators';
import { getCommentableTypeForDeviationType } from '@wix/da-shared-react/pkg/DeviationPage/redux/helpers';
import { PAGE_STREAM_ID } from '../../constants';
import {
  AnyPapiDaBrowseNetworkbarRequest,
  AnyPapiDaBrowseNetworkbarResponse,
} from '../../types/api';
import { apiGet } from '../helpers/xhr';
import {
  initPageData,
  initPostsFeed,
  fetchPageStreamNext,
} from '../actions/app';
import { getCurrentFeedType } from '../selectors/page';
import { BrowseStreamParams } from '../types/stream';
import { getNormalizedStreamItems } from '../helpers/normalize';

const FEED_COMMENTS_MAX_DEPTH = 1;
const FEED_COMMENTS_ITEMS_PER_FETCH = 10;

function* initializePostThreads(deviations: PapiDeviation[]) {
  const commentThreads = deviations.map(deviation =>
    put(
      initializeThread({
        thread: {
          commentableItemId: deviation.deviationId,
          commentableTypeId: getCommentableTypeForDeviationType(deviation),
          items: [],
          hasMore: !!deviation.stats?.comments,
          total: deviation.stats?.comments ?? 0,
          canPostComment: !!deviation.isCommentable,
        },
        maxDepth: FEED_COMMENTS_MAX_DEPTH,
        itemsPerFetch: FEED_COMMENTS_ITEMS_PER_FETCH,
      })
    )
  );
  yield all(commentThreads);
}

function* initPageStream({ payload }: ReturnType<typeof initPageData>) {
  const {
    initData,
    initData: {
      hasMore,
      usedLimit = 10,
      nextCursor,
      prevCursor,
      currentOffset = 0,
    },
    requestParams: originalRequestParams,
    requestEndpoint,
    feedType,
  } = payload;

  const streamId = PAGE_STREAM_ID;
  const { items, itemType, entities } = getNormalizedStreamItems(initData);
  const { page, cursor, init, ...requestParams } = originalRequestParams as any;

  const streamParams: BrowseStreamParams = {
    itemType,
    requestParams,
    requestEndpoint,
    initialOffset: currentOffset,
  };

  yield put(
    withCursor.actions.initialize({
      streamId,
      itemsPerFetch: usedLimit,
      items,
      hasMore,
      hasLess: !!prevCursor,
      cursor: nextCursor,
      prevCursor,
      streamParams,
      entities,
      fetchNextCallback: fetchPageStreamNext,
    })
  );

  if (feedType === 'posts' && initData.hasOwnProperty('deviations')) {
    yield call(initializePostThreads, initData['deviations']);
    yield put(initPostsFeed(initData['deviations']));
  }
}

function* onFetchPageStreamNext({
  payload: { streamId },
}: ReturnType<typeof fetchPageStreamNext>) {
  yield delay(200); // debounce

  const { requestParams, requestEndpoint }: BrowseStreamParams = yield select(
    selectors.getStreamParams,
    streamId
  );
  const cursor = yield select(withCursor.selectors.getCursor, streamId);
  const feedType = yield select(getCurrentFeedType);

  if (!cursor) {
    return;
  }

  const params: AnyPapiDaBrowseNetworkbarRequest = {
    ...requestParams,
    cursor,
  };

  // routing for requests...
  let endpoint = `/networkbar/${requestEndpoint}`;
  if (
    requestEndpoint.startsWith('search/') ||
    requestEndpoint.startsWith('marketplace/')
  ) {
    endpoint = requestEndpoint;
  }

  const result: AnyPapiDaBrowseNetworkbarResponse | false = yield call(
    apiGet,
    endpoint,
    params
  );

  if (!result || isApiError(result)) {
    yield put(
      withCursor.actions.fetchFailure({
        streamId,
        fetchType: FetchType.Next,
        errorMsg: "Can't load deviations",
      })
    );
    return;
  }

  const { hasMore = false, nextCursor, serverResultsChanged } = result;
  const { items, entities } = getNormalizedStreamItems(result);

  yield put(
    withCursor.actions.fetchSuccess({
      streamId,
      items,
      entities,
      hasMore,
      cursor: nextCursor,
    })
  );

  if (serverResultsChanged) {
    yield put(
      actions.setParam({
        streamId,
        paramType: 'serverResultsChanged',
        paramValue: serverResultsChanged,
      })
    );
  }

  if (feedType === 'posts' && result.hasOwnProperty('deviations')) {
    yield call(initializePostThreads, result['deviations']);
    yield put(initPostsFeed(result['deviations']));
  }
}

export default function* browseStreamsRootSaga(): SagaIterator {
  yield all([
    takeLatest(fetchPageStreamNext, onFetchPageStreamNext),
    takeEvery(initPageData, initPageStream),
  ]);
}
