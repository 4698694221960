import React from 'react';
import { useTranslation } from 'react-i18next';
import s from './SingleDeviantEmptyResult.scss';

export interface Props {
  feedType?: string;
}

const SingleDeviantEmptyResult: React.FC<Props> = ({ feedType }) => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <div className={s['title']}>
        {t(
          `deviantsYouWatchPage.singleDeviantEmptyResult.title.${
            feedType === 'deviations' ? 'deviations' : 'posts'
          }`
        )}
      </div>
      <div className={s['subtitle']}>
        {t('deviantsYouWatchPage.singleDeviantEmptyResult.subtitle')}
      </div>
    </div>
  );
};
SingleDeviantEmptyResult.displayName = 'SingleDeviantEmptyResult';

export default SingleDeviantEmptyResult;
