import { all, takeEvery, select, call, put, delay } from 'redux-saga/effects';
import {
  PostFeedViewBiEvent,
  PureBiData,
  ScrollToLoadMoreBiEvent,
} from '@wix/da-bi/pkg/events';
import {
  logPageView,
  biModalUnload,
} from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { selectors } from '@wix/da-shared-react/pkg/Stream';
import { exitDuperbrowseDone } from '@wix/da-shared-react/pkg/Duperbrowse/redux/actionCreators';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import isSSR from '@wix/da-shared-react/pkg/utils/isSSR';
import { initPostsFeed, fetchPageStreamNext } from '../actions/app';
import { getBiDetails, getPageViewData } from '../selectors/biLogger';
import { getCurrentStreamItems } from '../selectors/stream';
import { getCurrentFeedType } from '../selectors/page';
import { PapiDeviation } from '@wix/da-papi-types';

export function* pageViewHandler() {
  const pageViewData: ReturnType<typeof getPageViewData> = yield select(
    getPageViewData
  );
  yield put(logPageView(pageViewData));
  // Must be called after the pageview handler, so that BI view and component get initialized
  yield call(postFeedInitHandler);
  return;
}

export function* handleDuperbrowseExit() {
  yield delay(500); // give it time to restore the page
  yield call(pageViewHandler);
}

export function* infiniteScrollHandler() {
  // Both of the fetch actions picked up by this saga happen not only
  // after a scroll, but also happen on page init in large windows.
  // Make sure we only fire this BI event in the first case - on scrolling
  if (typeof window !== 'undefined' && window.scrollY > 0) {
    const { typeid, itemid }: ReturnType<typeof getBiDetails> = yield select(
      getBiDetails
    );

    yield call(
      logBiEvent,
      PureBiData<ScrollToLoadMoreBiEvent>({
        evid: 98,
        typeid,
        itemid,
        offset: yield select(selectors.getTotalItemsCount),
      })
    );
  }
}

export function* postFeedInitHandler() {
  const streamItems = yield select(getCurrentStreamItems);
  yield call(handlePostFeedViewItems, streamItems);
}

export function* postFeedViewHandler(action: ReturnType<typeof initPostsFeed>) {
  yield call(handlePostFeedViewItems, action.payload.deviations);
}

export function* handlePostFeedViewItems(items: PapiDeviation[]) {
  if (isSSR()) {
    return;
  }
  const pagingMode = yield select(getPagingMode);
  const feedType = yield select(getCurrentFeedType);
  if (
    !items ||
    !items.length ||
    pagingMode !== 'scroll' ||
    feedType !== 'posts'
  ) {
    // POST_FEED_VIEW event should only fire for infinite scroll
    return;
  }
  const streamItems = yield select(getCurrentStreamItems);
  const offset = streamItems.length - items.length;
  const metadata = JSON.stringify(
    items.map((item, index) => ({
      typeid: item.typeId,
      item: item.deviationId,
      rank: offset + index,
    }))
  );
  const { itemid, typeid } = yield select(getBiDetails);

  yield call(
    logBiEvent,
    PureBiData<PostFeedViewBiEvent>({
      evid: 95,
      itemid,
      typeid,
      offset,
      metadata,
    })
  );
}

export default function* biLogger() {
  yield all([
    takeEvery([appMounted, biModalUnload], pageViewHandler),
    takeEvery(exitDuperbrowseDone, handleDuperbrowseExit),
    takeEvery(fetchPageStreamNext, infiniteScrollHandler),
    takeEvery(initPostsFeed, postFeedViewHandler),
  ]);
}
