import React, { useCallback, useContext, useMemo } from 'react';
import { PapiDeviation } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import ItemCard from '../../ItemCard';
import { StandardGrid, GridElement } from '@wix/da-shared-react/pkg/Grid';
import { BrowseItem } from '../../../../types/api';
import { PANEL_HEIGHT as COMMISSION_CARD_FOOTER_HEIGHT } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Shop';
import { EXTRAS_HEIGHT as MOBILE_EXTRAS_HEIGHT } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Mobile';
import { getStreamItemId } from '@wix/da-shared-react/pkg/Duperbrowse/redux/helpers';
import { getItemId, getItemType, isDeviation } from '../../../helpers/streams';
import { FOOTER_HEIGHT as COLLECTION_CARD_FOOTER_HEIGHT } from '../../ItemCard/CollectionCard';
import EmptyResult from '../../EmptyResult';
import {
  GRID_DEFAULT_STRIP_INDEX,
  GRID_STRIP_HEIGHT_DESKTOP,
  GRID_STRIP_HEIGHT_MOBILE,
} from '../constants';

export interface Props {
  items: BrowseItem[];
  searchType?: string;
  contentStrip?: React.ReactNode;
  contentStripIndex?: number;
  contentStripHeight?: number;
  isInfiniteScroll?: boolean;
  onRemove?: (deviation: PapiDeviation, offset: number) => void;
  onOffsetReached?: (offset: number) => void;
}

const BrowseGrid: React.FC<Props> = ({
  items,
  searchType,
  contentStrip,
  contentStripIndex,
  contentStripHeight,
  isInfiniteScroll,
  onOffsetReached,
  onRemove,
}) => {
  const isMobile = useContext(MobileContext);
  const allowMobileCard = isMobile && isDeviation(items[0]);

  const getGridItemId = useCallback(
    (index: number) => {
      const item = items[index];
      const type = getItemType(item);
      return `item-${type}-${getItemId(item)}`;
    },
    [items]
  );

  const getDuperbrowseItemId = useCallback(
    (index: number) => {
      const item = items[index];

      if (isDeviation(item)) {
        return getStreamItemId(item);
      }

      const type = getItemType(item);
      return `item-${type}-${getItemId(item)}`;
    },
    [items]
  );

  const renderGridItem = useCallback(
    (element: GridElement) => {
      const { index, width, height, style } = element;
      const item = items[index];
      const type = getItemType(item);

      return (
        item &&
        type && (
          <ItemCard
            itemIndex={index}
            key={getGridItemId(index)}
            type={type}
            item={item}
            width={width}
            height={height}
            style={style}
            allowMobileCard={allowMobileCard}
            onRemove={onRemove}
          />
        )
      );
    },
    [getGridItemId, items, onRemove, allowMobileCard]
  );

  const heightAdjustments = useMemo(() => {
    if (allowMobileCard) {
      return MOBILE_EXTRAS_HEIGHT;
    }

    switch (searchType) {
      case 'commissions':
        return COMMISSION_CARD_FOOTER_HEIGHT;
      case 'collections':
        return COLLECTION_CARD_FOOTER_HEIGHT;
      case 'artists':
        return isMobile ? -36 : 0;
      case 'groups':
      case 'statuses':
      case 'polls':
      case 'journals':
      default:
        return 0;
    }
  }, [allowMobileCard, searchType, isMobile]);

  if (!items.length) {
    return <EmptyResult />;
  }

  return (
    <StandardGrid
      contentStrip={contentStrip}
      contentStripIndex={contentStripIndex ?? GRID_DEFAULT_STRIP_INDEX}
      contentStripHeight={
        contentStripHeight ??
        (isMobile ? GRID_STRIP_HEIGHT_MOBILE : GRID_STRIP_HEIGHT_DESKTOP)
      }
      elementCount={items.length}
      cookieType={MeasuredCookieType.BROWSE}
      getElementId={getGridItemId}
      getDuperbrowseItemId={getDuperbrowseItemId}
      adjustHeight={heightAdjustments}
      withShadowSpace={!isMobile}
      enableScrollOptim={isInfiniteScroll}
      onOffsetReached={isInfiniteScroll ? onOffsetReached : undefined}
    >
      {renderGridItem}
    </StandardGrid>
  );
};

BrowseGrid.displayName = 'BrowseGrid';

export default BrowseGrid;
