import React from 'react';
import classnames from 'classnames';
import s from './TitleBlock.scss';

export interface Props {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  withBackground?: boolean;
  className?: string;
}

const TitleBlock: React.FC<Props> = ({
  title,
  tagline,
  withBackground,
  className,
}) => {
  if (!title) {
    return null;
  }

  return (
    <div
      className={classnames(
        s['title'],
        withBackground && s['with-background'],
        className
      )}
    >
      <h1 className={s['headline']}>{title}</h1>
      {tagline && <div className={s['tagline']}>{tagline}</div>}
    </div>
  );
};
TitleBlock.displayName = 'TitleBlock';

export default TitleBlock;
