import React from 'react';
import FeedThumb from '@wix/da-shared-react/pkg/DeviationViews/Feed';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { BrowseItem } from '../../../../types/api';
import { isDeviation } from '../../../helpers/streams';
import s from './BrowseFeed.scss';

export interface Props {
  items: BrowseItem[];
  className?: string;
  withWatchOrSubscribeButtons?: boolean;
}

const BrowseFeed: React.FC<Props> = ({
  items,
  withWatchOrSubscribeButtons,
  className,
}) => {
  return (
    <div className={className}>
      {items.map((item, index) => {
        if (isDeviation(item) && !item.isDeleted) {
          return (
            <React.Fragment key={getDeviationEntityId(item)}>
              <BiLoggerContextProvider value={{ _rankIndex: index }}>
                <FeedThumb
                  deviation={item}
                  className={s['feed-item']}
                  withComments
                  withWatchOrSubscribeButton={withWatchOrSubscribeButtons}
                  showTierPromotionTooltip
                />
              </BiLoggerContextProvider>
            </React.Fragment>
          );
        }
        return null;
      })}
    </div>
  );
};
BrowseFeed.displayName = 'BrowseFeed';

export default BrowseFeed;
