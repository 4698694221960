export const XHR_BASE_PATH = '/_napi/da-browse/';
export const API_BASE_PATH = `${XHR_BASE_PATH}api/networkbar`;
export const SHARED_API_BASE_PATH = `${XHR_BASE_PATH}shared_api`;

export const PAGE_STREAM_ID = '@@BROWSE_PAGE_STREAM';

export enum ErrorNames {
  None = 'none',
  Request = 'request',
  HighOffset = 'highOffset',
  NoResults = 'noResults',
  PastEndOfResults = 'pastEndOfResults',
  AllMature = 'allMature',
  AllStored = 'allStored',
  AllDeleted = 'allDeleted',
  AllAntisocial = 'allAntisocial',
  HighLoad = 'highLoad',
  AllBlocked = 'allBlocked',
  NoDeviantsYouWatch = 'noDeviantsYouWatch',
  TagBlocked = 'tagBlocked',
}

export enum BrowseLayout {
  Torpedo = 1,
  Grid = 2,
  Feed = 3,
  Stacked = 4,
  MobileGrid = 6,
  ShopGrid = 7,
  ShopMobileGrid = 8,
}

export enum ItemTypes {
  Deviation = 'deviation',
  User = 'user',
  Group = 'group',
  Gallection = 'gallection',
}
