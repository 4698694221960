import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import type { Props } from './DatePickerPopup';

const DateControls = lazy(
  () =>
    import(
      /* webpackChunkName: "datepicker" */
      './DatePickerPopup'
    )
);

const DateControlsLoadable: React.FC<Props> = props => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const Loader = (
    <span className={props.className}>
      {props.date ? props.date : t('subnavigation.button.datepicker')}
    </span>
  );

  if (!isMounted) {
    return Loader;
  }

  return (
    <Suspense fallback={Loader}>
      <DateControls {...props} />
    </Suspense>
  );
};
DateControlsLoadable.displayName = 'DateControlsLoadable';

export default DateControlsLoadable;
