import React, { useContext } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import type { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { ThemeSurface } from '@wix/da-react-context/pkg/constants';
import SellPromotionCard from '@wix/da-shared-react/pkg/ShopCard/SellPromotionCard';
import Surface from '@wix/da-ds/pkg/Surface';
import { useMeasuredMobileSwipeCookie } from '../useMeasuredMobileSwipeCookie';
import TitledStrip, { cardWidth } from '../../_partials/TitledStrip';
import s from './RecommendedSellStrip.scss';

interface Props {
  strip: PapiNetworkbarStrip;
  className?: string;
}

const RecommendedSellStrip: React.FC<Props> = ({ strip, className }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { ref, dimensions } = useMeasuredMobileSwipeCookie();

  return (
    <Surface type={ThemeSurface.SECONDARY} className={c(s['root'], className)}>
      <TitledStrip
        title={t('shopPage.sellYourArt.strips.recommendedSellStrip.heading')}
        description={t(
          'shopPage.sellYourArt.strips.recommendedSellStrip.description'
        )}
        className={s['content']}
      >
        {strip.deviations &&
          strip.deviations.map(deviation => (
            <div
              key={deviation.deviationId}
              className={s['center-for-mobile-slider']}
              ref={ref}
            >
              <SellPromotionCard
                deviation={deviation}
                width={isMobile ? dimensions.width ?? cardWidth : cardWidth}
              />
            </div>
          ))}
      </TitledStrip>
    </Surface>
  );
};

RecommendedSellStrip.displayName = 'RecommendedSellStrip';

export default RecommendedSellStrip;
