// DS: CR35
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { getInfoToDisplayImageDeviation } from '@wix/da-shared-react/pkg/DeviationViews/Dumbo/Image/_helpers';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar/UserAvatar';
import { AuthorWatchButton } from '@wix/da-shared-react/pkg/Button';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import { dateToDistanceTrans } from '@wix/da-ds/pkg/Time/functions';

import s from './MiniArtistCard.scss';

export interface Props {
  user: PapiUser;
  isOwnUser?: boolean;
  style?: React.CSSProperties;
  dataHook?: string;
}

export const MiniArtistCard: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  user,
  user: { profile, stats, details },
  isOwnUser,
  dataHook,
}) => {
  const { t } = useTranslation();

  const getBackgroundUrl = (deviation): string | undefined => {
    const info = getInfoToDisplayImageDeviation({
      deviation,
      width: 438,
    });
    if (info) {
      return info.pickedImage.src;
    }
  };

  const profilePic = profile && profile.profilePic;
  const backgroundImageSrc = profilePic && getBackgroundUrl(profilePic);
  const backgroundClassName = classnames(
    s['background'],
    !backgroundImageSrc && s['no-background']
  );

  const backgroundStyle: React.CSSProperties = {
    backgroundImage: backgroundImageSrc
      ? `url('${backgroundImageSrc}')`
      : undefined,
  };

  const joinedDistance = details && dateToDistanceTrans(details.joindate);

  return (
    <div className={s['root']} style={style} data-hook={dataHook}>
      <UserLink user={user} className={s['user-link']}>
        <div className={backgroundClassName} style={backgroundStyle} />
      </UserLink>
      <div className={s['content']}>
        <UserAvatar
          user={user}
          size={50}
          className={classnames(
            s['avatar'],
            user.usericonDefault && s['avatar-default']
          )}
        />
        <UserLink user={user} className={s['user-link']} />
        <div className={s['tagline']}>{profile && profile.tagline}</div>
        <div className={s['stats']}>
          {stats && (
            <div className={classnames(s['watchers'], s['stat-item'])}>
              {shortenNumber(stats.watchers, 0)}{' '}
              {t!('miniArtistCard.watchers', {
                formattedCount: stats.watchers,
                count: stats.watchers,
              })}
            </div>
          )}
          {joinedDistance && (
            <div className={classnames(s['joined'], s['stat-item'])}>
              &nbsp;|&nbsp;
              {t('miniArtistCard.joinedDateDistance', {
                distance: t(joinedDistance.key, {
                  count: joinedDistance.value,
                }),
              })}
            </div>
          )}
        </div>
        {!isOwnUser && (
          <AuthorWatchButton author={user} className={s['watch-button']} />
        )}
      </div>
    </div>
  );
};

MiniArtistCard.displayName = 'MiniArtistCard';
export default MiniArtistCard;
