import React, { useCallback, useContext } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import type { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { ThemeSurface } from '@wix/da-react-context/pkg/constants';
import Surface from '@wix/da-ds/pkg/Surface';
import TitledStrip, {
  cardWidth as DEFAULT_CARD_WIDTH,
} from '../../_partials/TitledStrip';
import SellerCard from '@wix/da-shared-react/pkg/SellerCard';
import { UsersExtra } from '@wix/da-shared-react/pkg/SellerCard/SellerCard';
import { useMeasuredMobileSwipeCookie } from '../useMeasuredMobileSwipeCookie';

import s from './FeaturedShopsStrip.scss';

const MULTIART_CARD_WIDTH = 284;

interface Props {
  strip: PapiNetworkbarStrip;
  className?: string;
  fullwidth?: boolean;
  multiArt?: boolean;
}

const FeaturedShopsStrip: React.FC<Props> = ({
  strip,
  className,
  fullwidth,
  multiArt,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { ref, dimensions } = useMeasuredMobileSwipeCookie();
  const cardWidth = multiArt ? MULTIART_CARD_WIDTH : DEFAULT_CARD_WIDTH;
  // todo: TitledStrip uses the hard-coded cardWidth still - why not pass it there too?

  const renderCard = useCallback(
    (usersExtra: UsersExtra) => (
      <div
        key={usersExtra.user.userId}
        className={s['center-for-mobile-slider']}
        ref={ref}
      >
        <SellerCard
          className={className}
          width={isMobile ? dimensions.width : cardWidth}
          withShopCta
          multiArt={multiArt}
          {...usersExtra}
        />
      </div>
    ),
    [cardWidth, isMobile, multiArt, dimensions, className, ref]
  );

  return (
    <Surface
      type={ThemeSurface.PRIMARY}
      className={c(s['root'], multiArt && s['multi-art'], className)}
    >
      <TitledStrip
        title={t('shopPage.sellYourArt.strips.featuredShops.heading')}
        description={t('shopPage.sellYourArt.strips.featuredShops.description')}
        className={s['content']}
        fullwidth={fullwidth}
      >
        {strip.usersExtra && strip.usersExtra.length > 0
          ? strip.usersExtra.map(usersExtra => renderCard(usersExtra))
          : (strip.users || []).map(user => renderCard({ user }))}
      </TitledStrip>
    </Surface>
  );
};

FeaturedShopsStrip.displayName = 'FeaturedShopsStrip';

export default FeaturedShopsStrip;
