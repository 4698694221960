import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import DeviationDuperbrowseContext from '@wix/da-shared-react/pkg/Duperbrowse/DeviationDuperbrowseContext';
import StandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';

export interface Props {
  itemClassName?: string;
  contentStrip?: PapiNetworkbarStrip;
}

const FeedSidebarContent: React.FC<Props> = ({
  itemClassName,
  contentStrip,
}) => {
  const { t } = useTranslation();
  if (!contentStrip || !contentStrip.deviations) {
    return null;
  }

  return (
    <DeviationDuperbrowseContext
      streamId={'post-feed-sidebar'}
      deviations={contentStrip.deviations}
      parent={{ title: t('duperbrowse.backButton.browse') }}
    >
      {contentStrip.deviations.map((deviation, rank) => (
        <BiLoggerContextProvider
          key={deviation.deviationId}
          value={{
            sectionname: 'posts_sidebar',
            rank,
          }}
        >
          <StandardThumb
            deviation={deviation}
            className={itemClassName}
            width={300}
            height={300}
          />
        </BiLoggerContextProvider>
      ))}
    </DeviationDuperbrowseContext>
  );
};
FeedSidebarContent.displayName = 'FeedSidebarContent';

export default FeedSidebarContent;
