import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiTag } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import TagList from '@wix/da-ds/pkg/TagList';
import BrowseThumbLayout from '../../../BrowseThumbLayout';
import ContentLoader from '../../../ContentLoader';
import Strips from '../../../Strips';
import MoreDeviationsBySearch from '../../../Strips/MoreDeviationsBySearch';
import OrderDropdown from '../../_partials/OrderDropdown';
import BasePageLayout from '../../_partials/BasePageLayout';
import SearchPageHeader from '../../../SearchPageHeader';
import { BiData, SearchTagClickBiEvent } from '@wix/da-bi/pkg/events';
import s from './SearchPage.scss';

export interface Props {
  searchTerm?: string;
  searchType?: string;
  mainContentUrl?: string;
  totalResults?: number;
  hasOrderSubnav?: boolean;
  initialOffset?: number;
  relatedTags?: PapiTag[];
  thisTag?: PapiTag;
}

const SearchPage: React.FC<Props> = ({
  hasOrderSubnav,
  initialOffset,
  searchTerm,
  searchType,
  mainContentUrl,
  totalResults = 0,
  relatedTags,
  thisTag,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const totalLabel = useMemo(() => {
    if (!totalResults) {
      return t('searchPage.numResults_zero', { searchTerm });
    }

    const formattedCount = shortenNumber(totalResults);
    const count = totalResults;

    return t('searchPage.numResults', {
      formattedCount,
      count,
    });
  }, [totalResults, t, searchTerm]);

  const tags = useMemo(() => {
    if (!relatedTags && !thisTag) {
      return;
    }
    const result = thisTag
      ? [
          {
            tagname: thisTag.tag,
            href: thisTag.url,
            isSelected: true,
            isRemovable: true,
            biData: BiData<SearchTagClickBiEvent>({
              evid: 140,
              tag_text: thisTag.tag,
              is_selected: 0, // Clicking would remove
              link_url: thisTag.url,
            }),
          },
        ]
      : [];

    if (relatedTags) {
      const mappedTags = relatedTags
        .filter(tag => tag.itemId !== thisTag?.itemId)
        .map(tag => ({
          tagname: tag.tag,
          href: tag.url,
          biData: BiData<SearchTagClickBiEvent>({
            evid: 140,
            tag_text: tag.tag,
            is_selected: 1, // Clicking would select
            link_url: tag.url,
          }),
        }));
      return [...result, ...mappedTags];
    }

    return result;
  }, [relatedTags, thisTag]);

  return (
    <BasePageLayout
      title={
        isMobile ? undefined : (
          <span className={s['results']}>{totalLabel}</span>
        )
      }
      headerRightSideContent={hasOrderSubnav && !isMobile && <OrderDropdown />}
      withNetworkBar={false}
      contentClassName={classnames(tags && s['with-tags'])}
    >
      <BiLoggerContextProvider
        value={{
          sectionname: 'general',
          _rankOffset: initialOffset,
        }}
      >
        <Strips areTopStrips />
        <ContentLoader>
          {tags && <TagList size="medium" tags={tags} className={s['tags']} />}
          {isMobile && hasOrderSubnav && (
            <div className={s['order-dropdown']}>
              <OrderDropdown />
            </div>
          )}
          {searchType === 'all' && <SearchPageHeader />}
          <BrowseThumbLayout
            contentStrip={
              <Strips className={s['strips']} stripClassName={s['strip']}>
                <MoreDeviationsBySearch
                  searchTerm={`'${searchTerm}'`}
                  mainContentUrl={mainContentUrl}
                />
              </Strips>
            }
          />
        </ContentLoader>
      </BiLoggerContextProvider>
    </BasePageLayout>
  );
};
SearchPage.displayName = 'SearchPage';

export default SearchPage;
