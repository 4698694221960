import React from 'react';
import classnames from 'classnames';
import s from './RemoveContentMargin.scss';

export interface Props {
  feedType?: string;
  className?: string;
  children: React.ReactNode;
}

/** Applies negative margins to counteract the default layout's paddings */
const RemoveContentMargin: React.FC<Props> = ({
  children,
  className,
  feedType,
}) => (
  // awkward construct is to make sure the negative margins don't mess with
  // any margins applied by the consumer
  <div className={className}>
    <div className={classnames(s['root'], s[`feed-${feedType}`])}>
      {children}
    </div>
  </div>
);
RemoveContentMargin.displayName = 'RemoveContentMargin';

export default RemoveContentMargin;
