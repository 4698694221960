// DS: MOF3.1
import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import useShopFilterState from './useShopFilterState';
import ShopFiltersSubnavRadioGroup from './ShopFiltersSubnavRadioGroup';

import s from './ShopFiltersForm.scss';

export interface Props {
  className?: string;
}

const ShopFiltersForm: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const {
    statusSubnav,
    priceRangeSubnav,
    filterSubnavValues,
    updateFilters,
    updateFiltersAndReload,
    resetFilters,
    resetFiltersAndReload,
  } = useShopFilterState();

  // on mobile, the page only reloads and filters only update when apply is clicked
  // on desktop, when the user changes any value in the form, the page automatically
  // navigates, updating the form values
  const onChange = isMobile ? updateFilters : updateFiltersAndReload;
  const onReset = isMobile ? resetFilters : resetFiltersAndReload;
  const reloadWithCurrentFilters = () => updateFiltersAndReload();

  return (
    <form
      className={classnames(className, s['root'])}
      onSubmit={ev => ev.preventDefault()}
      onReset={ev => {
        ev.preventDefault();
        onReset();
      }}
    >
      {!!statusSubnav && (
        <ShopFiltersSubnavRadioGroup
          className={s['status']}
          buttonClassName={s['status-radio-button']}
          subnav={statusSubnav}
          currentValue={filterSubnavValues.filter_adoptable_status}
          legend={t('shopPage.shopFiltersForm.status.legend')}
          labelLocaleKeyPrefix="shopPage.shopFiltersForm.status.label."
          onChange={onChange}
        />
      )}

      {!!priceRangeSubnav && (
        <ShopFiltersSubnavRadioGroup
          className={s['price-ranges']}
          buttonClassName={s['price-range']}
          subnav={priceRangeSubnav}
          currentValue={filterSubnavValues.filter_price_range}
          legend={t('shopPage.shopFiltersForm.price.legend')}
          onChange={onChange}
        />
      )}

      {isMobile ? (
        <div className={s['mobile-button-group']}>
          <GhostButton
            type="reset"
            variant="main"
            size="large"
            onClick={onReset}
          >
            {t('shopPage.shopFiltersForm.resetButton.mobile')}
          </GhostButton>

          <SolidButton
            size="large"
            type="button"
            variant="approval-green"
            priority="primary"
            onClick={reloadWithCurrentFilters}
          >
            {t('shopPage.shopFiltersForm.applyButton.mobile')}
          </SolidButton>
        </div>
      ) : (
        <TextButton
          className={s['reset-button-desktop']}
          size="small"
          type="reset"
          variant="main"
          onClick={onReset}
        >
          {t('shopPage.shopFiltersForm.resetButton')}
        </TextButton>
      )}
    </form>
  );
};

ShopFiltersForm.displayName = 'ShopFiltersForm';
export default ShopFiltersForm;
