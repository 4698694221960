import React from 'react';
import { useTranslation } from 'react-i18next';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import Button from '@wix/da-shared-react/pkg/Button';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { BiData, SeeAllClickBiEvent } from '@wix/da-bi/pkg/events';

import s from './SearchPageHeader.scss';

export interface Props {
  estTotal?: number;
  mainContentUrl?: string;
}

const SearchPageHeader: React.FC<Props> = ({ estTotal, mainContentUrl }) => {
  const { t } = useTranslation();
  const isDeviationsTitleALink = Boolean(mainContentUrl);
  return (
    <>
      <div className={s['deviations-header']}>
        <div className={s['deviations-title-container']}>
          <span className={s['deviations-title']}>
            {isDeviationsTitleALink ? (
              <BiLink
                href={mainContentUrl}
                biData={BiData<SeeAllClickBiEvent>({ evid: 106 })}
              >
                {t('facetPage.deviationsTitle')}
              </BiLink>
            ) : (
              t('facetPage.deviationsTitle')
            )}
          </span>
          <span className={s['deviations-count']}>
            {shortenNumber(estTotal)}
          </span>
        </div>
        {mainContentUrl && (
          <Button
            view="b33"
            href={mainContentUrl}
            className={s['deviations-all']}
            biData={BiData<SeeAllClickBiEvent>({ evid: 106 })}
            fullWidth={false}
          >
            {t('common.viewAll')}
          </Button>
        )}
      </div>
    </>
  );
};
SearchPageHeader.displayName = 'SearchPageHeader';

export default SearchPageHeader;
