import React, { useContext } from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Slider from '@wix/da-ds/pkg/Slider';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import s from './TitledStrip.scss';
import commonStyles from '../../common.scss';

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  fullwidth?: boolean;
}

export const cardWidth = 260;

const TitledStrip = React.forwardRef<HTMLDivElement, Props>(
  ({ title, description, children, className, fullwidth }, ref) => {
    const isMobile = useContext(MobileContext);
    return (
      <Section
        className={c(
          s['root'],
          commonStyles['section-horizontal-sizing'],
          fullwidth && commonStyles['fullwidth'],
          className
        )}
        ref={ref}
      >
        <div className={s['heading-and-description']}>
          <Heading className={s['heading']}>{title}</Heading>
          <p className={s['description']}>{description}</p>
        </div>
        {isMobile ? (
          <SwipeSlider className={s['swipe-slider']}>{children}</SwipeSlider>
        ) : (
          <Slider scrollBy={cardWidth * 4} className={s['custom-spacing']}>
            {children}
          </Slider>
        )}
      </Section>
    );
  }
);

TitledStrip.displayName = 'TitledStrip';

export default TitledStrip;
