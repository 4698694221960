/** Define some meta info about shop filters so we can parse url params, etc. */
export const SHOP_FILTER_TYPEOFS = {
  min_price: 'number',
  max_price: 'number',
  status: 'string',
};

export const SHOP_PARAMS_TYPEOFS = {
  ...SHOP_FILTER_TYPEOFS,
  q: 'string',
  order: 'string',
  deviationid: 'number',
};

export enum StripDeviationType {
  UNKNOWN = 'unknown',
  COMMISSION = 'commission',
  TIER = 'tier',
  PCP = 'pcp',
  ADOPTABLE = 'adoptable',
}
