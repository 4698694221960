import React, { lazy, Suspense } from 'react';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import retry from '@wix/da-shared-react/pkg/utils/webpack/chunkRetry';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import SubscribedFilter, { Props } from '../SubscribedFilter';

const SubscribedFilterFTUETooltip = lazy(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "ms_ftue" */
        './SubscribedFilterFTUETooltip'
      )
  )
);

export const LoadableSubscribedFilterFTUETooltip: React.FC<Props> = props => {
  const isMounted = useIsMounted();
  const loading = <SubscribedFilter {...props} />;

  if (!isMounted) {
    return loading;
  }

  return (
    <ErrorBoundary debugComponent="LoadableSubscribedFilterFTUETooltip">
      <Suspense fallback={loading}>
        <SubscribedFilterFTUETooltip {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};
LoadableSubscribedFilterFTUETooltip.displayName =
  'LoadableSubscribedFilterFTUETooltip';

export default LoadableSubscribedFilterFTUETooltip;
