import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../types/store';
import { showLessLikeThis } from '../../actions/recommendation';
import { Props } from './ItemCard';
import {
  getCanShowLessLikeThis,
  getShowTierPromotionTooltip,
} from '../../selectors/page';

export type StateProps = Pick<
  Props,
  'canShowLess' | 'showTierPromotionTooltip'
>;
export type DispatchProps = Pick<Props, 'onShowLess'>;
type OwnProps = Omit<
  Props,
  keyof (Omit<StateProps, 'showTierPromotionTooltip'> & DispatchProps)
>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => ({
  canShowLess: getCanShowLessLikeThis(state) && !ownProps.disableShowLess,
  showTierPromotionTooltip:
    ownProps.showTierPromotionTooltip ?? getShowTierPromotionTooltip(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  onShowLess: showLessLikeThis,
};

export default connect(mapStateToProps, mapDispatchToProps);
