import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  default as FeedLayoutShared,
  FeedContent,
  FeedSidebar,
} from '@wix/da-shared-react/pkg/FeedLayout';
import FeedPostbox from './FeedPostbox';
import FeedNotice from './FeedNotice';
import FeedSidebarContent from '../FeedSidebarContent';
import s from './FeedLayout.scss';

export interface Props {
  children?: React.ReactNode;
}

const FeedLayout: React.FC<Props> = ({ children }) => {
  const isMobile = useContext(MobileContext);
  return (
    <>
      {!isMobile && <FeedPostbox className={s['feed-postbox']} />}
      <FeedLayoutShared>
        <FeedContent>
          {isMobile && <FeedPostbox className={s['feed-postbox']} />}
          <FeedNotice className={s['feed-notice']} />
          {children}
        </FeedContent>

        {!isMobile && (
          <FeedSidebar>
            <FeedSidebarContent itemClassName={s['sidebar-block']} />
          </FeedSidebar>
        )}
      </FeedLayoutShared>
    </>
  );
};
FeedLayout.displayName = 'FeedLayout';

export default FeedLayout;
