// DS5.6
/** Set of controls and inputs that open the status, journal, and poll submission
 *  modals in an alternate way to the submission menu.
 *  Meant to encourage users to post
 */
import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ModalOptions,
  ModalType,
} from '@wix/da-shared-react/pkg/Modals/redux/types';
import BiButton from '@wix/da-bi/pkg/Components/BiButton';
import { DeviationResourceType } from '@wix/da-shared-react/pkg/types/deviation';
import { useVerification } from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import { PapiUser } from '@wix/da-papi-types';
import SubmitPostStatus from '@wix/da-ds/pkg/Icons/24x24/SubmitPostStatus';
import SubmitPostJournal from '@wix/da-ds/pkg/Icons/24x24/SubmitPostJournal';
import SubmitPostPoll from '@wix/da-ds/pkg/Icons/24x24/SubmitPostPoll';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { BiData, PostFromFeedBiEvent } from '@wix/da-bi/pkg/events';

import s from './FeedPostbox.scss';

export interface Props {
  canSubmitJournal?: boolean;
  canSubmitPoll?: boolean;
  canSubmitStatus?: boolean;
  currentUser?: PapiUser;
  thisGroup?: PapiUser;
  className?: string;
  showSubmitModal: (type: ModalType, options?: ModalOptions) => void;
}

const FeedPostbox: React.FC<Props> = ({
  className,
  canSubmitJournal = true,
  canSubmitPoll = true,
  canSubmitStatus = true,
  currentUser,
  thisGroup,
  showSubmitModal,
}) => {
  const { t } = useTranslation();

  const { isVerificationNeeded, pushVerificationPopup } = useVerification();
  const openStatusModal = useCallback(() => {
    if (isVerificationNeeded) {
      return pushVerificationPopup('submitstatus');
    }
    showSubmitModal(ModalType.STATUS_UPDATE, {
      params: { refreshAfterSubmit: true },
    });
  }, [showSubmitModal, isVerificationNeeded, pushVerificationPopup]);
  const openJournalModal = useCallback(() => {
    if (isVerificationNeeded) {
      return pushVerificationPopup('submitjournal');
    }
    showSubmitModal(ModalType.JOURNAL, {
      params: { refreshAfterSubmit: true },
    });
  }, [showSubmitModal, isVerificationNeeded, pushVerificationPopup]);
  const openGroupJournalModal = useCallback(() => {
    if (isVerificationNeeded) {
      return pushVerificationPopup('submitjournal');
    }
    showSubmitModal(ModalType.JOURNAL, {
      params: { refreshAfterSubmit: true, groupDestination: thisGroup },
    });
  }, [showSubmitModal, thisGroup, isVerificationNeeded, pushVerificationPopup]);

  const openPollModal = useCallback(() => {
    if (isVerificationNeeded) {
      return pushVerificationPopup('submitpoll');
    }
    showSubmitModal(ModalType.POLL_EDIT, {
      params: { refreshAfterSubmit: true },
    });
  }, [showSubmitModal, isVerificationNeeded, pushVerificationPopup]);

  if (
    !currentUser ||
    (!canSubmitJournal && !canSubmitPoll && !canSubmitStatus)
  ) {
    return null;
  }

  return (
    <div
      className={cn(
        'ds-surface-tertiary',
        s['root'],
        thisGroup && s['group-mode'],
        className
      )}
    >
      <UserAvatar
        className={s['avatar']}
        user={currentUser}
        size={32}
        withLink={false}
      />

      <BiButton
        className={s['fake-text-input']}
        onClick={thisGroup ? openGroupJournalModal : openStatusModal}
        biData={BiData<PostFromFeedBiEvent>({
          evid: 117,
          typeid: thisGroup
            ? DeviationResourceType.JOURNAL
            : DeviationResourceType.STATUS_DEVIATION,
          is_from_textbox: 1,
        })}
      >
        {thisGroup
          ? t('postsPage.postbox.fakeInputPlaceholder.group', {
              groupname: thisGroup.username,
            })
          : t('postsPage.postbox.fakeInputPlaceholder', {
              username: currentUser.username,
            })}
      </BiButton>

      {!thisGroup && (
        <div className={s['button-row']}>
          {canSubmitStatus && (
            <BiButton
              className={s['post-button']}
              onClick={openStatusModal}
              biData={BiData<PostFromFeedBiEvent>({
                evid: 117,
                typeid: DeviationResourceType.STATUS_DEVIATION,
                is_from_textbox: 0,
              })}
            >
              <SubmitPostStatus
                size={IconSize.SMALL}
                className={s['post-button-icon']}
              />
              {t('siteHeader.statusUpdate')}
            </BiButton>
          )}

          {canSubmitJournal && (
            <BiButton
              className={s['post-button']}
              onClick={openJournalModal}
              biData={BiData<PostFromFeedBiEvent>({
                evid: 117,
                typeid: DeviationResourceType.JOURNAL,
                is_from_textbox: 0,
              })}
            >
              <SubmitPostJournal
                size={IconSize.SMALL}
                className={s['post-button-icon']}
              />
              {t('siteHeader.journal')}
            </BiButton>
          )}

          {canSubmitPoll && (
            <BiButton
              className={s['post-button']}
              onClick={openPollModal}
              biData={BiData<PostFromFeedBiEvent>({
                evid: 117,
                typeid: DeviationResourceType.POLL,
                is_from_textbox: 0,
              })}
            >
              <SubmitPostPoll
                size={IconSize.SMALL}
                className={s['post-button-icon']}
              />
              {t('siteHeader.poll')}
            </BiButton>
          )}
        </div>
      )}
    </div>
  );
};
FeedPostbox.displayName = 'FeedPostbox';
export default FeedPostbox;
