import React, { useContext } from 'react';
import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import classnames from 'classnames';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import Strip from './Strip';
import { isWatchSuggestionStrip } from '../../helpers/streams';
import type { WatchSuggestionsStrip } from '../../../types/api';
import WatchSuggestionStrip from './WatchSuggestionStrip';
import LazyContext from '@wix/da-shared-react/pkg/LazyLoadingImage/LazyContext';

import s from './Strips.scss';

export interface Props {
  strips?: PapiNetworkbarStrip[];
  /** Are these strips displayed at the very top of the homepage */
  areTopStrips?: boolean;
  isSearchPage?: boolean;
  isShopPage?: boolean;
  className?: string;
  stripClassName?: string;
  children?: React.ReactNode;
}

const Strips: React.FC<Props> = ({
  strips,
  areTopStrips,
  isSearchPage,
  isShopPage,
  className,
  stripClassName,
  children,
}) => {
  const isMobile = useContext(MobileContext);

  if (!strips || strips.length === 0) {
    return null;
  }

  // watch suggestions are a special snowflake, if they appear on their own dont wrap them
  if (strips.length === 1 && isWatchSuggestionStrip(strips[0])) {
    return <WatchSuggestionStrip strip={strips[0] as WatchSuggestionsStrip} />;
  }

  // if we're searching on mobile, expand the strip width to the outside of the viewport
  // so that cards in the strip slider on the right and left can always be seen
  let sliderClassName, sliderContentClassName;
  if (isMobile && isSearchPage) {
    sliderClassName = s['full-width-strips'];
    sliderContentClassName = s['full-width-strip-content'];
  }

  return (
    <div
      className={classnames(
        s['strips'],
        isSearchPage && s['on-search-page'],
        areTopStrips && s['top-strips'],
        className
      )}
    >
      <OverlayContextProvider
        value={{
          showDailyDeviationLabel: false,
          showSuggestedReasonLabel: false,
          showExtraActions: false,
        }}
      >
        {strips.map((strip, index) => (
          <BiLoggerContextProvider
            key={`${strip.title}-${index}`}
            value={{ sectionrank: index }}
          >
            <LazyContext.Provider value={!areTopStrips || isMobile}>
              <Strip
                strip={strip}
                className={classnames(s['strip'], stripClassName)}
                sliderClassName={sliderClassName}
                sliderContentClassName={sliderContentClassName}
                isTopStrip={areTopStrips}
                isSearchPage={isSearchPage}
                isShopPage={isShopPage}
              />
            </LazyContext.Provider>
          </BiLoggerContextProvider>
        ))}
      </OverlayContextProvider>

      {children}
    </div>
  );
};
Strips.displayName = 'Strips';

export default Strips;
