import React from 'react';
import { Dims } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import ItemCard from '../../ItemCard';
import { BrowseItem } from '../../../../types/api';
import { ItemTypes } from '../../../../constants';

export interface Props {
  item: BrowseItem;
  itemIndex: number;
  type: ItemTypes;
  elementSize: Dims;
  disableShowLess?: boolean;
}

const StripItem: React.FC<Props> = ({
  type,
  item,
  itemIndex,
  elementSize,
  disableShowLess = true,
}) => {
  return (
    <ItemCard
      itemIndex={itemIndex}
      type={type}
      item={item}
      style={elementSize}
      width={elementSize.width}
      height={elementSize.height}
      inclusiveFooter
      disableShowLess={disableShowLess}
      showTierPromotionTooltip={false}
    />
  );
};
StripItem.displayName = 'StripItem';

export default StripItem;
