import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import CalendarIcon from '@wix/da-ds/pkg/Icons/Calendar';
import CloseSmallIcon from '@wix/da-ds/pkg/Icons/CloseBold';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import DatePickerPopup from './DatePickerPopup';
import NativeDatePicker from './NativeDatePicker';
import s from './DateControls.scss';

export interface Props {
  date?: string;
}

const DateControls: React.FC<Props> = ({ date }) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();
  const [isPickerVisible, setPickerVisible] = useState(false);

  return (
    <>
      <div
        className={classnames(s['root'], date && s['active'])}
        onClick={() => setPickerVisible(true)}
      >
        <CalendarIcon disableDefaultColors className={s['calendar-icon']} />
        {isMobile ? (
          <NativeDatePicker
            date={date}
            className={s['picker']}
            placeholder={t('subnavigation.button.datepicker')}
            isFocused={isPickerVisible}
            onFocus={() => setPickerVisible(true)}
            onBlur={() => setPickerVisible(false)}
          />
        ) : (
          <DatePickerPopup
            date={date}
            isFocused={isPickerVisible}
            onFocusChange={({ isFocused }) => setPickerVisible(isFocused)}
            placeholder={t('subnavigation.button.datepicker')}
            className={s['picker']}
          />
        )}
        {date && (
          <PlainButtonOrLink
            className={s['close-icon']}
            href={Url.browseDailyDeviationsLink()}
            onClick={e => e.stopPropagation()}
          >
            <CloseSmallIcon disableDefaultColors customSizable />
          </PlainButtonOrLink>
        )}
      </div>
    </>
  );
};
DateControls.displayName = 'DateControls';

export default DateControls;
