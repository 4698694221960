import { connect, MapStateToProps } from 'react-redux';
import { getIsMobile } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { AppState } from '../../../../types/store';
import {
  getShopSearchQuery,
  getSearchType,
  getCurrentOrder,
  getIsInfiniteScroll,
} from '../../../../selectors/page';
import { getInitialOffset, getTotal } from '../../../../selectors/stream';

import { Props } from './ShopPage';

export type StateProps = Pick<
  Props,
  | 'strips'
  | 'searchPageType'
  | 'searchTerm'
  | 'order'
  | 'initialOffset'
  | 'totalResults'
  | 'isInfiniteScroll'
  | 'isMobile'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const isMobile = getIsMobile(state);
  return {
    strips: state.currentPageData?.strips,
    searchPageType: getSearchType(state),
    searchTerm: getShopSearchQuery(state) || '',
    order: getCurrentOrder(state) ?? '',
    isInfiniteScroll: getIsInfiniteScroll(state),
    initialOffset: getInitialOffset(state),
    totalResults: getTotal(state),
    isMobile,
  };
};

export default connect(mapStateToProps);
