/** A row of content that is designed to appear within a larger layout,
 *  often representing a larger set of content like a stream, a search,
 *  or a curated collection.
 */
import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { PapiDeviation, PapiNetworkbarStrip } from '@wix/da-papi-types';
import DeviationShopCard from '@wix/da-shared-react/pkg/ShopCard/DeviationShopCard';
import CommissionShopCard from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import StripWrapper from './StripWrapper';
import DeviationsStrip from './DeviationsStrip';
import MobileNonSearchDeviationsStrip from './MobileNonSearchDeviationsStrip';
import WatchSuggestionStrip from './WatchSuggestionStrip';
import MeasuredStripSlider from './MeasuredStripSlider';
import StripSlider from './StripSlider';
import TagsStrip from './TagsStrip';
import SwipeStrip from './SwipeStrip';
import ShopStrip from './ShopStrip';
import FeaturedShopsStrip from '../Page/variants/ShopPage/ShopSellYourArtPage/strips/FeaturedShopsStrip';
import {
  getStripDeviationType,
  isCollectionStrip,
  isDeviationStrip,
  isGroupStrip,
  isTagStrip,
  isTopNecoStrip,
  isUserStrip,
  isWatchSuggestionStrip,
} from '../../helpers/streams';
import { StripDeviationType } from '../../types/shop';

export interface Props {
  /** stream data and initial array of content and url pointing to larger collection */
  strip: PapiNetworkbarStrip;
  /** is this strip displayed at the very top of the home page */
  isTopStrip?: boolean;
  isSearchPage?: boolean;
  isShopPage?: boolean;

  className?: string;
  sliderClassName?: string;
  sliderContentClassName?: string;
}

const Strip: React.FC<Props> = props => {
  const {
    strip,
    sliderClassName,
    sliderContentClassName,
    className,
    isSearchPage,
    isShopPage,
  } = props;
  const isMobile = useContext(MobileContext);

  if (isWatchSuggestionStrip(strip)) {
    return <WatchSuggestionStrip strip={strip} />;
  }

  if (isTopNecoStrip(strip)) {
    return null;
  }

  if (isShopPage) {
    /* For subscription strips we render the default card, otherwise we use ShopCard */
    const stripDeviationType = getStripDeviationType(strip);
    const isTierStrip = stripDeviationType === StripDeviationType.TIER;
    return isMobile ? (
      <SwipeStrip
        strip={strip}
        className={className}
        renderItem={
          isTierStrip
            ? undefined
            : ({ item, index, width }) => {
                if (stripDeviationType === StripDeviationType.PCP) {
                  return (
                    <PCPShopCard
                      key={`${strip.codename}-${index}`}
                      deviation={item as PapiDeviation}
                      width={width}
                    />
                  );
                }
                if (stripDeviationType === StripDeviationType.COMMISSION) {
                  return (
                    <CommissionShopCard
                      key={`${strip.codename}-${index}`}
                      deviation={item as PapiDeviation}
                      width={width}
                      withIndicators={false}
                    />
                  );
                }
                return (
                  <DeviationShopCard
                    key={`${strip.codename}-${index}`}
                    deviation={item as PapiDeviation}
                    width={width}
                  />
                );
              }
        }
      />
    ) : (
      <ShopStrip strip={strip} className={className} />
    );
  }

  if (isDeviationStrip(strip)) {
    const isMobileNonSearch =
      isMobile &&
      !isSearchPage &&
      getStripDeviationType(strip) !== StripDeviationType.TIER;
    return isMobileNonSearch ? (
      <MobileNonSearchDeviationsStrip
        strip={strip}
        className={className}
        sliderClassName={sliderClassName}
      />
    ) : (
      <DeviationsStrip
        strip={strip}
        className={className}
        sliderClassName={sliderClassName}
        sliderContentClassName={sliderContentClassName}
        isSearchPage={isSearchPage}
        disableShowLess={!isSearchPage}
      />
    );
  }

  if (isTagStrip(strip)) {
    return (
      <TagsStrip
        strip={strip}
        isSearchPage={isSearchPage}
        className={className}
      />
    );
  }

  if (strip.codename === 'marketplace_featured_shops') {
    return <FeaturedShopsStrip fullwidth strip={strip} />;
  } else if (strip.codename === 'featured_shops') {
    return <FeaturedShopsStrip fullwidth strip={strip} multiArt />;
  }

  if (
    !isCollectionStrip(strip) &&
    !isGroupStrip(strip) &&
    !isUserStrip(strip)
  ) {
    return null;
  }

  return (
    <StripWrapper {...props}>
      {isSearchPage ? (
        <MeasuredStripSlider
          strip={strip}
          disableShowLess={!isSearchPage}
          className={sliderClassName}
          contentClassName={sliderContentClassName}
        />
      ) : (
        <StripSlider strip={strip} disableShowLess={!isSearchPage} />
      )}
    </StripWrapper>
  );
};
Strip.displayName = 'Strip';

export default Strip;
