// DS: INT01D
import React from 'react';
import classnames from 'classnames';
import { PapiGallection } from '@wix/da-papi-types';
import Attribution from '@wix/da-shared-react/pkg/DeviationViews/Helper/Attribution';
import { gallectionTypeToResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import {
  ThemeOverride,
  ThemeOptions,
} from '@wix/da-react-context/pkg/ThemeContext';

import s from './CollectionCardOverlay.scss';

export interface Props {
  gallection: PapiGallection;
  className?: string;
}

export const CollectionCardOverlay: React.FC<Props> = ({
  gallection,
  className,
}) => {
  return (
    <ThemeOverride theme={ThemeOptions.DARK}>
      <div className={classnames(s['root'], className)}>
        <Attribution
          showAuthorTooltip
          relatedItemBiData={
            {
              typeid: gallectionTypeToResourceType(gallection.type),
              itemid: gallection.folderId,
            } as any
          }
          author={gallection.owner}
          className={s['attribution']}
          userlinkClassName={s['user-link']}
        />
      </div>
    </ThemeOverride>
  );
};
CollectionCardOverlay.displayName = 'CollectionCardOverlay';

export default CollectionCardOverlay;
