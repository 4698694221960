import { PapiNetworkbarStrip } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useCallback, useContext } from 'react';
import { ItemTypes } from '../../../../constants';
import { getStripType, getStripDeviationType } from '../../../helpers/streams';
import {
  COMMISSION_STRIP_ITEM_SIZE,
  DEFAULT_STRIP_ITEM_SIZE,
  TIER_STRIP_ITEM_SIZE_MOBILE,
  TIER_STRIP_ITEM_SIZE,
} from '../constants';
import { Dims } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { StripDeviationType } from '../../../types/shop';

export function useElementSize(strip: PapiNetworkbarStrip): Dims {
  const isMobile = useContext(MobileContext);
  const type = getStripDeviationType(strip);

  switch (type) {
    case StripDeviationType.COMMISSION:
      return COMMISSION_STRIP_ITEM_SIZE;
    case StripDeviationType.TIER:
      return isMobile ? TIER_STRIP_ITEM_SIZE_MOBILE : TIER_STRIP_ITEM_SIZE;
    default:
      return DEFAULT_STRIP_ITEM_SIZE;
  }
}

export function useGetMeasuredElementSize(strip: PapiNetworkbarStrip) {
  const isMobile = useContext(MobileContext);
  const type = getStripType(strip);

  const getMeasuredElementSize = useCallback(
    (defaultSize: Dims): Dims => {
      switch (type) {
        case ItemTypes.Gallection:
          return { ...defaultSize, height: 340 };
        case ItemTypes.Deviation:
          const deviationType = getStripDeviationType(strip);
          switch (deviationType) {
            case StripDeviationType.COMMISSION:
              return { ...defaultSize, height: 360 };
            case StripDeviationType.TIER:
              // tiers dont give a duck about measurements
              return isMobile
                ? TIER_STRIP_ITEM_SIZE_MOBILE
                : TIER_STRIP_ITEM_SIZE;
            default:
              return defaultSize;
          }
        default:
          return defaultSize;
      }
    },
    [isMobile, strip, type]
  );

  return getMeasuredElementSize;
}
