import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import DeviantsBar from '../../../../DeviantsBar';
import SingleDeviantEmptyResult from '../../../../EmptyResult/SingleDeviant';
import BasePageLayout from '../../../_partials/BasePageLayout';
import DefaultPageContent from '../../../_partials/DefaultPageContent';
import WatchButton from '../../../../WatchButton';
import BirthdayBanner from '../../../../BirthdayBanner';
import { BiContext } from '@wix/da-bi/pkg/BiLogger.context';
import {
  BiData,
  BiEvent,
  WatchItemRemoveClickBiEvent,
} from '@wix/da-bi/pkg/events';

import s from './DesktopArtistWatchPage.scss';

export interface Props {
  feedType?: string;
  thisUser: PapiUser;
  isEmpty?: boolean;
  showRemoveAll?: boolean;
  removeAll: (biData: BiEvent) => void;
  removeDeviation: (deviation: PapiDeviation, offset: number) => void;
}

const DesktopArtistWatchPage: React.FC<Props> = ({
  feedType,
  thisUser,
  isEmpty,
  showRemoveAll,
  removeAll,
  removeDeviation,
}) => {
  const { t } = useTranslation();
  const biData = useContext(BiContext);
  const { user } = useContext(SessionContext);

  const isOwnPage =
    !!user &&
    !!thisUser &&
    (user === thisUser.userId ||
      (user as PapiUser).userId === thisUser?.userId);

  return (
    <BasePageLayout
      title={<UserLink user={thisUser} className={s['username']} />}
      customHeader={<DeviantsBar />}
      headerIsStickyOnScrollUp
      contentClassName={classnames(
        isEmpty && s['content-empty'],
        thisUser.isBirthweek && s['with-banner']
      )}
      headerRightSideContent={
        <>
          {showRemoveAll && (
            <GhostButton
              size="small"
              variant="main"
              className={s['rightside-button']}
              onClick={() =>
                removeAll({ ...biData, sectionname: 'button' } as any)
              }
              biData={BiData<WatchItemRemoveClickBiEvent>({
                evid: 405,
                sectionname: 'button',
              })}
            >
              {t('subnavigation.button.removeAll')}
            </GhostButton>
          )}
          {!isOwnPage && (
            <div className={s['rightside-button']}>
              <WatchButton className={s['watch-button']} user={thisUser} />
            </div>
          )}
        </>
      }
      allowSaleBanner={!thisUser.isBirthweek}
    >
      {thisUser.isBirthweek && (
        <BirthdayBanner user={thisUser} className={s['birthday-banner']} />
      )}
      {isEmpty ? (
        <SingleDeviantEmptyResult feedType={feedType} />
      ) : (
        <OverlayContextProvider
          value={{
            showPermanentEngagementIcon: true,
            showAuthorWatchButton: false,
          }}
        >
          <DefaultPageContent
            onRemove={removeDeviation}
            withWatchOrSubscribeButtons={false}
          />
        </OverlayContextProvider>
      )}
    </BasePageLayout>
  );
};
DesktopArtistWatchPage.displayName = 'DesktopArtistWatchPage';

export default DesktopArtistWatchPage;
