import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import { useCurrentPromo } from '@wix/da-shared-react/pkg/utils/hooks/useCurrentPromo';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks/useFlag';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import BasePageLayout from '../../_partials/BasePageLayout';
import DefaultPageContent from '../../_partials/DefaultPageContent';
import SubmitButton from '../../_partials/SubmitButton';
import DreamUpButton from '../../_partials/DreamUpButton';
import TagSliderSubnav from '../../_partials/TagSliderSubnav';

import s from './HomePage.scss';

export interface Props {
  feedType?: string;
  hasOrderSubnav?: boolean;
}

const HomePage: React.FC<Props> = ({ feedType, hasOrderSubnav }) => {
  const { t } = useTranslation();
  const { isLoggedIn, aiContentFilter } = useContext(SessionContext);
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;

  // disable DD flag on anon and killswitched home page which is also a DD page
  const rfyKillswitch = useFlag('homepage_rfy_killswitch');
  const showDailyDeviationLabel = isLoggedIn && !rfyKillswitch;

  const currentPromo = useCurrentPromo('self');
  const showCorePromo = !!currentPromo;
  const isShowingMobileSubmit = isMobile && isLoggedIn && feedType !== 'posts';

  const isShowingMobileDreamUpCTA =
    isMobile && isLoggedIn && aiContentFilter !== 'suppress';

  const tagSliderSubnav = (
    <TagSliderSubnav
      className={s['tag-slider-subnav']}
      withClearButton
      withTodaysTagIcon
    />
  );
  return (
    <OverlayContextProvider value={{ showDailyDeviationLabel }}>
      <BasePageLayout
        aboveHeader={isMobile && tagSliderSubnav}
        headerIsStickyOnScrollUp={false}
        headerClassName={s['header']}
        title={isMobile ? undefined : t('subnavigation.title.home')}
        titleClassName={s['title']}
        allowSaleBanner={showCorePromo}
        belowHeader={isDesktop && tagSliderSubnav}
        contentClassName={s['content-block']}
      >
        {(isShowingMobileDreamUpCTA || isShowingMobileSubmit) && (
          <div className={s['mobile-buttons-wrapper']}>
            {isShowingMobileSubmit && (
              <SubmitButton className={s['mobile-submit-button']} />
            )}
            {isShowingMobileDreamUpCTA && (
              <DreamUpButton className={s['mobile-dreamup-cta']} />
            )}
          </div>
        )}

        <DefaultPageContent />
      </BasePageLayout>
    </OverlayContextProvider>
  );
};
HomePage.displayName = 'HomePage';

export default HomePage;
