import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Url } from '@wix/da-url';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import { BiData, DreamupInfoClickBiEvent } from '@wix/da-bi/pkg/events';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import DreamUpStars16 from '@wix/da-ds/pkg/Icons/16x16/DreamUpStars';
import s from './DreamupBlock.scss';

interface Props {
  className?: string;
}

const DreamupBlock: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Section className={c(s['root'], className)}>
      <div className={s['left']}>
        <div className={s['heading-and-description']}>
          <Heading className={s['heading']}>
            {t('shopPage.sellYourArt.dreamup.heading')}
          </Heading>
          <p className={s['description']}>
            {t('shopPage.sellYourArt.dreamup.description')}
          </p>
        </div>
        <BrandedButton
          size="medium"
          variant="dreamup-purple"
          icon={DreamUpStars16}
          href={Url.dreamupLink()}
          biData={BiData<DreamupInfoClickBiEvent>({
            evid: 923,
            sectionname: 'dreamup',
          })}
        >
          {t('shopPage.sellYourArt.dreamup.button.text')}
        </BrandedButton>
      </div>
      <div className={s['right']}>
        <img
          className={s['dreamup-screenshot']}
          alt=""
          src="https://st.deviantart.net/eclipse/global/images/shop/dreamup-screenshot.png"
        />
      </div>
    </Section>
  );
};

DreamupBlock.displayName = 'DreamupBlock';

export default DreamupBlock;
