import { connect, MapStateToProps } from 'react-redux';
import { Props } from './RemoveContentMargin';
import { AppState } from '../../../../types/store';
import { getCurrentFeedType } from '../../../../selectors/page';

export type StateProps = Pick<Props, 'feedType'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  feedType: getCurrentFeedType(state),
});

export default connect(mapStateToProps);
