import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '@wix/da-shared-react/pkg/Popup/ConfirmationModal';
import { PremiumTrash } from '@wix/da-ds/pkg/Icons/Confirmations/PremiumTrash';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import {
  BiData,
  BiEvent,
  WatchConfirmRemovalBiEvent,
} from '@wix/da-bi/pkg/events';

interface Props {
  biData?: BiEvent;
  removeAll: () => Action;
  requestClose: () => void;
}

const DywRemoveConfirmationModal: React.FC<Props> = ({
  biData,
  removeAll,
  requestClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(removeAll());
    requestClose();
  }, [dispatch, removeAll, requestClose]);

  return (
    <ConfirmationModal
      isOpen
      withCancelButton
      title={t('watch.removeAll.modal.title')}
      text={t('watch.removeAll.modal.body')}
      cancelBtnLabel={t('watch.removeAll.modal.button.cancel')}
      cancelBtnBiData={BiData<WatchConfirmRemovalBiEvent>({
        ...biData,
        evid: 416,
        removal_confirmed: 0,
        is_grouped: 0,
      })}
      confirmBtnLabel={t('watch.removeAll.modal.button.remove')}
      confirmBtnBiData={BiData<WatchConfirmRemovalBiEvent>({
        ...biData,
        evid: 416,
        removal_confirmed: 1,
        is_grouped: 0,
      })}
      icon={<PremiumTrash />}
      onConfirm={onConfirm}
      onCancel={requestClose}
    />
  );
};
DywRemoveConfirmationModal.displayName = 'DywRemoveConfirmationModal';

export default DywRemoveConfirmationModal;
