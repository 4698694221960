// DS6.12
import React, { useState } from 'react';
import c from 'classnames';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import type { BiEvent } from '@wix/da-bi/pkg/events';
import s from './DeviantsBarItem.scss';

export interface Props {
  label: React.ReactNode; // string | style-less node
  size: 'large' | 'medium' | 'small';
  isActive?: boolean;
  hasNewContent?: boolean;
  url?: string;
  className?: string;
  biData?: BiEvent;
  children: React.ReactNode;
}

const DeviantsBarItem: React.FC<Props> = ({
  label,
  isActive,
  hasNewContent,
  url,
  className,
  children,
  biData,
  size,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  if (url) {
    return (
      <BiLink
        biData={biData}
        href={url}
        className={c(
          s['root'],
          hasNewContent && s['new'],
          isActive && s['active'],
          isClicked && s['clicked'],
          size && s[`size-${size}`],
          className
        )}
        onClick={() => setIsClicked(true)}
      >
        <div className={s['selection-frame']}>{children}</div>
        <div className={s['label']}>{label}</div>
      </BiLink>
    );
  }
  return (
    <div
      className={c(
        s['root'],
        s[`size-${size}`],
        hasNewContent && s['new'],
        isActive && s['active'],
        isClicked && s['clicked'],
        className
      )}
      onClick={() => setIsClicked(true)}
    >
      <div className={s['selection-frame']}>{children}</div>
      <div className={s['label']}>{label}</div>
    </div>
  );
};
DeviantsBarItem.displayName = 'DeviantsBarItem';

export default DeviantsBarItem;
