import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { PapiUser } from '@wix/da-papi-types';
import { useReducedMotion } from '@wix/da-hooks/pkg/useReducedMotion';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserAvatarIndicators from '@wix/da-shared-react/pkg/User/AvatarIndicators';
import BasicUserName from '@wix/da-shared-react/pkg/User/BasicUserName';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import BirthdayBadge from '@wix/da-ds/pkg/Badges/BirthdayBadge';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import DeviantsBarItem from '../DeviantsBarItem';
import { BiData, UserOrGroupFeedSelectBiEvent } from '@wix/da-bi/pkg/events';

import s from './DeviantsBarUserItem.scss';

interface Props {
  user: PapiUser;
  size: 'large' | 'medium' | 'small';
  subscriberOnly?: boolean;
  isActive?: boolean;
  className?: string;
}

const avatarSizes = {
  large: 48,
  medium: 40,
  small: 32,
};

const DeviantsBarUserItem: React.FC<Props> = ({
  user,
  className,
  isActive,
  subscriberOnly,
  size,
}) => {
  const { t } = useTranslation();
  const prefersReducedMotion = useReducedMotion();
  const isFaded = subscriberOnly && !user.isSubscribed;
  const indicator = useMemo(() => {
    if (user.isSubscribed) {
      return 'tierSubscribed';
    }
    if (user.isBirthweek) {
      return (
        <SimpleTooltip
          placement="bottom"
          text={t('watch.birthday.avatar.indicator.tooltip', {
            username: user.username,
          })}
          className={s['birthday-indicator']}
          usePortal
        >
          <BirthdayBadge size={20} />
        </SimpleTooltip>
      );
    }
    return null;
  }, [t, user.isBirthweek, user.isSubscribed, user.username]);

  return (
    <DeviantsBarItem
      className={classnames(isFaded && s['faded'], s[size], className)}
      size={size}
      url={user.networkbar?.url}
      hasNewContent={user.networkbar?.hasNewContent}
      isActive={isActive}
      label={<BasicUserName user={user} showSymbol={false} />}
      biData={BiData<UserOrGroupFeedSelectBiEvent>({
        evid: 402,
        typeid: ResourceType.USER,
        itemid: user.userId,
        indicator: user.networkbar?.hasNewContent ? 1 : 0,
        is_birthday: user.isBirthweek ? 1 : 0,
      })}
    >
      <UserAvatarIndicators
        user={user}
        topRight={indicator}
        className={classnames(
          user.isBirthweek && s['birthday'],
          !prefersReducedMotion && s['animated']
        )}
      >
        <UserAvatar
          size={avatarSizes[size]}
          user={user}
          withLink={false}
          // Avoid duplication for screen readers with the provided label
          aria-hidden="true"
        />
      </UserAvatarIndicators>
    </DeviantsBarItem>
  );
};
DeviantsBarUserItem.displayName = 'DeviantsBarUserItem';

export default DeviantsBarUserItem;
