import { SagaIterator, eventChannel } from 'redux-saga';
import {
  all,
  takeLatest,
  select,
  put,
  call,
  delay,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import { actions as siteHeaderActions } from '@wix/da-shared-react/pkg/SiteHeader';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { pushVerificationSuccessfulPopup } from '@wix/da-shared-react/pkg/VerificationSuccessfulPopup/actions';
import { SEARCH_FIELD_ID } from '../components/Header/constants';
import { getModalToShowOnLoad, getModalParams } from '../selectors/modal';
import { markCurrentPageAsSeen } from '../actions/app';
import {
  getSelectedNetworkBarItem,
  getShouldMarkSelectedNetworkBarItemAsRead,
} from '../selectors/app';
import { markChannelAsRead } from '@wix/da-shared-react/pkg/NetworkBar/redux/actions';

const MODAL_TYPES = {
  status: ModalType.STATUS_UPDATE,
  journal: ModalType.JOURNAL,
  literature: ModalType.LITERATURE,
  poll: ModalType.POLL_EDIT,
  verify: ModalType.VERIFICATION,
};

export function* focusSearchFieldSaga() {
  const element = document.getElementById(SEARCH_FIELD_ID);
  if (element) {
    (element as HTMLInputElement).value = '';
    element.focus();
  }
}

function* handleMarkPageAsReadOnLoad() {
  // either after 5 seconds or when the user scrolls, mark page as seen
  const scrollChannel = eventChannel(emitter => {
    window.addEventListener('scroll', emitter);
    return () => {
      window.removeEventListener('scroll', emitter);
    };
  });
  yield race({ scroll: take(scrollChannel), delay: delay(5000) });
  scrollChannel.close();
  yield put(markCurrentPageAsSeen());
}

function* handleCurrentPageSeen() {
  const isChannelFullyRead = yield select(
    getShouldMarkSelectedNetworkBarItemAsRead
  );
  if (isChannelFullyRead) {
    const selectedItem = yield select(getSelectedNetworkBarItem);
    yield put(markChannelAsRead(selectedItem));
  }
}

export function* appMountedSaga() {
  yield call(openInitialModalIfAny);
  yield call(handleMarkPageAsReadOnLoad);
}

export function* openInitialModalIfAny() {
  // After verifying the email address a special popup is rendered, based on a query param
  const showVerificationSuccessfulPopup =
    typeof window !== 'undefined' &&
    window.location.href.endsWith('/?verify_success=1');

  if (showVerificationSuccessfulPopup) {
    yield put(pushVerificationSuccessfulPopup());
  }

  const modalTypeName = yield select(getModalToShowOnLoad);
  const modalType = MODAL_TYPES[modalTypeName];
  if (!modalType) {
    return;
  }

  history.replace('/');
  const modalParams = yield select(getModalParams);
  yield put(
    modalActions.pushModal(modalType, {
      wrapInModal: false,
      params: modalParams,
    })
  );
}

export default function* appSagaRoot(): SagaIterator {
  yield all([
    takeLatest(
      siteHeaderActions.siteHeaderSearchButtonClicked,
      focusSearchFieldSaga
    ),
    takeLatest(appMounted, appMountedSaga),
    takeEvery(markCurrentPageAsSeen, handleCurrentPageSeen),
  ]);
}
